import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { Navigation, NavigationLink } from 'ui-library';
import { useTranslation } from 'react-i18next';
import {useManagerProfile} from '../../prodivers/managerProfile';

function SideNavigation({ isOpen, current, onClose }) {
    const { t } = useTranslation();
    const iframeURL = useMemo(() => (global.AppConfig.IFRAME_CASE_MANAGEMENT_URL));
    const { hasAccessPolicy } = useManagerProfile();

    return (
        <Navigation
            isOpen={isOpen}
            onClose={onClose}
        >
            <NavigationLink to="/dashboard" homeLink={current === '/dashboard'}>{t('navigation.sideNav.dashboard')}</NavigationLink>
            <NavigationLink to="/clients" homeLink={current.includes('clients')}>{t('navigation.sideNav.clients')}</NavigationLink>
            {hasAccessPolicy('EnableDocumentLibraryTab') && <NavigationLink to="/documents" target="_blank">{t('navigation.sideNav.documents')}</NavigationLink>}
            {hasAccessPolicy('CaseManagement') && <NavigationLink externalURL to={{pathname: iframeURL}} target="_blank" rel="noreferrer">{t('navigation.sideNav.caseManagement')}</NavigationLink>}
            {hasAccessPolicy('EnableGeneralReports') && <NavigationLink to="/reports" homeLink={current === '/reports'}>{t('navigation.sideNav.reports')}</NavigationLink>}
        </Navigation>
    );
}

SideNavigation.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    current: PropTypes.string.isRequired,
};

SideNavigation.defaultProps = {
    isOpen: false,
    onClose: () => {},
};

export default SideNavigation;
