import { useCallback, useEffect, useState } from 'react';
import { getItemFromSS } from 'utils/storage';
import AdapterError from 'errors/AdapterError';

export const useFormData = ({serverData, sessionStorageKey}) => {
    // Form data
    const [data, setData] = useState({});
    const getDataFromSS = useCallback(() => getItemFromSS(sessionStorageKey), [getItemFromSS]);
    const initializeData = useCallback(({ groupIds }) => {
        if (!groupIds) throw new AdapterError('There is no groupIds for initializeData function');
        const newData = groupIds.reduce((acc, key) => ({ ...acc, [key]: {} }), {});

        setData(newData);
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(newData));
    }, []);
    const saveData = useCallback(({ groupId, questions }) => {
        if (!groupId) throw new AdapterError('There is no groupId for saveData function');

        const newData = { ...data };

        if (!newData[groupId]) {
            newData[groupId] = {};
        }

        questions?.forEach(question => {
            const {questionId, value} = question;

            if (questionId) {
                newData[groupId][questionId] = value;
            } else {
                newData[groupId] = value;
            }
        });

        setData(newData);
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(newData));
    }, [data]);
    const clearData = useCallback(() => {
        setData(null);
        sessionStorage.removeItem(sessionStorageKey);
    }, []);

    useEffect(() => {
        if (!serverData) {
            return;
        }

        const group = serverData?.Groups?.[0];
        const questions = group?.Steps?.[0]?.Questions;

        const serverValues = {
            [group?.Id]: {
                ...questions?.reduce((capacitor, question) => {
                    if (question?.Value) {
                        capacitor[question?.Id] = question.Type === 'SingleAnswer' ? question?.Value?.Ids?.[0] : question?.Value?.Ids;
                    }

                    return capacitor;
                }, {}),
            },
        };
        const dataFromSS = getDataFromSS();
        const mergedData = {
            [group?.Id]: {
                ...serverValues?.[group?.Id],
                ...dataFromSS?.[group?.Id],
            },
        };

        if (dataFromSS || serverValues) {
            setData(mergedData);
        }
    }, [getDataFromSS, serverData, setData]);

    return {
        data,
        getDataFromSS,
        initializeData,
        saveData,
        clearData,
    };
};
