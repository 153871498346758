/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Table, Button, notification} from 'ui-library';
import {useParams} from 'react-router-dom';
import {
    useReSendActivationLetterEnabled,
} from 'pages/ClientOverview/pages/ClientPortal/hooks/useReSendActivationLetterEnabled';
import Infobox from 'ui-library/components/Infobox/Infobox';
import { useGetContact } from 'hooks/rest/useGetContact';
import {getClientId} from '../../../../utils';
import {useClientActivationHistory} from './hooks/useClientActivationHistory';
import Card from '../../../../components/Card/Card';
import Preloader from '../../../../components/Preloader';
import {clientHistoryAdapter} from './adapter/clientHistoryAdapter';
import Icon from '../../../../ui-library/components/Icon/Icon';
import {ICON_TYPE_LETTER} from '../../../../ui-library/components/Icon';
import {useResendActivationLatter} from './hooks/useSendActivationLatter';
import {columns} from './const';
import {useClientProfile} from '../../../../prodivers/clientProfile';
import {useClientLayout} from '../../hooks/useClientLayout';

import './ClientPortl.css';

const ClientPortal = () => {
    const {t} = useTranslation();
    const [resendError, setResendError] = useState<string|undefined>();
    const {dfsClientId} = useParams();
    const clientId = getClientId(dfsClientId);
    const {data: contactDetails} = useGetContact({contactId: clientId});

    const contactPhoneNumber = !!contactDetails?.details?.communicationMethods?.primaryPhoneNumber;

    useClientLayout();

    const {data: isReSendEnabledForClient, isLoading: isReSendEnabledForClientLoading} = useReSendActivationLetterEnabled({contactId: clientId});

    const {
        isOverviewLoading,
    } = useClientProfile();

    const {
        getData, data, isLoading: isHistoryLoading, error, isLastResend24hAgo, isUserRegistered,
    } = useClientActivationHistory({contactId: clientId, adapter: clientHistoryAdapter});

    const {sendData, isLoading: isSending} = useResendActivationLatter({contactId: clientId});

    const resendActivationCode = useCallback(async () => {
        try {
            setResendError(undefined);
            await sendData();
        } catch (err) {
            const message = t('clientDashboard.clientPortal.letterNotDelivered');

            setResendError(message);
            notification.open({content: message, type: 'error'});

            return;
        }
        await getData();
    }, [sendData]);

    const openUserInBO = useCallback(() => {
        const zurichInvestBO = globalThis?.AppConfig?.BO_ZIAG_URL;
        const additivBO = globalThis?.AppConfig?.BO_ADDITIV_URL;
        const isZurichInvest = window.location.href.includes('zurich.ch');

        global.open(
            `${isZurichInvest ? zurichInvestBO : additivBO}/Additiv/Crm/Contact/Edit/${clientId}`,
            '_blank',
        );
    }, [clientId]);

    useEffect(() => {
        getData();
    }, [getData]);

    const isLoading = useMemo(() => isHistoryLoading || isSending || isOverviewLoading || isReSendEnabledForClientLoading, [isHistoryLoading, isSending, isOverviewLoading, isReSendEnabledForClientLoading]);

    const isResendDisabled = useMemo(() => (
        isLoading || !isReSendEnabledForClient || !isLastResend24hAgo || isUserRegistered || !contactPhoneNumber
    ), [isLoading, isReSendEnabledForClient, isLastResend24hAgo, isUserRegistered, contactPhoneNumber]);

    return (
        <div className="container ClientPortal fi-verticals">
            <Card
                title={t('clientDashboard.clientPortal.title')}
                titleTagName="h1"
            >
                <Preloader isLoading={isHistoryLoading} error={error}>
                    <Table
                        noDataText={t('clientDashboard.clientPortal.noActions')}
                        columns={columns(t)}
                        data={data}
                    />
                </Preloader>
                {resendError && <Infobox error>{resendError}</Infobox>}
                <span className="button-group">
                    <Button
                        type="secondary"
                        size="small"
                        className="letter"
                        loading={isLoading}
                        disabled={isResendDisabled}
                        onClick={resendActivationCode}
                    >

                        {/* @ts-ignore */}
                        <Icon type={ICON_TYPE_LETTER} size={24}/>
                        {t('clientDashboard.clientPortal.activation.buttonLabel')}
                    </Button>

                    <Button
                        type="secondary"
                        size="small"
                        className="open-bo"
                        onClick={openUserInBO}
                    >
                        {t('clientDashboard.clientPortal.openBO.buttonLabel')}
                    </Button>
                </span>
            </Card>
        </div>
    );
};

export default ClientPortal;
