import {checkIfEmailIsAvailable, checkIfEmailIsInUse} from 'pages/utils';
import {getValidationMessage} from './messages';

export const validateDateOfBirth = (dateOfBirthdayValue) => {
    if (dateOfBirthdayValue === '' || dateOfBirthdayValue === null || dateOfBirthdayValue === undefined || dateOfBirthdayValue === false || dateOfBirthdayValue === 'Invalid date') {
        return getValidationMessage().mandatoryError;
    }

    const regex = /^\d{2}.\d{2}.\d{4}$/;

    if (!regex.test(dateOfBirthdayValue)) {
        return getValidationMessage().notBirthDateError;
    }

    const splitDateOfBirthday = typeof dateOfBirthdayValue === 'string' ? dateOfBirthdayValue?.split('/') : null;

    if (splitDateOfBirthday && (+splitDateOfBirthday[2] < 1900)) {
        return getValidationMessage().birthDateError;
    }

    return null;
};

export const validateInputAlphanumeric = (inputValue, isRequired = false) => {
    if (isRequired) {
        const isInvalidString = validateInputString(inputValue);

        if (isInvalidString) {
            return isInvalidString;
        }
    }
    if (!inputValue || inputValue === '') {
        return null;
    }

    const reqExp = new RegExp(/^\w+$/);
    const result = inputValue.toString().match(reqExp);

    return result ? null : getValidationMessage().alphanumericError;
};

export const validateInputString = (inputValue) => {
    if (inputValue === null || inputValue === undefined || inputValue === 'N.A' || inputValue === '') {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateName = (inputValue) => {
    if (inputValue === null || inputValue === undefined || inputValue === 'N.A' || inputValue === '') {
        return getValidationMessage().mandatoryError;
    }

    const nameValidationRegExp = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    if (!nameValidationRegExp.test(inputValue)) {
        return getValidationMessage().nameError;
    }

    return null;
};

export const validateInputNumber = (inputNumberValue) => {
    if (inputNumberValue === null || inputNumberValue === undefined) {
        return getValidationMessage().mandatoryError;
    }

    const regex = new RegExp('^[0-9]+$');

    if (!regex.test(inputNumberValue)) {
        return getValidationMessage().numberError;
    }

    return null;
};

export const validateInputNumberWithZero = (inputNumberValue) => {
    if (inputNumberValue === 0) {
        return getValidationMessage().mandatoryError;
    }

    return validateInputNumber(inputNumberValue);
};

export const validateEmailAdress = (emailAdressValue) => {
    if (emailAdressValue === null || emailAdressValue === undefined || emailAdressValue === 'N.A') {
        return getValidationMessage().mandatoryError;
    }

    const regex = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;

    if (!regex.test(emailAdressValue)) {
        return getValidationMessage().emailError;
    }

    return null;
};

export const validateEmailAddressUniqueness = async (email, userRegisterId) => {
    const isEmailInUse = userRegisterId ? await checkIfEmailIsInUse(userRegisterId, email) : !(await checkIfEmailIsAvailable(email));

    if (isEmailInUse) {
        return getValidationMessage().emailInUse;
    }

    return null;
};

export const validatePhoneNumber = (phoneNumberValue, required = true) => {
    if (required && (phoneNumberValue === null || phoneNumberValue === undefined)) {
        return getValidationMessage().mandatoryError;
    }

    const regex = new RegExp('^[\+][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$');

    if (phoneNumberValue && !regex.test(phoneNumberValue)) {
        return getValidationMessage().phoneError;
    }

    return null;
};

export const validateSelect = (selectValue) => {
    if (selectValue === null || selectValue === undefined || selectValue === 'N/A') {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateRadio = (radioValue) => {
    if (radioValue === null || radioValue === undefined) {
        return getValidationMessage().mandatoryError;
    }

    return null;
};

export const validateInputLength = (value, maxAllowed = 40) => {
    if (value?.length > maxAllowed) {
        return getValidationMessage({length: maxAllowed}).lengthError;
    }

    return null;
};

export const validateMultipleInputsLength = (maxAllowed, message, ...args) => {
    let totalLength = 0;

    args.forEach(f => { totalLength += f?.length || 0; });

    if (totalLength > maxAllowed) {
        return message;
    }

    return null;
};

export const IsAllDataNull = (obj) => Object.values(obj).every((value) => value === null);
