import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {usePortfolioProcessNavigation} from '../hooks/helpers/usePortfolioProcessNavigation';
import {useCreatePortfolioActions, useCreatePortfolioState} from './CreatePortfolio';
import {useManagerProfile} from '../prodivers/managerProfile';

export const useAdvisoryProcess = () => {
    const {hasAccessPolicy} = useManagerProfile();

    const {t} = useTranslation();
    const items = useMemo(() => [
        {
            id: 2,
            name: t('advisoryDocumentInit.step.no'),
        },
        {
            id: 1,
            name: t('advisoryDocumentInit.step.yes'),
            disabled: !hasAccessPolicy('EnableAdvisoryProcess'),
        },
    ], [t]);
    const {
        setShouldInitiateAdvisoryProcessAction,
    } = useCreatePortfolioActions();
    const {shouldInitiateAdvisoryProcess} = useCreatePortfolioState();

    usePortfolioProcessNavigation({
        preventNavigationLock: true,
        saveOnBack: useCallback(() => setShouldInitiateAdvisoryProcessAction(items?.[0]?.id), [items?.[0]?.id]),
    });

    return {
        items,
        shouldInitiateAdvisoryProcess,
        setShouldInitiateAdvisoryProcess: setShouldInitiateAdvisoryProcessAction,
    };
};
