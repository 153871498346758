import { MailingAddress, NewConnectionsSessionManagerAPI } from 'pages/ClientOverview/pages/ClientConnections/components/NewConnections/constants';

export type ConnectionGroup = '3b' | 'JA';
export type ConnectionType = 'Representative' | 'JointAccount' | 'PowerOfAttorney' | 'Owner';

export enum AddressType {
    Domicile = 0,
    Mailing = 1,
}

export enum AccessPolicy {
    BeneficialOwner = 2,
    PowerOfAttorney = 3,
    Representative = 19,
    Owner = 20,
}

export enum ContactHistoryAction {
    Deleted,
    Added,
}

export enum ContactGroupEnum {
    threeB = '3b',
    ja = 'JA'
}

export type ContactGroup = {
    groupId: number;
    externalId: string;
    groupName: ConnectionGroup;
    owners: {number: string};
    accessPolicy: AccessPolicy;
    proxyId: number;
    proxyName: string;
    proxy: Array<number> | null
    proxies: any
    isDeletable: boolean
}

export type ModifiedGroup = ContactGroup & {
    actionCol: {
        onConnectionSelect: (connection: ContactGroup | null) => void,
    }
}

export type ProxyDetails = {
    accessPolicy: number,
    proxyId: number,
    proxyName: 'string'
}

export type ContactGroupDetails = {
    groupId: number;
    groupName: string,
    owners: Record<string, string>;
    proxies: Array<ProxyDetails>
    externalId: string
    contactGroupAddress?: MailingAddress | null;
}

export type ContactGroupDocuments = {
    name: string,
    id: number
    crateDate: Date
    nameCol: any
}

export type ContactGroupHistory = {
    contacts: string,
    contactGroupId: number,
    changeDate: Date,
    member: string,
    accessPolicyType: number,
    action: number
}

export type ConnectionsAPI = {
    data: ContactGroup[],
    rawData: ContactGroup[],
    isLoading: boolean,
    error: string | null,
    onAddConnection: () => void,
    onGeneratePOAform: () => void,
    selectedProxyConnection: ProxyDetails | null,
    discardConnectionRemove: () => void,
    isAddConnectionModalOpen: boolean,
    closeAddConnectionModal: () => void,
    createConnection: (connection: NewConnectionsSessionManagerAPI) => void,
    getContactGroupDetails: (groupId?: number) => Promise<void>,
    contactGroupDetails: ContactGroupDetails | null
    setSelectedProxyConnection: (proxyDetails: ProxyDetails) => void,
    onProxyConnectionDelete: () => Promise<void>
    deleteContactGroup: (contactGroupId: number) => Promise<void>
    getContactGroupDocuments: (groupId?: number) => Promise<void>
    contactGroupDocumentsInProgress: boolean;
    contactGroupDocumentsError: string | null;
    contactGroupDocuments: Array<ContactGroupDocuments> | null
    addresses: Address[],
    isAddressesLoading: boolean,
    inProgress: boolean,
    addressError: string | null,
    loadContactAddresses: (contacts: number[]) => void,
    getContactGroupHistory: (groupId?: number) => Promise<void>,
    contactGroupHistoryLoading: boolean,
    contactGroupHistoryError: string | null,
    contactGroupHistory: ContactGroupHistory | null,
    setContactGroupDetails: (value: ContactGroupDetails | null) => void
    updateContactGroupAddress: (groupId: string, address: MailingAddress, callback?: (...args) => Promise<void>) => Promise<void>
    downloadDocument: (documentId: number) => Promise<void>,
    getProxyAdditionStatus: (groupId?: string | null | undefined) => Promise<void>,
    allowProxyAddition: boolean,
}

export type JADocument = {
    filename: string;
    file: string;
    type: number
}

export type NewProxyConnectionPayload = {
    proxyId: number,
    accessPolicy: AccessPolicy,
    proxyRelationship: {
        description: string,
        ccApproval: boolean
    }
    documents: JADocument[]
}

export type NewJAConnectionPayload = {
    contacts: number[],
    mailingAddress: MailingAddress,
}

export type Address = {
    contactName?: string;
    streetNo?: string;
    zipCode?: string;
    city?: string;
    addressType: AddressType;
    language: number;
    addressLine1?: string;
    addressLine2?: string;
    country: number;
}
