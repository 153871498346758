export const getRiskScrores = (t) => [
    {
        id: 1,
        label: t('riskScore.interest'),
    },
    {
        id: 2,
        label: t('riskScore.income'),
    },
    {
        id: 3,
        label: t('riskScore.balanced'),
    },
    {
        id: 4,
        label: t('riskScore.growth'),
    },
    {
        id: 5,
        label: t('riskScore.capitalGain'),
    },
    {
        id: 6,
        label: t('riskScore.BondsCH'),
    },
    {
        id: 7,
        label: t('riskScore.EquityCH'),
    },
];
