export const defaultPieChartOptions = {
    chart: { type: 'pie', margin: 0, width: 250, height: 250 },
    title: false,
    subtitle: { useHTML: true },
    plotOptions: {
        pie: {
            center: ['50%', '50%'],
            innerSize: '64%',
            size: '100%'
        },
    },
    tooltip: { enabled: true, useHTML: true, followPointer:true, style:{color:"#142966",fontSize: "12px"}, borderWidth: 2, borderRadius: 16, borderColor: 'transparent' },
    series: [{ dataLabels: false, states: { hover: { enabled: false } } }],
    credits: false,
};
