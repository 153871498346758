import Service from 'additiv-services';
import {getQueryStringByObject} from 'additiv-services/utils/queryUtils';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class CustomClientServices extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.CONTACT);

    static resendActivationLetter({contactId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${contactId}/resend-activation-letter`, {}, {isSecure: true});
    }

    static resendActivationLetterEnabled({contactId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/acctivationLetter/${contactId}/canSend`, {isSecure: true});
    }

    static createNewContact(payload) {
        return Service.postRequest(this.#baseServiceUrl, payload, {isSecure: true});
    }

    static getContactByExternalId({contactExternalId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/external-contact/${contactExternalId}/id`, {isSecure: true});
    }

    static getContactDetails({contactExternalId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/external-contact/${contactExternalId}/details`, {isSecure: true});
    }

    static getIsLocked({contactExternalId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/external-contact/${contactExternalId}/isLocked`, {isSecure: true});
    }

    static postTransactions(contactId, portfolioId, payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/${contactId}/portfolios/${portfolioId}/transactions`, payload, {isSecure: true});
    }

    static saveClientDetails({data, contactId}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${contactId}/information`, data, {isSecure: true});
    }

    static checkIfEmailIsInUse({contactId, email}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/checkemail?email=${email}`, {isSecure: true});
    }

    static checkIfEmailIsAvailable({email}) {
        return Service.getRequest(`${this.#baseServiceUrl}/checkemail?email=${email}`, {isSecure: true});
    }

    static search({filter, pageNumber, pageSize = 10 }) {
        const queryString = getQueryStringByObject({filter, pageNumber, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/search?${queryString}`, {isSecure: true});
    }

    static patchMailingAddress({contactId, data, action = 0 }) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${contactId}/mailing?action=${action}`, data, {isSecure: true});
    }

    static getConnectionsHistory({contactId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/connectionsHistory`, {isSecure: true});
    }

    static getConnections({contactId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/connections`, {isSecure: true});
    }

    static getContactGroups({contactId, createThreeB = false}) {
        const queryString = getQueryStringByObject({createThreeB});

        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/contactgroups?${queryString}`, {isSecure: true});
    }

    static getActiveApplicationIndicator({contactGroupId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactGroupId}/active-application`, {isSecure: true});
    }

    static deleteProxyConnection({contactGroupId, proxyId, accessPolicy}) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${contactGroupId}/proxy/${proxyId}/${accessPolicy}`, {isSecure: true});
    }

    static getProxyAdditionStatus(contactGroupId) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactGroupId}/proxy/canAdd`, {isSecure: true});
    }

    static deleteJAConnection({contactGroupId}) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${contactGroupId}/jointaccount`, {isSecure: true});
    }

    static getContactGroupDetails({groupId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${groupId}/contactgroupdetails`, {isSecure: true});
    }

    static createProxyConnection(contactId, payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/${contactId}/contactproxy`, payload, {isSecure: true});
    }

    static updateContactGroupProxy(contactGroupId, payload) {
        return Service.putRequest(`${this.#baseServiceUrl}/${contactGroupId}/contactgroupproxy`, payload, {isSecure: true});
    }

    static updateContactGroupAddress(contactGroupId, payload) {
        return Service.putRequest(`${this.#baseServiceUrl}/${contactGroupId}/contactgroupaddress`, payload, {isSecure: true});
    }

    static deleteContactGroup({contactGroupId}) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${contactGroupId}`, {isSecure: true});
    }

    static createJAConnection(contactId, payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/${contactId}/jointaccount`, payload, {isSecure: true});
    }

    static getContactGroupAddresses(contacts) {
        const queryString = contacts.map((contact) => `contacts=${contact}`).join('&');

        return Service.getRequest(`${this.#baseServiceUrl}/addresses?${queryString}`, {isSecure: true});
    }

    static getContactGroupHistory(contactGroupId) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactGroupId}/contactGroupHistory`, {isSecure: true});
    }

    static postKYCData(contactId, payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/${contactId}/KYC`, payload, {isSecure: true});
    }

    static putKYCData(contactId, payload) {
        return Service.putRequest(`${this.#baseServiceUrl}/${contactId}/KYC`, payload, {isSecure: true});
    }

    static getKYCData(contactId) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/KYC`, {isSecure: true});
    }

    static isParentalAuthorityAvailable(contactId) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/is-parental-authority`, {isSecure: true});
    }

    static hasThreeBActivePortfolio({contactId, contactGroupId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/contact-groups/${contactGroupId}/has-active-portfolio`, {isSecure: true});
    }
}

export default CustomClientServices;
