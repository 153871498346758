import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useLayout} from 'prodivers/layout';
import LogoWithClientName from 'components/LogoWithClientName';
import {useClientProfile} from 'prodivers/clientProfile';
import {genarateDFSClientId} from 'utils';

export const useClientLayout = (
    showClientTitle = true,
    showTopNavigation = true,
) => {
    const { initHeaderState, changeHeaderState } = useLayout();
    const navigate = useNavigate();
    const { t } = useTranslation();
    // Client Provider
    const {
        clientId, clientName, isProspect, isDataAccessible,
    } = useClientProfile();
    const dfsClientId = genarateDFSClientId(clientId);

    useEffect(() => {
        changeHeaderState({
            isDataAccessible,
        });
    }, [isDataAccessible]);
    useEffect(() => {
        initHeaderState({
            clientName,
            dfsClientId,
            link: null,
            content: LogoWithClientName,
            contentProps: { clientName, dfsClientId, isLogoClickable: true },
            showClientTitle,
            showTopNavigation,
            leftButton: { type: 'login', label: `${t('clientDashboard.close')} ${clientName}` },
            onLeftButtonClick: () => {
                navigate(`/clients${isProspect ? '?status=Prospects' : ''}`);
            },
            rightButton: '',
            onRightButtonClick: null,
            isDataAccessible,
        });
    }, [clientName, isProspect, dfsClientId, initHeaderState, t, isDataAccessible]);
};
