import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
    number, object, string,
} from 'yup';
import {
    Title, TextInput, ProjectionChart, FormGroup, RadioButtonGroup, Select, LineChart,
} from 'ui-library';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import Preloader from 'components/Preloader';
import RangeSlider from 'components/RangeSlider';
import { useFormatting } from 'locale';
import EmptyContent from 'components/EmptyContent';

import {useParams} from 'react-router-dom';
import {useGoalAndPerformance} from './hooks/useGoalAndPortfolio';
import './GoalAndPortfolioSetup.css';
import RiskScale from '../../../../components/RiskScale/RiskScale';
import {useCreatePortfolio} from '../../context';
import {ConsultationBox} from './components/ConsultationBox';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import {PAYMENT_PERIODS} from '../../utils';

const GoalAndPortfolioSetup = () => {
    const {dfsClientId} = useParams();
    const { newPortfolio } = useCreatePortfolio();
    const isZurichEmployee = newPortfolio?.applicationData?.investmentDescription?.personalDetails?.zurichEmployee;
    const {
        t,
        riskCategories,
        modelPortfolio,
        projectionOptions,
        currency,
        productName,
        productSettings,
        changeTransferValue,
        changeYearlyPayment,
        transferValue,
        yearlyPayment,
        chartData,
        minMaxTransferValue,
        minMaxYearlyPayment,
        isFullTransfer,
        radioFullTransferOptions,
        changeFullTransfer,
        showFullTransferQuestion,
        isLoading,
        validation,
        onFieldChange,
        prevPensionScheme,
        getSelectStrategy,
        isThreeB,
        periodicPayment,
        oneTimeInvestment,
        paymentPeriod,
        onPeriodicPaymentChange,
        onOneTimePaymentChange,
        onPaymentPeriodChange,
        rubrik,
        setRubrik,
        projectionYears,
        onProjectionYearsChange,
        getInvestmentForNextSixMonths,
        isInvestmentAllowed,
        azpXAxisFormat,
        azpYAxisFormat,
        setProjectionYearsValue,
        isProofOfAssetsRequired,
        language,
    } = useGoalAndPerformance({
        dfsClientId,
        modelPortfolioId: newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.modelPortfolioId,
        strategyId: newPortfolio?.applicationData?.investmentDescription?.selectStrategy?.strategyId,
    });

    const { getFormattedCurrency, getFormattedDate } = useFormatting();
    const {
        isAzp, isZic, isZifd,
    } = useThreeBProductType(newPortfolio);

    const nextSixMonthsInvestment = getInvestmentForNextSixMonths();

    const schema = useMemo(() => {
        if (isZifd || !isZic) {
            return object().shape({
                periodicPaymentFrequency: number().typeError(t('validation.mandatoryField')).required(t('validation.mandatoryField')),
                rubrik: string(),
            });
        }

        return object().shape({});
    }, [t]);

    const resolver = useYupValidationResolver(schema);
    const paymentPeriods = useMemo(() => Object.values(PAYMENT_PERIODS).map(i => ({label: t(i.label), value: i.id})), [t]);

    const {
        control, formState: { errors },
    } = useForm({resolver});

    useEffect(() => {
        if (isZic) {
            setProjectionYearsValue(5);
        }
    }, [isZic]);

    const RubrikInput = useMemo(() => (
        <TextInput
            value={rubrik}
            onChange={(e) => setRubrik(e.target.value)}
            label={t('3b.performance.rubrik')}
            error={validation?.rubrik}
            maxLength={30}
        />
    ), [rubrik, validation?.rubrik, language]);

    if (isAzp) {
        return (
            <div className="new-portfolio goal-product-setup newStyle">
                <Preloader isLoading={isLoading}>
                    <Title type={1}>
                        {newPortfolio?.product?.description}
                    </Title>
                    <div className="azp-container">
                        <div className="azp-data">
                            <div className="azp-detail-data">
                                <span>{t('3b.withdrawalPlan.investmentAmount')}</span>
                                <span>{getFormattedCurrency(newPortfolio.applicationData.investmentDescription?.withdrawal?.investmentAmount, { currency: 'CHF' })}</span>
                            </div>
                            <div className="azp-detail-data">
                                <span>{t('3b.withdrawalPlan.withdrawalsStart')}</span>
                                <span>{getFormattedDate(newPortfolio.applicationData.investmentDescription?.withdrawal?.startDate)}</span>
                            </div>
                            {RubrikInput}
                        </div>
                        <div className="azp-graph">
                            <div className="fi-verticals">
                                <Title type={2}>
                                    {t('3b.goalAndPortfolioSetup.estimatedPerformance')}
                                </Title>
                                <EmptyContent
                                    data={chartData}
                                    text={t('advisoryDashboard.noData')}
                                >
                                    <LineChart yAxisFormat={azpYAxisFormat} customXAxisFormat={azpXAxisFormat} data={chartData} yTitle={currency} legendEnabled projection />
                                </EmptyContent>
                            </div>
                        </div>
                    </div>
                </Preloader>
            </div>
        );
    }

    return (
        <div className="new-portfolio goal-product-setup newStyle">
            <Preloader isLoading={isLoading}>
                <Title type={1}>
                    {newPortfolio?.product?.description}
                </Title>
                <div className="fi-verticals">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="fi-verticals">
                                <div className="form">
                                    <Title type={2}>{t('portfolio.createNewPortfolio.productConfiguration')}</Title>
                                    {!isZic && (
                                        <FormGroup>
                                            <h3 className="Form-control-label">{t('portfolio.createNewPortfolio.chosenProduct')}</h3>
                                            <p className="button buttonRadioElement buttonRadioElementNotSelected button-small button-primary">{modelPortfolio ? modelPortfolio.Name : 'Not available'}</p>
                                        </FormGroup>
                                    )}
                                    <FormGroup>
                                        <h3 className="Form-control-label">{t('portfolio.createNewPortfolio.risk')}</h3>
                                        <RiskScale scales={riskCategories} activeIndex={getSelectStrategy()} />
                                    </FormGroup>
                                    {!isThreeB && (
                                        <>
                                            {(productSettings?.showYearlyPayments) && (
                                                <RangeSlider
                                                    data-cy="yearlyPayment"
                                                    label={t('portfolio.createNewPortfolio.yearlyPayment')}
                                                    labelInfobox
                                                    error={validation?.yearlyPayment}
                                                    value={yearlyPayment}
                                                    onChange={changeYearlyPayment}
                                                    {...minMaxYearlyPayment}
                                                    withInput
                                                    showMinMax
                                                />
                                            )}
                                            <RangeSlider
                                                data-cy="transfer"
                                                label={t('portfolio.createNewPortfolio.transfer', {productName})}
                                                labelInfobox
                                                error={validation?.transferValue}
                                                value={transferValue}
                                                onChange={changeTransferValue}
                                                {...minMaxTransferValue}
                                                withInput
                                                showMinMax
                                            />
                                        </>
                                    )}
                                </div>
                                {(showFullTransferQuestion && !isThreeB)
                                    && (
                                        <fieldset className="form-group full-transfer">
                                            <legend
                                                className="inputLabel"
                                            >
                                                {t('portfolio.createNewPortfolio.transferQuestion.legend')}
                                            </legend>
                                            <RadioButtonGroup
                                                value={isFullTransfer}
                                                options={radioFullTransferOptions}
                                                onChange={changeFullTransfer}
                                            />
                                        </fieldset>
                                    )}
                                {transferValue > 0 && !isThreeB ? (
                                    <div className="form">
                                        <Title className="title-pension-scheme" type={2}>{t('portfolio.createNewPortfolio.previousPensionScheme')}</Title>
                                        <TextInput
                                            onChange={(e) => {
                                                onFieldChange({nameOfPensionScheme: e?.target?.value});
                                            }}
                                            value={prevPensionScheme?.nameOfPensionScheme}
                                            error={validation?.nameOfPensionScheme}
                                            placeholder=""
                                            required
                                            label={t('portfolio.createNewPortfolio.nameOfPensionScheme')}
                                        />
                                        <TextInput
                                            onChange={(e) => {
                                                onFieldChange({addressSupplement: e?.target?.value});
                                            }}
                                            value={prevPensionScheme?.addressSupplement}
                                            placeholder=""
                                            label={t('portfolio.createNewPortfolio.addressSupplement')}
                                        />
                                        <TextInput
                                            onChange={(e) => {
                                                onFieldChange({streetAndNumber: e?.target?.value});
                                            }}
                                            value={prevPensionScheme?.streetAndNumber}
                                            placeholder=""
                                            label={t('portfolio.createNewPortfolio.streetAndNumber')}
                                        />
                                        <TextInput
                                            onChange={(e) => {
                                                onFieldChange({ postcodeAndCity: e?.target?.value });
                                            }}
                                            value={prevPensionScheme?.postcodeAndCity}
                                            placeholder=""
                                            label={t('portfolio.createNewPortfolio.postcodeAndCity')}
                                        />
                                        <TextInput
                                            type="text"
                                            onChange={(e) => {
                                                onFieldChange({ policeNumber: e?.target?.value });
                                            }}
                                            value={prevPensionScheme?.policeNumber}
                                            error={validation?.policeNumber}
                                            placeholder=""
                                            label={t('portfolio.createNewPortfolio.policeNumber')}
                                        />
                                        <TextInput
                                            onChange={(e) => {
                                                onFieldChange({ bankAccountNumber: e?.target?.value });
                                            }}
                                            type="text"
                                            error={validation?.bankAccountNumber}
                                            value={prevPensionScheme?.bankAccountNumber}
                                            placeholder=""
                                            label={t('portfolio.createNewPortfolio.bankAccountNumber')}
                                        />
                                    </div>
                                ) : ''}
                                {isThreeB && (
                                    <div>
                                        <RangeSlider
                                            data-cy="oneTimeInvestment"
                                            label={t('portfolio.createNewPortfolio.oneTimeInvestment')}
                                            labelInfobox
                                            error={validation?.oneTimeInvestment}
                                            value={oneTimeInvestment}
                                            onChange={onOneTimePaymentChange}
                                            min={isZurichEmployee
                                                ? productSettings?.minOneTimeInvestmentEmp || productSettings?.minOneTimeInvestment
                                                : productSettings?.minOneTimeInvestment}
                                            max={productSettings?.maxOneTimeInvestment}
                                            withInput
                                            showMinMax
                                        />

                                        {!isZic && (
                                            <RangeSlider
                                                data-cy="periodicPayment"
                                                label={t('portfolio.createNewPortfolio.periodicPayment')}
                                                labelInfobox
                                                error={validation?.periodicPayment}
                                                value={periodicPayment}
                                                onChange={onPeriodicPaymentChange}
                                                min={productSettings?.minPeriodicPayment}
                                                max={productSettings?.maxPeriodicPayment}
                                                withInput
                                                showMinMax
                                            />
                                        )}
                                        {!isZic && (
                                            <Select
                                                value={paymentPeriod}
                                                placeholder={t('3b.kyc.pleaseSelect')}
                                                label={t('3b.performance.periodicPaymentFrequency')}
                                                options={paymentPeriods}
                                                onChange={onPaymentPeriodChange}
                                                error={errors?.periodicPaymentFrequency?.message}
                                                required
                                            />
                                        )}
                                        <div className="planned-investment">
                                            {!isZic && (
                                                <span>
                                                    {`${t('3b.performance.plannedInvestmentLabel')} ${getFormattedCurrency(nextSixMonthsInvestment, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0,
                                                        currency: 'CHF',
                                                    })}`}
                                                </span>
                                            )}
                                        </div>
                                        {!isZic && RubrikInput}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {
                                chartData.length ? (
                                    <>
                                        <Title type={2}>{t('portfolio.createNewPortfolio.calculationExample')}</Title>
                                        {isThreeB && (
                                            <div className="chart-subtitle-box">
                                                <div>
                                                    <Title type={3} className="chart-subtitle">{t('portfolio.createNewPortfolio.previewHorizon')}</Title>
                                                    {!isZic && <p className="chart-subtitle-info">{t('portfolio.createNewPortfolio.previewInfo')}</p>}
                                                </div>
                                                <div className="years-selector">
                                                    {!isZic ? (
                                                        <RangeSlider
                                                            data-cy="previewHorizon"
                                                            unit="years"
                                                            value={projectionYears}
                                                            onChange={onProjectionYearsChange}
                                                            min={5}
                                                            max={65}
                                                            withInput
                                                        />
                                                    ) : (
                                                        <p className="projection-years">
                                                            {`${projectionYears} ${t('charts.years')}`}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {/* FIXME: labels should come from BE in form of, for example (bestCase: +3.92%) - graph doesn't have an option to calculate these */}
                                        <ProjectionChart
                                            data={chartData}
                                            yTitle={currency}
                                            {...projectionOptions}
                                            yearsText={t('charts.years')}
                                            // legendLabels={[{name: 'Best case: +3.92%'}, {name: 'Average case'}, {name: 'Worst case'}]}
                                            showLegend
                                        />
                                    </>
                                ) : ''
                            }
                        </div>
                    </div>
                </div>
                {!isInvestmentAllowed && isThreeB && <ConsultationBox dfsClientId={dfsClientId}/> }
                {isProofOfAssetsRequired && <ConsultationBox showButton={false} message="3b.performance.proofOfAssetsWarning" dfsClientId={dfsClientId}/> }
            </Preloader>
        </div>
    );
};

GoalAndPortfolioSetup.propTypes = {};

GoalAndPortfolioSetup.defaultProps = {};

export default GoalAndPortfolioSetup;
