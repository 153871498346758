import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './InfoBox.css';
import { InfoBoxProps } from './InfoBox.types';

const InfoBox:FC<InfoBoxProps> = ({title, value}) => {
    const {t} = useTranslation();

    return (
        <div className="property-value-info">
            {title}
            {value !== null ? (
                <span>
                    CHF
                    {' '}
                    {value}
                </span>
            ) : (
                <span>
                    {t('PensionSituationForm.info.notSpecified')}
                </span>
            )}
        </div>
    );
};

export default InfoBox;
