import React, { useState } from 'react';
import {
    Icon, Dropdown, MenuItem,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import './QuickAccessMenu.css';
import { getClassNames } from 'utils';
import {useNavigate} from 'react-router-dom';

function QuickAccessMenu() {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const onNewMessage = () => {
        navigate('/messages/new', {State: { fromQuickAccessMenu: true }});
    };
    const onNewInteraction = () => {
        navigate('/dashboard/new-interaction');
    };
    const onNewClient = () => {
        navigate('/onboarding/register');
    };

    const className = getClassNames(
        'quick-access',
        { 'quick-access__active': visible },
    );

    return (
        <>
            <div className="plus-menu-icon-wrapper">
                <Dropdown
                    placement="bottomRight"
                    overlayClassName="QuickAccessMenu"
                    visible={visible}
                    onVisibleChange={setVisible}
                    title={(
                        <Icon
                            className={className}
                            type="plus"
                            role="button"
                            tabIndex={0}
                        />
                    )}
                >
                    <div className="quick-access__plus-menu">
                        <MenuItem onClick={() => {
                            onNewMessage();
                            setVisible(false);
                        }}
                        >
                            {t('quickAcessMenu.newMessage')}
                        </MenuItem>

                        <MenuItem onClick={() => {
                            onNewInteraction();
                            setVisible(false);
                        }}
                        >
                            {t('quickAcessMenu.newInteraction')}
                        </MenuItem>
                        <MenuItem onClick={() => {
                            onNewClient();
                            setVisible(false);
                        }}
                        >
                            {t('clients.newClient')}
                        </MenuItem>
                    </div>
                </Dropdown>
            </div>

        </>
    );
}

export default QuickAccessMenu;
