import {useQuery} from '@tanstack/react-query';
import { AdvisoryListItemTypes } from 'components/common/common.types';
import { useSchema } from 'domain/ClientService';
import ServerError from 'errors/ServerError';

export enum AdvisoryOptionTypesEnum {
    CATEGORY = 'AdvisoryCategoryType',
    INSTITUTE = 'AdvisoryInstituteType',
    STRATEGY = 'AdvisoryStrategyType',
    REAL_STATE_PROPERTY = 'advisoryRealEstateType',
  }

export const useGetListItemsByKey = () => {
    const { getSchemaListByKey } = useSchema();
    const getList = async (type: string) => {
        const result = await getSchemaListByKey<Array<AdvisoryListItemTypes>>({key: type});

        return result;
    };

    const getListItemByKey = ({key} : {key: AdvisoryOptionTypesEnum}) => {
        const response = useQuery<AdvisoryListItemTypes[] | undefined, ServerError>({
            queryKey: [`get${key}`],
            retry: false,
            queryFn: () => getList(key),
        });

        const options = {
            data: response?.data?.map(type => ({
                label: type?.label,
                value: type?.id,
            })) || [],
            error: response?.error || null,
            status: response?.status || null,
        };

        return options;
    };

    return {getListItemByKey};
};
