/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import Icon, {ICON_TYPE_REMOVE} from '../../../ui-library/components/Icon';
import './RemoveConnectionCell.css';

type RemoveConnectionCellProps = {
    onProxyDelete: () => void;
    isDeletable: boolean;
};

const RemoveConnectionCell = ({ onProxyDelete, isDeletable }: RemoveConnectionCellProps) => {
    if (!isDeletable) {
        return null;
    }

    return (
        <button
            type="button"
            role="link"
            className="table-cell_linked cell-remove-connection-view"
            onClick={onProxyDelete}
        >
            {/* @ts-ignore */}
            <Icon type={ICON_TYPE_REMOVE}/>
        </button>
    );
};

export default RemoveConnectionCell;
