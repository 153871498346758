/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector as useReduxSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ObjectSchema } from 'yup';

import { isNil } from 'lodash/fp';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { addDaysToDate, addMonthsToDate, compareDates } from 'utils/datetime';
import {
    useForm,
} from 'react-hook-form';
import {
    CalculatedPlanData, Data, UseWithdrawalPlanBaseProps, UseWithdrawalPlanBaseReturn,
} from '../types';

import { OPTION_LIST } from '../../../../../../../../../constants/constants';
import HandlerError from '../../../../../../../../../errors/HandlerError';
import { useOptionList } from '../../../../../../../../../hooks/useOptionList';
import { memberIdSelector } from '../../../../../../../../../redux-store/auth/authSelectors';
import SM from '../../../../../../../../../services/ServiceManager';
import handlerRequestCanceling from '../../../../../../../../../utils/handlerRequestCanceling';
import { sendWithdrawalPlanModel } from '../models/sendModel';
import { useGoalAndPerformance } from '../../GoalAndPortfolioSetup/hooks/useGoalAndPortfolio';
import { getWithdrawerPlanSchema } from '../schemas/getWithdrawerPlanSchema';
import {useInvestmentContributions} from '../../GoalAndPortfolioSetup/hooks/useInvestmentContributions';

export const useWithdrawalPlanBase = ({
    productSettings,
    modelPortfolioId,
    strategyId,
    isAzp,
    isZiv,
    isZifd,
    isPk,
    investmentApplicationId,
    setPageErrors,
    productExternalId,
    productConfiguration,
}: UseWithdrawalPlanBaseProps): UseWithdrawalPlanBaseReturn => {
    const { t } = useTranslation();

    const [withdrawalPlanData, setWithdrawalPlanData] = useState<CalculatedPlanData | null>(null);
    const [planCalculationLoading, setPlanCalculationLoading] = useState<boolean>(false);
    const [isRegularTransferPlan, setIsRegularTransferPlan] = useState<boolean>(false);
    const { dfsClientId } = useParams();

    const memberId = useReduxSelector(memberIdSelector);
    const option = [
        { label: t('clientDashboard.yes'), id: true },
        { label: t('clientDashboard.no'), id: false },
    ];

    // FIXME: change this after PK is ready
    const isAtLeastOneProductOpened = useMemo(() => true, []);

    const [isLoading, setLoading] = useState(false);
    const {
        lists: [optionWithdrawalPeriodicity],
        isLoading: isOptionsLoading,
    } = useOptionList([OPTION_LIST.WithdrawalPeriodicity]);

    const {
        getInvestmentForNextSixMonths,
    } = useInvestmentContributions(productExternalId!, productSettings, { oneTimeInvestment: productConfiguration?.initialInvestment, periodicPayment: productConfiguration?.periodicalPaymentAmount, paymentPeriod: productConfiguration?.periodicity});

    const nextSixMonthsInvestment = getInvestmentForNextSixMonths();

    const schema: ObjectSchema<Data> = useMemo(
        () => getWithdrawerPlanSchema(
            t,
            productSettings,
            isAzp,
            isZiv,
            isZifd,
            isPk,
            nextSixMonthsInvestment,
        ),
        [t, productSettings, nextSixMonthsInvestment],
    );

    const resolver = useYupValidationResolver(schema);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        register,
        trigger,
        setError,
        formState: { errors: errorData },
    } = useForm<Data>({
        resolver,
    });

    const data = watch();

    const calculateWithdrawalPlan = async () => {
        setPlanCalculationLoading(true);
        try {
            const { data: calculatedPlanData } = await SM.customInvestmentService(
                'calculateWithdrawalPlanData',
                [
                    {
                        investmentApplicationId,
                        ...sendWithdrawalPlanModel(data),
                    },
                ],
            );

            setWithdrawalPlanData(calculatedPlanData);
            setPlanCalculationLoading(false);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setPageErrors,
                    setLoading: setPlanCalculationLoading,
                }),
            )(err);
        }
    };

    useEffect(() => {
        if (!isAzp) return;

        if (
            data.amount
            && data.withdrawalsStartDate
            && data.duration
            && data.frequency
            && investmentApplicationId
        ) {
            calculateWithdrawalPlan();
        }
    }, [
        isAzp,
        data.amount,
        data.withdrawalsStartDate,
        data.duration,
        data.frequency,
        investmentApplicationId,
    ]);

    const displayWithdrawalPlanCalculations = useMemo(
        () => data.amount
            && data.withdrawalsStartDate
            && data.duration
            && data.frequency,
        [data.amount, data.withdrawalsStartDate, data.duration, data.frequency],
    );

    const disableDateHandler = useCallback(
        (current) => {
            if (current) {
                const minLogic = !isNil(productSettings?.minStartDateMonths)
                    ? compareDates(
                        addMonthsToDate(
                            addDaysToDate(new Date(), -1),
                            productSettings?.minStartDateMonths,
                        ),
                        current,
                    )
                    : false;
                const maxLogic = !isNil(productSettings?.maxStartDateMonths)
                    ? compareDates(
                        current,
                        addMonthsToDate(
                            new Date(),
                            productSettings?.maxStartDateMonths,
                        ),
                    )
                    : false;

                return minLogic || maxLogic;
            }

            return current;
        },
        [productSettings],
    );

    return {
        data,
        setData: reset,
        withdrawalPlanData,
        planCalculationLoading,
        setPlanCalculationLoading,
        errorData,
        trigger,
        setErrorData: setError,
        isRegularTransferPlan,
        setIsRegularTransferPlan,
        dfsClientId,
        modelPortfolioId,
        strategyId,
        memberId,
        option,
        isAtLeastOneProductOpened,
        setLoading,
        isLoading,
        optionWithdrawalPeriodicity,
        isOptionsLoading,
        calculateWithdrawalPlan,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        control,
        handleSubmit,
        register,
    };
};
