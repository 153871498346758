import Service from 'additiv-services/services/core/service';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';

class CustomMembersService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.MEMBERS);

    static getPBData(memberId) {
        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/pbnumbers`, { isSecure: true });
    }

    static getBackofficeAccessPolicies() {
        return Service.getRequest(`${this.#baseServiceUrl}/access-policies/backoffice`, { isSecure: true });
    }

    static checkMemberAccessOnClientData({contactId, memberId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/access-policies/${contactId}`, {isSecure: true});
    }

    static getMemberInfo({memberId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/info?memberId=${memberId}`, {isSecure: true});
    }

    static saveMemberInfo(data) {
        return Service.postRequest(`${this.#baseServiceUrl}/save-info`, data, {isSecure: true});
    }

    static getMembers({filter}) {
        return Service.getRequest(`${this.#baseServiceUrl}?filter=${filter}`, {isSecure: true});
    }

    static getDashboardInfo() {
        return Service.getRequest(`${this.#baseServiceUrl}/dashboard-info`, {isSecure: true});
    }

    static updateMemberProfilePicture({memberId, payload}) {
        return Service.putRequest(`${this.#baseServiceUrl}/${memberId}/validate-add-profilepicture`, payload, {isSecure: true});
    }
}

export default CustomMembersService;
