import React, {
    useState, useCallback, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import {MAX_FILE_SIZE} from 'constants/constants';
import { adaptFiles } from 'adaptors/adaptFiles';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import {
    Button, Modal, notification, Icon, Loader,
} from '../../../../../ui-library';
import UploadDocuments from '../../../../../components/UploadDocuments/UploadDocuments';
import Error from '../../../../../components/AtomicStructure/atoms/Error';
import {UPLOAD_DOCUMENT_CONFIG} from '../constants';
import {getBase64FromFile} from '../../../../../utils/file';

import './DocumentUpload.css';

const SCREEN = {
    documentType: {
        id: 1,
        width: 400,
        primaryButtonText: 'clientDashboard.documents.next',
    },
    upload: {
        id: 2,
        width: 800,
        primaryButtonText: 'clientDashboard.documents.upload',
    },
};

export const DocumentUpload = ({
    contactId, onUpload, handleUploadNonIdDocument, personalDocumentNameSuffix = 'personalDocument',
    documentOptions = UPLOAD_DOCUMENT_CONFIG,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [error, setError] = useState();
    const { t } = useTranslation();
    const [screen, setScreen] = useState(SCREEN.documentType);
    const [files, setFiles] = useState(null);
    const [numberOfDocumentsRequired, setNumberOfDocumentsRequired] = useState(0);
    const [documentSize, setDocumentSize] = useState(null);

    const hideModal = () => {
        setScreen(SCREEN.documentType);
        setShowModal(false);
        setError(null);
        setIsLoading(false);
        setSelectedDocumentType('');
        setFiles(null);
    };

    const documentTypes = useMemo(() => [{label: t('position.pleaseSelect'), id: ''}, ...Object.keys(documentOptions).map(key => ({id: key, label: t(documentOptions[key].translation)}))], [t, documentOptions]);

    const uploadPersonalDocs = useCallback(async () => {
        const payload = {
            ownerId: contactId,
            documents: await Promise.all(files.map(async (file) => ({
                filename: file.name,
                file: (await getBase64FromFile(file)).split(',')[1],
            }))),
        };

        const {data} = await SM.customDocumentsService('uploadIdDocument', [payload]);

        return data;
    }, [contactId, files]);

    const uploadClientDataDocs = useCallback(async () => {
        const fileParams = await adaptFiles(contactId, files);

        const {data} = await SM.documents('postDocument', [fileParams]);

        return data;
    }, [contactId, files]);

    const onUploadSuccess = useCallback((result) => {
        notification.open({content: t('clientDashboard.documents.uploadSuccess'), type: 'success'});
        hideModal();
        onUpload(result);
    }, [onUpload]);

    const uploadFile = useCallback(async () => {
        try {
            setIsLoading(true);
            let result = null;

            if (selectedDocumentType === documentOptions.personal.type) {
                result = await uploadPersonalDocs();
            } else {
                if (handleUploadNonIdDocument) {
                    handleUploadNonIdDocument(files, onUploadSuccess, selectedDocumentType);

                    return;
                }
                result = await uploadClientDataDocs();
            }
            onUploadSuccess(result);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    }, [onUploadSuccess, uploadClientDataDocs, uploadPersonalDocs, selectedDocumentType]);

    const onOk = useCallback(async () => {
        if (screen.id === SCREEN.documentType.id && !selectedDocumentType) {
            setError(t('clientDashboard.documents.documentCategoryRequired'));
        } else if (screen.id === SCREEN.documentType.id) {
            setScreen(SCREEN.upload);
        } else if (!files?.length) {
            setError(t('clientDashboard.documents.noFileUploadError'));
        } else {
            await uploadFile();
        }
    }, [screen, uploadFile, selectedDocumentType]);

    const onBackClick = useCallback(() => {
        setScreen(SCREEN.documentType);
        setError(null);
    }, []);

    const renderTitle = useMemo(() => {
        if (screen.id === SCREEN.documentType.id) {
            return t('advisoryDashboard.documents.uploadDocument');
        }

        return (
            <div className="modal-title">
                <div>{`${t('advisoryDashboard.documents.uploadDocument')} - ${t(documentOptions[selectedDocumentType]?.translation)}`}</div>
                {selectedDocumentType === documentOptions.personal.type && <div className="warning">{t('advisoryDashboard.documents.warning')}</div>}
                <Button type="link" onClick={onBackClick}>
                    <Icon size={10} type="arrow-left"/>
                    {t('clientDashboard.documents.back')}
                </Button>
            </div>
        );
    }, [screen, t, selectedDocumentType]);

    useEffect(() => {
        setNumberOfDocumentsRequired(documentOptions[selectedDocumentType]?.numberOfDocuments);
        setDocumentSize(documentOptions[selectedDocumentType]?.size ?? MAX_FILE_SIZE);
        setError(null);
    }, [selectedDocumentType]);

    return (
        <>
            <Button onClick={() => setShowModal(true)}>
                {t('advisoryDashboard.documents.upload')}
            </Button>
            {showModal && (
                <Modal
                    title={renderTitle}
                    visible
                    okText={t(screen.primaryButtonText)}
                    cancelText={t('advisoryDashboard.documents.cancel')}
                    onOk={isLoading ? <Loader/> : onOk}
                    okButtonProps={{disabled: isLoading}}
                    cancelButtonProps={{disabled: isLoading}}
                    onCancel={hideModal}
                    width={screen.width}
                    className="upload-document-modal"
                >
                    {screen.id === SCREEN.documentType.id ? (
                        <SelectElement options={documentTypes} value={selectedDocumentType} onChange={(val) => setSelectedDocumentType(val)}/>
                    ) : <UploadDocuments onSuccess={setFiles} onError={setError} numberOfFiles={numberOfDocumentsRequired} maxMbFileSize={documentSize} />}

                    {error && <Error>{error}</Error>}
                </Modal>
            )}
        </>
    );
};

DocumentUpload.propTypes = {
    contactId: PropTypes.number.isRequired,
    onUpload: PropTypes.func.isRequired,
};

DocumentUpload.defaultProps = {};
