import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import SM from 'services/ServiceManager';
import {adaptModels, adaptStrategies} from 'pages/ClientOverview/pages/Portfolios/adapters/adaptModelPortfolio';
import {useThreeBProductType} from 'hooks/isThreeBProduct';
import {AdditionalData, InvestorProfileHook} from 'widgets/InvestorProfile/types/InvestorProfileHook';
import {useCreatePortfolioActions, useCreatePortfolioSelectors, useCreatePortfolioState} from '../CreatePortfolio';

export const useInvestorProfile = (): InvestorProfileHook => {
    const {i18n: {language}} = useTranslation();

    const {
        jointClientSelector,
        currentClientSelector,
        selectedProductSelector,
        targetInvestorProfileDFSClientIdSelector,
        isJointAccountSelector,
    } = useCreatePortfolioSelectors();

    const {
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();

    const {
        saveInvestmentApplicationDataAction,
        saveRecommendedStrategyAction,
    } = useCreatePortfolioActions();

    const {
        isPk,
    } = useThreeBProductType({product: selectedProductSelector});

    const saveAdditionalData = useCallback(async ({clientId: mClientId, clientRiskToAccept, response}: AdditionalData) => {
        if (mClientId === clientRiskToAccept) {
            let additionalData: any = {recommendedStrategy: response};

            if (isPk) {
                const params = {language};

                const responseMP = await SM.portfolioManagement(
                    'getModelPortfolios',
                    [
                        currentInvestmentApplicationId,
                        params,
                    ],
                );

                const model = adaptModels(responseMP.data)[0];
                const riskCategory = adaptStrategies(responseMP.data)[0];

                const payload = {
                    strategyId: riskCategory?.Id,
                    investmentHorizon: 4,
                    modelPortfolioId: model.Id,
                    withdrawalOption: false,
                };

                additionalData = {
                    ...additionalData,
                    riskCategory,
                    modelPortfolio: model,
                };

                await saveInvestmentApplicationDataAction({
                    payload,
                    method: 'saveStrategyData',
                    additionalData,
                });
            } else {
                saveRecommendedStrategyAction({
                    data: additionalData,
                });
            }
        }
        if (isPk) {
            await SM.customInvestmentService('saveProductData', [{investmentApplicationId: currentInvestmentApplicationId}]);
        }
    }, [language, currentInvestmentApplicationId]);

    return {
        isJointAccount: isJointAccountSelector,
        isPk,
        saveAdditionalData,
        childClientName: jointClientSelector?.name,
        targetClientName: currentClientSelector?.name,
        targetDFSClientId: targetInvestorProfileDFSClientIdSelector,
        clientAge: currentClientSelector?.age,
    };
};
