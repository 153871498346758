import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './BuySellToggle.css';
import withTheme from 'ui-library/hocs/withTheme';
import uid from 'ui-library/utils/uid';


function BuySellToggle(props) {
    const {
        error, onChange, defaultChecked, id, disabled, value = props.defaultChecked, showText, className, ...rest
    } = props;
    const toggleId = id || uid('toggle');
    const [tempChecked, setTempChecked] = useState(defaultChecked);
    const handleChange = ({ target: { checked: isChecked = false } = {} }) => {
        onChange(isChecked);
        setTempChecked(isChecked);
    };

    const onPressEnter = (event) => {
        if (event.which === 13 || event.keyCode === 13) {
            setTempChecked(!tempChecked);
            onChange(!tempChecked);
        }
    };

    useEffect(() => {
        setTempChecked(value);
    }, [value]);


    return (
        <div className={`BuySellToggle ${error ? 'toggle-error' : ''} ${className}`}>
            <input
                {...rest}
                id={toggleId}
                className="switch switch-round"
                type="checkbox"
                onChange={handleChange}
                checked={tempChecked}
                disabled={disabled}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
                role="switch"
                aria-checked={tempChecked}
                htmlFor={toggleId}
                onKeyPress={onPressEnter}
                tabIndex={disabled ? -1 : 0}
            >
                <div className='toggle-wrapper'>
                    <div style={{color:tempChecked?'#2167AE':'#FFFFFF'}}>Buy</div>
                    <div style={{color:tempChecked?'#FFFFFF':'#2167AE'}}>Sell</div>
                </div>
            </label>
        </div>
    );
}

BuySellToggle.propTypes = {
    /** Toggle id */
    id: PropTypes.string,
    /** Toggle id */
    value: PropTypes.bool,
    /** To set the initial state */
    defaultChecked: PropTypes.bool,
    /** Disable toggle */
    disabled: PropTypes.bool,
    /** Enable error view */
    error: PropTypes.bool,
    /** The callback function that is triggered when when the checked state is changing */
    onChange: PropTypes.func,
    showText: PropTypes.bool
};

BuySellToggle.defaultProps = {
    id: null,
    defaultChecked: false,
    value: undefined,
    disabled: false,
    error: false,
    onChange: () => {
    },
    showText: false
};

export default withTheme(BuySellToggle);
