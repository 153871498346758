import {
    TYPE_CURRENCY, TYPE_NUMBER, TYPE_PERCENT, TYPE_RATIO, TYPE_TEXT,
} from 'utils/formatting';
import { OVERVIEW_SIZE_LAGRE, OVERVIEW_SIZE_MEDIUM, OVERVIEW_SIZE_SMALL } from './constants';

export enum OVERVIEW_SIZES_ENUM {
    SMALL= OVERVIEW_SIZE_SMALL,
    MEDIUM = OVERVIEW_SIZE_MEDIUM,
    LAGRE = OVERVIEW_SIZE_LAGRE,
}

export enum VALUE_TYPES {
    CURRENCY= TYPE_CURRENCY,
    PERCENT= TYPE_PERCENT,
    NUMBER = TYPE_NUMBER,
    TEXT= TYPE_TEXT,
    RATIO = TYPE_RATIO,
}

export interface ValueWithLinks {
    label: string | undefined;
    linkTo: string | undefined;
}

export interface OverviewItemProps {
    title: string;
    value?: ValueWithLinks[] | string | number | null;
    size?: OVERVIEW_SIZES_ENUM;
    currencySize?: OVERVIEW_SIZES_ENUM;
    type?: VALUE_TYPES;
    colored?: boolean;
    percent?: number | null,
    percentTooltip?: string | null,
    link?: string,
    linkLabel?: string,
    itemLink?: string,
    currency?: string,
    icon?: string,
    iconColored?: boolean,
    additionalValue?: string,
    RenderValue?: any,
    className?: string,
    valueLinks?: string[],
}
