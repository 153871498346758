/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from '../../../utils';
import { SORT_ORDER } from '../utils/constants';
import SortIcon from './SortIcon';
import Filter from './Filter';
import ColumnConfig from './ColumnConfig';
import { filteringState, sortState } from '../utils/types';

const ButtonWrapper = ({children, needWrap, onClick}) => (needWrap
    ? (
        <span role="button" className="header-column" onClick={onClick}>
            {children}
        </span>
    ) : (<>{children}</>));

function HeaderCell(props) {
    const {
        column,
        columns,
        sorting,
        filtering,
        resetColumns,
        changeColumns,
        changeSorting,
        changeFiltering,
        headerControls,
        isLast,
        isFirst,
    } = props;
    const {
        key,
        title,
        width,
        hidden,
        minWidth,
        sorter,
        sortable: sortableProp,
        filters,
        filterable,
        titleType,
        className,
        onHeaderCell,
        onFilterReset,
        headerClassName,
        lastSorter,
    } = column;

    if (hidden) return null;

    const sortable = sortableProp || typeof sorter === 'function';
    const hasActiveSort = sorting.key === key;
    const sortDirection = hasActiveSort ? SORT_ORDER.indexOf(sorting.direction) : 0;
    const filterValues = filtering.find((item) => item.key === key) || { key, value: [] };
    const { className: classNameProp, ...headerCellPops } = typeof onHeaderCell === 'function' ? onHeaderCell(column) : {};
    const classNameTH = getClassNames(className, headerClassName, classNameProp,
        { 'column-sort': sortable },
        { 'column-filter': filterable },
        { 'column-fixed': titleType === 'columns' },
        { sorted: hasActiveSort },
        { filtered: filterValues.value.length > 0 });
    const isButton = useMemo(() => (filterable || sortable), [sortable, filterable]);
    const onSort = () => {
        changeSorting(sortDirection === SORT_ORDER.length - 1
            ? {} : { key, sorter, direction: SORT_ORDER[sortDirection + 1] });
    };
    const sorterComponent = sortable && (
        <SortIcon
            isSorted={hasActiveSort}
            direction={SORT_ORDER[sortDirection]}
        />
    );
    const onFilter = (value) => {
        changeFiltering({ key, value });
    };
    const filterComponent = filterable && (
        <Filter
            filters={filters}
            selectedKeys={filterValues.value}
            headerControls={headerControls}
            setSelectedKeys={onFilter}
            onReset={onFilterReset}
        />
    );
    const onClick = () => {
        if (sortable) onSort();
    };

    if (titleType === 'columns') {
        return (
            <th
                role="columnheader"
                key={key}
                width={width}
                style={{ minWidth }}
                className={classNameTH}
                {...headerCellPops}
            >
                <ColumnConfig
                    columns={columns}
                    resetColumns={resetColumns}
                    changeColumns={changeColumns}
                    headerControls={headerControls}
                />
            </th>
        );
    }

    return (
        <th
            role="columnheader"
            key={key}
            width={width}
            style={{ minWidth }}
            className={classNameTH}
            {...headerCellPops}
        >
            {(isLast && !isFirst) && filterComponent}

            <ButtonWrapper needWrap={isButton} onClick={onClick}>
                {(!lastSorter && isLast && !isFirst && sorterComponent) }
                {title !== undefined ? title : key}
                {(lastSorter && sorterComponent) || ((!isLast || isFirst) && sorterComponent)}
            </ButtonWrapper>
            {(!isLast || isFirst) && filterComponent}
        </th>
    );
}

HeaderCell.propTypes = {
    column: PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hidden: PropTypes.bool,
        sorter: PropTypes.func,
        sortable: PropTypes.bool,
        filters: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number]),
        })),
        filterable: PropTypes.bool,
        titleType: PropTypes.string,
        className: PropTypes.string,
        headerClassName: PropTypes.string,
        onHeaderCell: PropTypes.func,
        onFilterReset: PropTypes.func,
        lastSorter: PropTypes.bool,
    }),
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hidden: PropTypes.bool,
        sortable: PropTypes.bool,
        className: PropTypes.string,
    })),
    sorting: sortState,
    filtering: filteringState,
    isLast: PropTypes.bool,
    isFirst: PropTypes.bool,
    resetColumns: PropTypes.func,
    changeColumns: PropTypes.func,
    changeSorting: PropTypes.func,
    changeFiltering: PropTypes.func,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
        sortByLabel: PropTypes.string,
    }).isRequired,
};

HeaderCell.defaultProps = {
    column: {
        key: null,
        title: undefined,
        width: null,
        minWidth: null,
        hidden: false,
        sortable: false,
        className: undefined,
        lastSorter: false,
    },
    columns: [],
    isLast: false,
    isFirst: false,
    sorting: null,
    filtering: null,
    resetColumns: null,
    changeColumns: () => {},
    changeSorting: () => {},
    changeFiltering: () => {},
};

export default HeaderCell;
