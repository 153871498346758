import React from 'react';
import PropTypes from 'prop-types';
import './CellStatus.css';

const CellStatus = ({ text, color }) => (
    <span className={`table-cell_status table-cell_status-${color}`}>
        {text}
    </span>
);

CellStatus.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
};

CellStatus.defaultProps = {
    text: null,
    color: null,
};

export default CellStatus;
