import {useQuery} from '@tanstack/react-query';
import ServiceManager from 'services/ServiceManager';
import ServerError from '../../errors/ServerError';

export const useActive3bApplication = ({contactGroupId}) => {
    const result = useQuery({
        queryKey: ['isActive3bApplicationExist', contactGroupId],
        enabled: !!contactGroupId,
        retry: false,
        queryFn: async () => {
            if (contactGroupId) {
                const {data} = await ServiceManager.customClientService('getActiveApplicationIndicator', [{contactGroupId}]);

                return data;
            }

            return null;
        },
    });

    return {
        ...result,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
