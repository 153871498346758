import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { Formio } from 'formiojs';
import AllComponents from 'formiojs/components';
import Components from 'formiojs/components/Components';
import { useCustomTemplates } from './hooks/useCustomTemplates';
import './AutoComplete';
import './LazySelect';
import './FormIo.css';

useCustomTemplates();
Components.setComponents(AllComponents);
// Formio.Components.addComponent('lazyselect', LazySelect);

const FormIo = React.forwardRef((props, ref) => {
    const {
        form, options, submission, formReady,
    } = props;

    const element = ref || useRef();
    const [formio, setFormio] = useState();

    const onAnyEvent = (event, ...args) => {
        if (event.startsWith('formio.')) {
            const funcName = `on${event.charAt(7).toUpperCase()}${event.slice(8)}`;

            if (funcName in props && typeof (props[funcName]) === 'function') {
                props[funcName](...args);
            }
        }
    };
    const initializeFormio = async () => {
        const formioInstance = await Formio.createForm(element.current, form, options);

        if (formioInstance) {
            formioInstance.form = form;
            setFormio(formioInstance);
            if (typeof formReady === 'function') {
                formReady(formioInstance);
            }
        }
    };

    useEffect(() => {
        if (form && element.current) {
            initializeFormio();
        }
    }, [form, element]);
    useEffect(() => {
        if (formio) {
            formio.onAny(onAnyEvent);
        }
    }, [formio]);
    useEffect(() => {
        if (formio && submission) {
            formio.submission = cloneDeep(submission);
        }
    }, [formio, submission]);
    useEffect(() => () => (formio ? formio.destroy(true) : null), [formio]);

    return (
        <div className="formio-wrapper" ref={element} />
    );
});

FormIo.propTypes = {
    form: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
        ]),
    }),
    submission: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
        ]),
    }),
    options: PropTypes.shape({
        readOnly: PropTypes.bool,
        noAlerts: PropTypes.bool,
        template: PropTypes.string,
        saveDraft: PropTypes.bool,
    }),
    formReady: PropTypes.func,
};

FormIo.defaultProps = {
    form: null,
    submission: null,
    options: null,
    formReady: null,
};

export default FormIo;
