import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { AdvisoryResponse } from 'core/types/api/Api';
import ServerError from 'errors/ServerError';
import SM from 'services/ServiceManager';

export const useCalculateStrategyRiskBandwidth = ({advisoryId, isEnabled}) => {
    const result = useQuery({
        queryKey: ['calculateStrategyRiskBandwidth'],
        enabled: !!advisoryId && isEnabled,
        retry: false,
        queryFn: async () => {
            const response: AxiosResponse<AdvisoryResponse> = await SM.customAdvisoryServices(
                'calculateStrategyRiskBandwidth',
                [{advisoryId}],
            );

            return response;
        },
    });

    return {
        // ...result,
        data: result?.data?.data,
        statusCode: result?.data?.status,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
