// datetime.js

import moment from 'moment';
import 'moment/min/locales';

import 'moment-timezone';

export const timeFormat = 'HH:mm';
export const timeFormat12 = 'hh:mm A';
export const timeStandardFormat12 = 'h:mm A';
export const dateFormat = 'YYYY-MM-DD';
export const dateFormatZurich = 'DD.MM.YYYY';
export const dateFlippedFormat = 'DD-MM-YYYY';
export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateTimeSecondsFormat = 'YYYY-MM-DD HH:mm:ss';
export const dateTimeStandardFormat = 'YYYY-MM-DDTHH:mm:ss';
export const dateTimeFormatSlashes = 'DD/MM/YYYY, HH:mm';
export const dateTimeSecondsFormatSlashes = 'DD/MM/YYYY, HH:mm:ss';
export const yearFormat = 'YYYY';
export const monthYearFormat = 'MM-YYYY';
const quarterEndDates = ['31-03', '30-06', '30-09', '31-12'];

export const getDateTimeFormat = (locale = 'en') => {
    // eslint-disable-next-line no-underscore-dangle
    const dateFormatByLocale = moment().locale(locale).creationData().locale._longDateFormat.L;
    // eslint-disable-next-line no-underscore-dangle
    const timeFormatByLocale = moment().locale(locale).creationData().locale._longDateFormat.LT;
    const adaptedTimeFormat = (timeFormatByLocale !== timeStandardFormat12)
        ? timeFormatByLocale
        : timeFormat12;

    return `${dateFormatByLocale} ${adaptedTimeFormat}`;
};

export const getDateFormat = (locale = 'de') => (
    // eslint-disable-next-line no-underscore-dangle
    moment().locale(locale).creationData().locale._longDateFormat.L
);

export const getDateByFormat = (stringDate, format) => moment(stringDate, format).toDate();

export const importLocale = (lang) => {
    try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        require(`moment/locale/${lang === 'en' ? 'en-gb' : lang}`);
    } catch (err) {
        // No need to display error
    }
};

export const detectTZ = () => moment.tz.guess();

export const getTimezoneOffset = () => moment().utcOffset();

export const toBaseDate = (date) => moment(date).toDate();

export const formatDateInUTC = (date, inputFormat, outputFormat = dateTimeStandardFormat) => (
    moment(date, inputFormat).utc().format(outputFormat)
);
export const formatDateInUTCLocal = (date, inputFormat, outputFormat = dateTimeStandardFormat) => (
    moment(date, inputFormat).utc().local().format(outputFormat)
);

export const getUtcDate = (
    date, outputFormat,
) => moment.utc(date).format(outputFormat);

export const formatDate = (
    date, outputFormat = dateFormat, inputFormat,
) => moment.utc(date, inputFormat).local().format(outputFormat);

export const formatDateWithoutLocalConversion = (
    date, outputFormat = dateFormat,
) => moment(date).startOf('day').format(outputFormat);

export const formatTime = (
    date, outputFormat = timeFormat,
) => moment.utc(date).local().format(outputFormat);

export const getTimestamp = (
    date = new Date(), initFormat = moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
) => +moment(date, initFormat);

export const subtractUnits = (
    date, quantity, unit, outputFormat = dateFormat,
) => moment(date).subtract(quantity, unit).format(outputFormat);

export const addUnits = (date, quantity, unit, outputFormat = dateFormat) => (
    moment(date).add(quantity, unit).format(outputFormat)
);

export const getDaysOfYear = (date) => moment(date).dayOfYear();

export const getDatesDifference = (
    date, dateDiff, units,
) => moment(date).diff(dateDiff, units);

export const getFirstDayOfYear = (
    outputFormat = dateFormat,
) => moment().startOf('year').format(outputFormat);

export const getFirstDayOfUnit = (
    unit, date, outputFormat = dateFormat,
) => moment(date).startOf(unit).format(outputFormat);

export const getSecondDayOfYear = (outputFormat = dateFormat) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    moment().dayOfYear(2).format(outputFormat);

export const setYearToDate = (
    date, year, outputFormat = dateFormat,
) => moment(date).set('year', year).format(outputFormat);

export const addMinutesToDate = (
    date, minuteUnits, outputFormat = dateFormat,
) => moment(date).add(minuteUnits, 'minutes').format(outputFormat);

export const addMonthsToDate = (date, months) => {
    date.setMonth(date.getMonth() + months);

    return date;
};

export const addDaysToDate = (date, days) => {
    date.setDate(date.getDate() + days);

    return date;
};

export const addYearsToDate = (
    date, yearUnits, outputFormat = dateFormat,
) => moment(date).add(yearUnits, 'years').format(outputFormat);

export const localResources = (func) => func(moment);

export const isSameDay = (date1, date2) => moment(date1).isSame(date2);

export const compareDates = (date1, date2) => moment(date1) > moment(date2);

export const isQuarterEndDate = (date1) => {
    const tempDate = moment(date1).format('DD-MM');

    return !quarterEndDates.includes(tempDate);
};

export const getNextQuarterEndDate = () => {
    const currentYear = moment().format('YYYY');
    const currentQuarter = moment().quarter();

    return moment(`${quarterEndDates[currentQuarter - 1]}-${currentYear}`, 'DD-MM-YYYY');
};

export const getNextYear = (
    date,
) => moment(date).add(1, 'years');

export const momentWithCeilMinutes = (startDate = new Date(), ceilValue = 5) => {
    const start = moment(startDate);
    const remainder = ceilValue - (start.minute() % ceilValue);

    return moment(start).add(remainder, 'minutes').toDate();
};

export const isWeekendDay = (date) => moment(date).day() === 0 || moment(date).day() === 6;

export const getPreviousDay = (date) => moment(date).subtract(1, 'days').toDate();

export const getFirstNextMonthDay = (
    date = new Date(),
) => new Date(date.getFullYear(), date.getMonth() + 1, 1);

export const getPreviousMonthWithPreviousDay = (
    { date = moment().toDate(), format = dateFormat } = {},
) => (
    moment(date).subtract(1, 'month').subtract(1, 'days').format(format)
);
