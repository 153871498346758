import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Table} from 'ui-library';
import {useFormatting} from 'locale';
import {portfoliosColumns} from '../../constants/constants';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import './Portfolios.css';
import Card from '../Card/Card';
import {adaptRemoveLinkPortfolio} from './adapters/adaptRemoveLinkPortfolio';

function Portfolios({
    data, isLoading, error, onOpenNewPortfolio, onOpenNewPortfolio2, link, pageSize,
}) {
    const {t} = useTranslation();
    const {getFormattedNumber} = useFormatting();

    return (
        <Card
            title={t('clientDashboard.portfolios.plan')}
            titleLink={link}
            headerActions={(
                <>
                    <button
                        data-cy="onOpenNewPortfolio"
                        key="onOpenNewPortfolio"
                        type="button"
                        className="button button-small button-primary"
                        onClick={onOpenNewPortfolio}
                    >
                        {t('clientDashboard.portfolios.openNewPortfolio')}
                    </button>
                    {globalThis.AppConfig.ADVISORY_PROCESS_FEATURE_FLAG === 'true' && (
                        <button
                            data-cy="onOpenNewPortfolio2"
                            key="onOpenNewPortfolio2"
                            type="button"
                            className="button button-small button-primary"
                            onClick={onOpenNewPortfolio2}
                            style={{marginLeft: 16}}
                        >
                            {t('Advisory way')}
                        </button>
                    )}
                </>
            )}
        >
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={data} text={t('clientDashboard.portfolios.noPortfolios')}>
                    {data?.jointRegularPortfolios?.length > 0 && (
                        <>
                            <h3>{t('clientDashboard.portfolios.myAccounts')}</h3>
                            <Table
                                data={adaptRemoveLinkPortfolio(data?.jointRegularPortfolios)}
                                columns={portfoliosColumns(t, getFormattedNumber)}
                                paging={{
                                    pageSize,
                                }}
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                }}
                            />
                        </>
                    )}
                    {data?.poaPortfolios?.length > 0 && (
                        <>
                            <h3>{t('clientDashboard.portfolios.powerOfAttorney')}</h3>
                            <Table
                                data={adaptRemoveLinkPortfolio(data?.poaPortfolios)}
                                columns={portfoliosColumns(t, getFormattedNumber)}
                                paging={{
                                    pageSize,
                                }}
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                }}
                            />
                        </>
                    )}
                    {data?.parentalPortfolios?.length > 0 && (
                        <>
                            <h3>{t('clientDashboard.portfolios.parentalAuthority')}</h3>
                            <Table
                                data={adaptRemoveLinkPortfolio(data?.parentalPortfolios)}
                                columns={portfoliosColumns(t, getFormattedNumber)}
                                paging={{
                                    pageSize,
                                }}
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                }}
                            />
                        </>
                    )}
                </EmptyContent>
            </Preloader>
        </Card>
    );
}

Portfolios.propTypes = {
    data: PropTypes.shape({
        jointRegularPortfolios: PropTypes.arrayOf(
            PropTypes.shape({
                Name: PropTypes.shape({label: PropTypes.string, link: PropTypes.string}),
                Product: PropTypes.string,
                Profile: PropTypes.string,
                Value: PropTypes.string,
                Performance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                Icon: PropTypes.string,
            }),
        ),
        poaPortfolios: PropTypes.arrayOf(
            PropTypes.shape({
                Name: PropTypes.shape({label: PropTypes.string, link: PropTypes.string}),
                Product: PropTypes.string,
                Profile: PropTypes.string,
                Value: PropTypes.string,
                Performance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                Icon: PropTypes.string,
            }),
        ),
        parentalPortfolios: PropTypes.arrayOf(
            PropTypes.shape({
                Name: PropTypes.shape({label: PropTypes.string, link: PropTypes.string}),
                Product: PropTypes.string,
                Profile: PropTypes.string,
                Value: PropTypes.string,
                Performance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                Icon: PropTypes.string,
            }),
        ),
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    onOpenNewPortfolio: PropTypes.func,
    onOpenNewPortfolio2: PropTypes.func,
    link: PropTypes.string,
    pageSize: PropTypes.number,
};

Portfolios.defaultProps = {
    data: {},
    isLoading: true,
    error: null,
    onOpenNewPortfolio: () => {
    },
    onOpenNewPortfolio2: () => {
    },
    link: '',
    pageSize: 9,
};

export default Portfolios;
