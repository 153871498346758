/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button, Infobox, Title, Icon,
} from 'ui-library';
import MeterChart from 'ui-library/components/Charts/MeterChart';
import { ICON_CHECK_SLIM_OUTLINE } from 'ui-library/components/Icon';
import './RiskBandWidthComparison.css';
import { RiskBandWidthComparisonProps } from './RiskBandWidthComparison.types';

const RiskBandWidthComparison = ({
    title, minMaxRange = {from: 9, to: 14}, value = 7.1, setCNPStep,
} : RiskBandWidthComparisonProps) => {
    const {t} = useTranslation();

    const bandwidthStatus = useMemo(() => {
        if (value > minMaxRange?.to) {
            return 'over-range';
        }

        if (value < minMaxRange?.from) {
            return 'below-range';
        }

        return 'in-range';
    }, [value, minMaxRange]);

    const [meterChartDimension, setMeterChartDimension] = useState({
        width: 450,
        height: 297,
    });

    useEffect(() => {
        if (window.innerWidth < 1025) {
            setMeterChartDimension({
                width: 350,
                height: 180,
            });
        } else {
            setMeterChartDimension({
                width: 450,
                height: 297,
            });
        }
    }, []);

    const bandwidthInfo = useMemo(() => {
        switch (bandwidthStatus) {
            case 'in-range':
                return t('RiskBandwidthComparison.Info.StrategyRisk.inRange');

            case 'below-range':
                return t('RiskBandwidthComparison.Info.StrategyRisk.belowRange');

            case 'over-range':
                return t('RiskBandwidthComparison.Info.StrategyRisk.overRange');

            default:
                return '';
        }
    }, [bandwidthStatus]);

    return (
        <section className="risk-bandwidth-comparison-comp">
            <Title type={2}>
                {title}
            </Title>

            <div className="risk-bandwidth-comparison-meter">
                <div className="risk-bandwidth-comparison--meter-chart">
                    <MeterChart width={meterChartDimension.width} height={meterChartDimension.height} max={26} range={minMaxRange} value={Math.round(+value)} />
                    <div className="risk-bandwidth-comparison--meter-chart-volatility" style={{width: meterChartDimension?.width || 'auto'}}>
                        Portfolio volatility:
                        <span>
                            {minMaxRange?.from}
                            {' '}
                            -
                            {minMaxRange?.to}
                            %
                        </span>
                    </div>
                </div>
                <div className="risk-bandwidth-comparison--infobox-wrapper">
                    <Infobox className={bandwidthStatus === 'over-range' ? 'risk-bandwidth-comparison--risk-error' : 'risk-bandwidth-comparison--risk-info'} showIcon={false}>
                        {/* @ts-ignore */}
                        <Icon type={ICON_CHECK_SLIM_OUTLINE} size={20} />
                        {bandwidthInfo}
                    </Infobox>
                    {bandwidthStatus === 'over-range' && (
                        <div className="risk-bandwidth-comparison--infobox-btn-wrapper">
                            <Button type="secondary" onClick={() => setCNPStep({stepKey: 'ShoppingCard'})}>
                                {t('RBC.Button.AdjustProductSelection')}
                            </Button>
                            <Button type="link" className="mt-12" onClick={() => setCNPStep({stepKey: 'AdvisoryStrategy'})}>
                                {t('RBC.Button.AdjustInvestmentStrategy')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default RiskBandWidthComparison;
