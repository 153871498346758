import React from 'react';
import PropTypes from 'prop-types';
import {Loader} from '../../ui-library';
import WidgetError from '../WidgetError';

const Preloader = ({
    isLoading,
    error,
    children,
    letChildrenToRenderOnError,
}) => {
    if (isLoading) return <Loader/>;

    if (error !== null) {
        return (
            <>
                <WidgetError accent error message={error.message}/>
                {
                    letChildrenToRenderOnError && (
                        <>
                            <br/>
                            {children}
                        </>
                    )
                }
            </>
        );
    }

    return children;
};

Preloader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    // children: PropTypes.oneOfType([
    //     PropTypes.element,
    //     PropTypes.array,
    // ]).isRequired,
    letChildrenToRenderOnError: PropTypes.bool,
    error: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    }),
};

Preloader.defaultProps = {
    error: null,
    letChildrenToRenderOnError: false,
};

export default Preloader;
