/* eslint-disable no-restricted-syntax */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {FC} from 'react';
import {Loader, Table, Title} from 'ui-library';
import { useGetAssetsTableColumns } from 'datasource/useOtherAssets/useGetAssetsTableColumns';
import './AssetsDataTable.css';

const AssetsDataTable:FC<any> = ({
    data: tableData, assetDataCategories, totalAssetsData, onEditAssetRow, categoryOptions, instituteOptions, strategyOptions, loading,
}) => {
    const {getColumns} = useGetAssetsTableColumns();

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="assets-data-table-wrapper">
            {assetDataCategories?.map((category: number) => {
                const tableDataBasedOnCategory = tableData?.filter(dta => dta.categoryListItemId === category);

                return (
                    <div className="mb-40" key={category}>
                        <Title type={2} className="font-normal">{categoryOptions?.find((cat: {label: string; value: number}) => cat?.value === category)?.label || ''}</Title>
                        <Table
                            className="assets-data-table"
                            columns={getColumns({
                                onEditAssetRow, categoryOptions, instituteOptions, strategyOptions,
                            })}
                            data={tableDataBasedOnCategory}
                            orderColumns
                        />
                    </div>
                );
            })}
            {totalAssetsData?.instituteListItemId && (
                <Table
                    wrapperClass="total-assets-data-table-wrapper"
                    className="total-assets-data-table"
                    columns={getColumns({})}
                    data={[{...totalAssetsData}]}
                    orderColumns
                />
            )}

        </div>
    );
};

export default AssetsDataTable;
