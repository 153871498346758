import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {memberIdSelector} from 'redux-store/auth/authSelectors';
import {getClientId} from 'utils';
import ServiceManager from 'services/ServiceManager';
import {isThreeBProduct} from 'hooks/isThreeBProduct';
import {IsAllDataNull, validateSelect} from 'validation/validation';
import {STATUS} from 'components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';
import {useCreatePortfolioSelectors, useCreatePortfolioState} from 'datasource/CreatePortfolio';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {validate} from '../validation';

export const useFinalization = ({ dfsClientId }) => {
    const { i18n: { language }, t } = useTranslation();
    const {
        applications,
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();
    const {
        selectedProductSelector,
        currentInvestmentApplicationSelector,
    } = useCreatePortfolioSelectors();
    const [pageErrors, setPageErrors] = useState();
    const isThreeB = isThreeBProduct({product: selectedProductSelector});
    const [formData, setFormData] = useState({
        isConsultationConducted: false,
        transactionByExternalReferrer: false,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const clientId = getClientId(dfsClientId);
    const memberId = useSelector(memberIdSelector);
    const [error, setError] = useState(undefined);

    const appsList = useMemo(() => Object.keys(applications).reduce((akku, key) => {
        if (key !== undefined && key !== 'undefined') {
            akku.push(applications[key]);
        }

        return akku;
    }, []), [applications]);

    const onContinue = useCallback(async () => {
        setIsSubmitted(true);
        const errors = validate(formData);

        setError(errors);

        if (IsAllDataNull(errors) === false) {
            return;
        }

        try {
            setPageErrors();

            setSending(true);

            const { data: planAppData } = await ServiceManager.customInvestmentService('getInvestmentApplication', [{investmentApplicationId: currentInvestmentApplicationId}]);

            if (planAppData.status !== STATUS.in_progress) {
                setPageErrors(t('portfolio.createNewPortfolio.opening.applicationHasAlreadyCompleted'));

                return;
            }

            await ServiceManager.customInvestmentService(
                'finalizeNewPortfolio',
                [{
                    ...formData,
                    investmentApplicationId: currentInvestmentApplicationId,
                    documentLanguage: formData.documentLanguage,
                    isConsultationConducted: formData.isConsultationConducted,
                    transactionByExternalReferrer: formData.transactionByExternalReferrer,
                }],
            );

            // call iban reservation only if single app
            if (!currentInvestmentApplicationSelector?.investmentDescription?.containerId) {
                await ServiceManager.customInvestmentService('makeIbanReservation', [{investmentApplicationId: currentInvestmentApplicationId}]);
            }

            if (!isThreeB) {
                await ServiceManager.customInvestmentService('generateDocuments', [{investmentApplicationId: currentInvestmentApplicationId}]);
            }

            stepComplete();
        } catch (err) {
            setPageErrors(err.message);
        } finally {
            setSending(false);
            setIsSubmitted(false);
        }
    }, [language, clientId, memberId, formData, isThreeB]);

    const yesNoOption = useMemo(() => ([
        { label: t('clientDashboard.yes'), id: true },
        { label: t('clientDashboard.no'), id: false },
    ]), []);

    const languagesOption = useMemo(() => ([
        { label: 'DE', id: 'de' },
        { label: 'FR', id: 'fr' },
        { label: 'IT', id: 'it' },
        { label: 'EN', id: 'en' },
    ]), []);

    const passData = useCallback((value, key) => {
        setError({
            ...error,
            [key]: validateSelect(value),
        });
        setFormData({
            ...formData,
            [key]: value,
        });
    }, [formData]);

    useEffect(() => {
        setFormData({
            ...formData,
            isConsultationConducted: false,
            transactionByExternalReferrer: false,
            documentLanguage: language.toLocaleLowerCase(),
        });
    }, [language]);

    const {stepComplete} = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        continueInProgress: isSending,
        pageErrors,
    });

    return {
        t,
        onContinue,
        yesNoOption,
        languagesOption,
        passData,
        isSubmitted,
        isSending,
        appsList,
        data: formData,
        error,
    };
};
