import {
    validateCheckboxGroup,
    validateInputString,
    validateRadio,
    validateSelect,
} from './validation/validation';
import {dateFormatZurich, formatDate} from '../../utils/datetime';

const toUndefinedIfZero = (val: number | undefined) => (val === 0 ? undefined : val);

export const errorModel: (data: KYCDataPayload, isSwissNationality: boolean) => Promise<KYCErrorData> = async (data: KYCDataPayload, isSwissNationality: boolean) => ({
    professionalSituation: validateSelect(data?.professionalSituation),
    employer: validateInputString(data?.employer),
    industry: validateSelect(data?.industry),
    degreeOfEducation: validateSelect(data?.degreeOfEducation),
    pepStatus: validateRadio(data?.pepStatus),
    pepDescription: data?.pepStatus ? validateSelect(data?.pepDescription) : null,
    beneficialOwner: validateRadio(data?.beneficialOwner),
    originOfAssets: validateCheckboxGroup(data?.originOfAssets),
});
export const getModel = (data: KYCDataPayload) => ({
    ...data,
    pepDescription: toUndefinedIfZero(data?.pepDescription),
    inSwitzerlandSince: toUndefinedIfZero(data?.inSwitzerlandSince),
    plannedLengthOfStay: toUndefinedIfZero(data?.plannedLengthOfStay),
    degreeOfEducation: toUndefinedIfZero(data?.degreeOfEducation),
    professionalSituation: toUndefinedIfZero(data?.professionalSituation),
    industry: toUndefinedIfZero(data?.industry),
    modifiedBy: data?.modifiedBy ?? '-',
    modifiedDate: data?.modifiedDate ? formatDate(data?.modifiedDate, dateFormatZurich) : '-',
});
export const sendModel = ({data, contactId}) => ({
    contactId,
    inSwitzerlandSince: data?.inSwitzerlandSince || 0,
    plannedLengthOfStay: data?.plannedLengthOfStay || 0,
    degreeOfEducation: data?.degreeOfEducation || 0,
    professionalSituation: data?.professionalSituation || 0,
    employer: data?.employer || '',
    industry: data?.industry || 0,
    originOfAssets: data?.originOfAssets?.length ? data?.originOfAssets.map((i) => parseInt(i, 10)) : [0],
    additionalInformation: data?.additionalInformation || '',
    pepStatus: data?.pepStatus || false,
    pepDescription: data?.pepDescription || 0,
    beneficialOwner: data?.beneficialOwner || 0,
});
