import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useCommonAllocationDataProvider
    from 'pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';
import {useThreeBProductType} from 'hooks/isThreeBProduct';
import StrategySelection from 'pages/ClientOverview/pages/Portfolios/components/StrategySelection/StrategySelection';
import {useStrategySelection} from 'pages/ClientOverview/pages/Portfolios/hooks/useStrategySelection';
import {Modal} from 'ui-library';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';

function StrategySelectionPage() {
    const {t} = useTranslation();
    const [pageErrors, setPageErrors] = useState({});

    const {
        selectedProductDescSelector,
        currentInvestmentApplicationSelector,
        is3bProductGroupSelector: is3bProduct,
        selectedProductSelector,
        currentInvestmentDescriptionSelector: cachedInvestmentDescription,
        currentRecommendedStrategySelector,
    } = useCreatePortfolioSelectors();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();

    const {setSetupWithdrawalPlan} = useCommonAllocationDataProvider();
    const {
        isZivZicAzp, isZiv, isZic, isAzp, isZivZifd, isZifd,
    } = useThreeBProductType({product: selectedProductSelector});

    const {
        investmentHorizon,
        selectedStrategyId,
        recommendedStrategy,
        selectedProductMarkId,
        setIndividualPortfolioData,
        onInvestmentHorizonChange,
        onProductMarkClick,
        onSetupWithdrawalPlanChange,
        setupWithdrawalPlan,
        strategies,
        strategy,
        setSelectedStrategyId,
        isIndividualPortfolioSelected,
        individualPortfolioData,
        isRebalance,
        setRebalance,
        selectedProduct,
        isNavigationDisabled,
        products,
        isModalListLoading,
        isAllocationsLoading,
        showChart,
        modelPortfolioDetails,
        setIndividualPortfolioValidationError,
        investmentHorizonError,
        setInvestmentHorizonError,
        productMarksOptions,
        allocations,
        factsheetUrl,
        riskScore,
        checkTheRisk,
        productKey,
        preferenceStock,
        onPreferenceStockChange,
        preferenceExclusion,
        onPreferenceExclusionChange,
        preferenceInclusion,
        onPreferenceInclusionChange,
        exclusionSubstitute,
        onExclusionSubstituteChange,
        strategySelectedError,
        isZifdExclInterestIncome,
    } = useStrategySelection({
        productId: cachedInvestmentDescription?.productId,
        recommendedStrategyScore: currentRecommendedStrategySelector?.Score,
        cachedModelPortfolioId: cachedInvestmentDescription?.selectStrategy?.modelPortfolioId,
        cachedSetupWithdrawalPlan: cachedInvestmentDescription?.selectStrategy?.withdrawalOption,
        cachedPreferenceStock: currentInvestmentApplicationSelector?.preferenceStock,
        cachedPreferenceExclusion: currentInvestmentApplicationSelector?.preferenceExclusion,
        cachedPreferenceInclusion: currentInvestmentApplicationSelector?.preferenceInclusion,
        cachedExclusionSubstitute: currentInvestmentApplicationSelector?.exclusionSubstitute,
        cachedInvestmentHorizon: cachedInvestmentDescription?.selectStrategy?.investmentHorizon,
        setPageErrors,
        productSettings: selectedProductSelector?.settings,
        is3bProduct,
        isZiv,
        isZic,
        isAzp,
        isZifd,
    });

    // Handlers/Callbacks
    const handleOnContinue = useCallback(async () => {
        const strategyId = selectedStrategyId || recommendedStrategy?.Id;

        const tempSetupWithdrawalPlan = isAzp || setupWithdrawalPlan;

        const payload = {
            strategyId,
            investmentHorizon: parseInt(investmentHorizon, 10),
            modelPortfolioId: selectedProductMarkId,
            withdrawalOption: is3bProduct ? tempSetupWithdrawalPlan : undefined,
            ...(isIndividualPortfolioSelected ? {
                individualPortfolio: individualPortfolioData.body.reduce((accu, item) => {
                    if (!item?.allocation?.disabled) {
                        accu.push({
                            securityId: item?.allocation?.id,
                            allocation: item?.allocation?.value / 100,
                        });
                    }

                    return accu;
                }, []),
                rebalancing: isRebalance,
            } : {

            }),
        };

        const additionalData = {
            strategy,
            modelPortfolio: selectedProduct,
            riskScore,
            setupWithdrawalPlan: tempSetupWithdrawalPlan,
            preferenceStock,
            preferenceExclusion,
            exclusionSubstitute,
            preferenceInclusion,
        };

        await saveInvestmentApplicationDataAction({
            payload,
            method: 'saveStrategyData',
            additionalData,
        });

        if (is3bProduct) {
            setSetupWithdrawalPlan(tempSetupWithdrawalPlan);
        }

        stepComplete();
    }, [isRebalance,
        selectedProduct,
        individualPortfolioData,
        investmentHorizon,
        selectedStrategyId,
        recommendedStrategy?.Id,
        selectedProductMarkId,
        riskScore,
        setupWithdrawalPlan,
        preferenceStock,
        preferenceExclusion,
        exclusionSubstitute,
        preferenceInclusion,
        is3bProduct,
        isZiv,
        isAzp,
        isIndividualPortfolioSelected]);

    const onContinue = useCallback(async () => {
        // since Investment Horizon questions is out of the questionnaire, validation needs to be done separately and manually
        if (!investmentHorizon) {
            setInvestmentHorizonError(t('validation.mandatoryField'));

            return;
        }

        const isTheRiskChanged = checkTheRisk(handleOnContinue);

        if (isTheRiskChanged) {
            Modal.confirm(isTheRiskChanged);

            return;
        }

        await handleOnContinue();
    }, [
        investmentHorizon,
        checkTheRisk,
        handleOnContinue,
    ]);

    const {stepComplete} = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        navigationDisabled: isNavigationDisabled,
        pageErrors,
    });

    return (
        <StrategySelection
            productDescription={selectedProductDescSelector}
            {...{
                investmentHorizon,
                investmentHorizonError,
                selectedProduct,
                recommendedStrategy,
                selectedStrategyId,
                setSelectedStrategyId,
                products,
                isIndividualPortfolioSelected,
                isModalListLoading,
                isAllocationsLoading,
                individualPortfolioData,
                isRebalance,
                setRebalance,
                modelPortfolioDetails,
                setIndividualPortfolioData,
                setIndividualPortfolioValidationError,
                selectedProductMarkId,
                productMarksOptions,
                showChart,
                allocations,
                factsheetUrl,
                onInvestmentHorizonChange,
                onProductMarkClick,
                strategies,
                cachedSelectStrategy: selectedProductDescSelector?.selectStrategy,
                productKey,
                onSetupWithdrawalPlanChange,
                setupWithdrawalPlan,
                is3bProduct,
                preferenceStock,
                onPreferenceStockChange,
                preferenceExclusion,
                onPreferenceExclusionChange,
                preferenceInclusion,
                onPreferenceInclusionChange,
                exclusionSubstitute,
                onExclusionSubstituteChange,
                isZivZicAzp,
                isZiv,
                isAzp,
                isZivZifd,
                strategySelectedError,
                isZic,
                isZifdExclInterestIncome,
            }}
        />
    );
}
export default StrategySelectionPage;
