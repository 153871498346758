import {useQuery} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { notification } from 'ui-library';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';

export const useGetSecurityAllocations = ({advisoryId, afterOptimization = false}) => {
    const {t} = useTranslation();
    const getAllocations = async (includeInvestmentApplications) => {
        try {
            return await SM.customAdvisoryServices('getSecurityAllocations', [{advisoryId, includeInvestmentApplications}]);
        } catch (error: any) {
            notification.open({content: `${t('contactGroups.somethingWentWrong')} ${error.message}`, type: 'error'});

            return null;
        }
    };

    const securityAllocations = useQuery({
        queryKey: ['getSecurityAllocations'],
        enabled: !!advisoryId,
        retry: false,
        queryFn: () => getAllocations(false),
    });

    const securityAllocationsAfterOptimization = useQuery({
        queryKey: ['getSecurityAllocationsAfterOptimization'],
        enabled: !!advisoryId && !!afterOptimization,
        retry: false,
        queryFn: () => getAllocations(afterOptimization),
    });

    const error = securityAllocations?.error || securityAllocationsAfterOptimization?.error;
    const status = securityAllocations?.data?.status || securityAllocationsAfterOptimization?.data?.status;

    return {
        securityAllocations: securityAllocations?.data || null,
        securityAllocationsAfterOptimization: securityAllocationsAfterOptimization?.data || null,
        statusCode: status,
        error: error ? new ServerError(error) : error,
    };
};
