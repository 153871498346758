import React, { useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import { getMeterChartOptions } from "./utils";
import './MeterChart.css'

const MeterChart = (props) => {
    const {max = 20, range = {from: 10, to: 15}, value = 12, className = '', width = 450, height =  297} = props || {};

    useEffect(() => {
        const meterPath = document.getElementsByTagName('linearGradient')
        if(meterPath?.length) {
            meterPath[0].setAttribute('id' , 'highcharts-plot-band-0')
        }
    }, [])

    return (
        <HighchartsReact
            containerProps={{ className: `MeterChart ${className}` }}
            options={getMeterChartOptions({max, range, value, width, height}) as any}
        />
    );
};

export default MeterChart;
