import {adaptAnswers} from 'adaptors/adaptAnswers';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import {useClientProfile} from 'prodivers/clientProfile';
import PropTypes from 'prop-types';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import { Title } from 'ui-library';
import {getClientId} from 'utils';

import './QualityCheck.css';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import {useNavigate, useParams} from 'react-router-dom';
import useCreatePortfolio from '../../context/useCreatePortfolio';
import {formDataSelector, questionnaireSelector} from './selectors';
import {useSelector} from './useSelector';

function QualityCheck() {
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const {dfsClientId} = useParams();
    const clientId = getClientId(dfsClientId);

    const {t} = useTranslation();

    // Client Provider
    const {...information} = useClientProfile();
    const {newPortfolio, saveNewPortfolio, layout: { setData: setLayoutData, setPageErrors }} = useCreatePortfolio();
    const {
        data, isLoading, getQuestionnaire, validateQuestionnaire,
        isLoadingPost, postQuestionnaire, error: postuseQuestionnaireError,
    } = useSelector(questionnaireSelector);
    const groupId = useMemo(() => data?.Groups?.[1]?.Id, [data]);
    const {
        data: formData, initializeData, saveData,
    } = useSelector(formDataSelector);
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(
        () => validateQuestionnaire({groupId, answers: formData}),
        [formData, groupId, validateQuestionnaire],
    );

    useEffect(() => {
        getQuestionnaire({investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId});
    }, [getQuestionnaire, newPortfolio?.applicationData?.investmentApplicationId]);
    useEffect(() => {
        if (!formData && data?.Groups?.length) {
            const groupIds = data?.Groups.map(({Id}) => Id);

            initializeData({groupIds});
        }
    }, [data, formData, initializeData]);

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            setSending(true);
            try {
                const answers = adaptAnswers({data, values: formData});

                await postQuestionnaire({answers, investmentApplicationId: newPortfolio?.applicationData?.investmentApplicationId});
                navigate(`/client/${dfsClientId}/portfolios/new-portfolio/opening`);
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading: setSending,
                    }),
                )(err);
                setPageErrors();
            } finally {
                setSending(false);
                setIsSubmitted(false);
            }
        }
    }, [allQuestionsFilled, clientId, data, formData, postQuestionnaire, saveNewPortfolio, newPortfolio]);

    const onBack = useCallback(() => {
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio/overview`);
    }, []);
    const onChange = useCallback(({questionId, value}) => {
        saveData({groupId, questionId, value});
    }, [groupId, saveData]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 8,
            onBack,
            onContinue,
            disabled: information?.isLoading || isLoading || isLoadingPost || isSending,
        });
    }, [onBack, onContinue, information?.isLoading, isLoading, isLoadingPost, isSending]);

    useEffect(() => {
        setLayoutData({saveInProgress: isSending});
    }, [isSending]);

    useEffect(() => {
        setPageErrors(postuseQuestionnaireError);
    }, [postuseQuestionnaireError]);

    return (
        <div className="new-portfolio">
            <Preloader
                isLoading={information?.isLoading || isLoading || isLoadingPost || isSending}
            >
                <Title type={1}>
                    {newPortfolio?.product?.description}
                </Title>
                <div className="new-portfolio-content">
                    <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                        <QuestionnaireGroup
                            data={data?.Groups?.[1]}
                            title={data?.Groups?.[1]?.Name}
                            value={formData?.[groupId] || {}}
                            errors={isSubmitted ? errors : {}}
                            onChange={onChange}
                        />
                    </EmptyContent>
                </div>
            </Preloader>
        </div>
    );
}

export default QualityCheck;
