/* eslint-disable no-restricted-syntax */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {
    number, object, ObjectSchema, string,
} from 'yup';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';
import {AdvisoryOtherInvestmentsResponse, AllocationsResponse} from 'core/types/api/Api';
import {getIntegerObjectValues} from 'widgets/OtherAssets/utils';
import {getValidationMessage} from 'utils/validation';
import {useYupValidationResolver} from 'hooks/useYupValidationResolver';
import {useForm} from 'react-hook-form';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {OtherAssetsFormData} from './useOtherAssets.types';

export const useOtherAssets = () => {
    const {t} = useTranslation();
    const {
        advisoryIdSelector, advisoryDataSelector, advisoryOptions, strategyAllocations,
    } = useCreatePortfolioSelectors();
    const {saveAdvisoryDataAction} = useCreatePortfolioActions();
    const {category: categoryOptions, institute: instituteOptions, strategy: strategyOptions} = advisoryOptions || {};
    const otherInvestmentsData = useMemo(() => advisoryDataSelector?.metadata?.otherInvestment?.investments?.map(investment => {
        const {
            allocations, ...rest
        } = investment;

        const convertedAllocations = getIntegerObjectValues(allocations);

        return {
            allocations: convertedAllocations,
            ...rest,
        };
    }) || [], [advisoryDataSelector?.metadata?.otherInvestment?.investments]);

    const [assetsTableData, setAssetsTableData] = useState<OtherAssetsFormData[]>([]);
    const [defaultValues, setDefaultValues] = useState<any>({});
    const [error, setError] = useState<string>('');
    const [showAddAssetModal, setShowAddAssetModal] = useState<boolean>(false);
    const [continueButtonLoading, setContinueButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        const modifiedAssestData = otherInvestmentsData?.map((data, index) => ({...data, id: `${Date.now()}_${index}`})) || [];

        setAssetsTableData(modifiedAssestData);
    }, [otherInvestmentsData]);

    const {
        mandatoryError, numberError,
    } = getValidationMessage();

    const numberInputValidation = number(mandatoryError)
        .typeError(numberError)
        .nullable(true, mandatoryError)
        .min(0)
        .max(100, 'Value must not be greater than 100%');

    const schema: ObjectSchema<AdvisoryOtherInvestmentsResponse> = useMemo(() => object().shape({
        categoryListItemId: number().nullable().required('Please choose a category.'),
        instituteListItemId: number().nullable().required('Please choose an institute.'),
        description: string().nullable(),
        strategyListItemId: number().nullable(),
        value: number().nullable(false, mandatoryError).required('required').typeError(mandatoryError),
        allocations: object().shape({
            stocks: numberInputValidation,
            liquidity: numberInputValidation,
            bonds: numberInputValidation,
            realEstate: numberInputValidation,
            rawMaterial: numberInputValidation,
            tiedAssets: numberInputValidation,
            other: numberInputValidation,
        }),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: {errors}, watch, reset, setValue, getValues,
    } = useForm({
        resolver,
        defaultValues: otherInvestmentsData,
    });

    const formData = watch();

    const setStrategyValueIfNotSelected = useCallback(() => {
        const strategyIdForIndividual = strategyOptions?.data?.find(option => option.label === 'Individual')?.value;
        const {
            stocks, liquidity, bonds, realEstate, rawMaterial, tiedAssets, other,
        } = formData?.allocations || {};

        const isAllocationTouched = stocks || liquidity || bonds || realEstate || rawMaterial || tiedAssets || other;

        if ((formData?.strategyListItemId === null || formData?.strategyListItemId === undefined) && isAllocationTouched) {
            setValue('strategyListItemId', strategyIdForIndividual);
        }
    }, [formData, strategyOptions, setValue]);

    useEffect(() => {
        setStrategyValueIfNotSelected();
    }, [formData]);

    const onSubmit = useCallback(() => {
        setContinueButtonLoading(true);
        const otherAssets: AdvisoryOtherInvestmentsResponse[] = assetsTableData?.map(asset => {
            const {
                allocations, value, id, ...rest
            } = asset || {};

            const modifiedAllocations = {};

            if (allocations) {
                Object.keys(allocations)?.forEach(key => {
                    modifiedAllocations[key] = allocations[key] / 100;
                });
            }

            return {
                ...rest,
                value: typeof value === 'string' ? +value : value,
                allocations: modifiedAllocations,
            };
        }) || [];

        if (otherAssets?.length) {
            saveData(otherAssets);
        } else {
            setContinueButtonLoading(false);
            unlockNavigationAction();
            stepComplete();
        }
    }, [assetsTableData]);

    const {stepComplete, unlockNavigationAction} = usePortfolioProcessNavigation({
        saveOnContinue: useCallback(onSubmit, [onSubmit]),
        continueInProgress: continueButtonLoading,
        enableAutoStepComplete: false,
    });

    const totalAssetPercentage = useMemo(() => {
        const fieldData = defaultValues?.categoryListItemId ? defaultValues : formData;
        const {
            liquidity = 0,
            bonds = 0,
            other = 0,
            rawMaterial = 0,
            realEstate = 0,
            stocks = 0,
            tiedAssets = 0,
        } = fieldData?.allocations || {};

        return +(+liquidity + +bonds + +other + +rawMaterial + +realEstate + +stocks + +tiedAssets).toFixed(1);
    }, [formData, defaultValues]);

    const getTotalAssetsData = useCallback((array: any[]) => {
        let totalValue = 0;
        const totalAllocations: AllocationsResponse = {
            stocks: 0,
            liquidity: 0,
            bonds: 0,
            realEstate: 0,
            rawMaterial: 0,
            tiedAssets: 0,
            other: 0,
        };

        array.forEach(item => {
            totalValue += +item.value;
            for (const key in item.allocations) {
                if (Object.hasOwn(totalAllocations, key)) {
                    totalAllocations[key] += item.value * (item.allocations[key] / 100);
                }
            }
        });

        // Convert the total allocations to percentages based on the total value
        for (const key in totalAllocations) {
            if (Object.hasOwn(totalAllocations, key)) {
                totalAllocations[key] = ((totalAllocations[key] / +totalValue) * 100).toFixed(1);
            }
        }

        return {
            instituteListItemId: <strong>Total</strong>,
            value: +totalValue?.toFixed(1),
            allocations: totalAllocations,
        };
    }, []);

    const totalAssetsData = useMemo(() => getTotalAssetsData(assetsTableData), [assetsTableData, getTotalAssetsData]);

    const assetDataCategories = useMemo(() => ((items) => {
        const categories = new Set<number>();

        items.forEach(item => {
            if (item?.categoryListItemId) {
                categories.add(item?.categoryListItemId);
            }
        });

        return Array.from(categories);
    })(assetsTableData || []), [assetsTableData]);

    const isTableLoading = useMemo(() => categoryOptions?.status === 'pending' || instituteOptions?.status === 'pending' || strategyOptions?.status === 'pending', [categoryOptions?.status, instituteOptions?.status, strategyOptions?.status]);

    useEffect(() => {
        if (totalAssetPercentage === 100.0) {
            setError('');
        }
    }, [totalAssetPercentage, defaultValues]);

    const toggleAddAssetModal = useCallback((val: boolean) => {
        setShowAddAssetModal(val);
        if (!val) {
            reset();
            setDefaultValues({});
        }
    }, [defaultValues, reset, setDefaultValues, setShowAddAssetModal]);

    const handleAddAsset = useCallback(() => {
        let timeout:ReturnType<typeof setTimeout>;

        if (totalAssetPercentage !== 100.0) {
            // setError('Please check your entry. The total must be 100 percent.');
            setError(t('OtherAssets.errors.totalAssetsPercentageError'));
        } else {
            const dta = getValues();

            if (defaultValues?.id) {
                setAssetsTableData((prevState: OtherAssetsFormData[]) => prevState?.map((prevData) => {
                    let nextData = {...prevData};

                    if (nextData?.id === defaultValues?.id) {
                        nextData = {...dta, id: defaultValues.id};
                    }

                    return nextData;
                }));
            } else {
                setAssetsTableData(prevState => [...prevState, {...dta, id: Date.now()}]);
            }

            timeout = setTimeout(() => {
                toggleAddAssetModal(false);
            }, 300);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [defaultValues, setAssetsTableData, toggleAddAssetModal, watch, totalAssetPercentage]);

    const handleEditAssetRow = useCallback((record: OtherAssetsFormData) => {
        setDefaultValues(record);
        toggleAddAssetModal(true);
    }, [setDefaultValues, toggleAddAssetModal]);

    const deleteAsset = useCallback(() => {
        if (defaultValues?.id) {
            setAssetsTableData((prevState: OtherAssetsFormData[]) => prevState?.filter((prevData) => prevData?.id !== defaultValues?.id));
            toggleAddAssetModal(false);
        }
    }, [defaultValues, setAssetsTableData]);

    const handleStrategyAllocations = useCallback((val: number) => {
        const specificAllocations = strategyAllocations?.find(allocation => allocation?.strategyListItemId === val);

        const updatedData = getIntegerObjectValues(specificAllocations);

        delete updatedData.strategyListItemId;

        setValue('allocations', {...updatedData});
    }, [strategyAllocations, formData, reset]);

    const saveData = async (data: AdvisoryOtherInvestmentsResponse[]) => {
        const payload = {
            otherInvestments: [...data],
        };

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'other-investments',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                unlockNavigationAction();
                stepComplete();
            }
        }
    };

    return {
        categoryOptions,
        instituteOptions,
        strategyOptions,
        defaultValues,
        error,
        showAddAssetModal,
        control,
        errors,
        assetsTableData,
        totalAssetsData,
        assetDataCategories,
        totalAssetPercentage,
        isTableLoading,
        handleAddAsset,
        deleteAsset,
        handleStrategyAllocations,
        handleEditAssetRow,
        toggleAddAssetModal,
        handleSubmit,
    };
};
