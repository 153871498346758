import {
    useCallback, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { useForm } from 'react-hook-form';
import {number, object, ObjectSchema} from 'yup';
import isNil from 'lodash/isNil';
import { getPropertyValueMinusMortgage } from 'utils/advisoty';
import { getValidationMessage } from 'utils/validation';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import { allocationColors, DEFAULT_APP_CURRENCY } from 'constants/constants';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useGetSecurityAllocations } from 'hooks/rest/useGetSecurityAllocations';
import { DesiredInvestAmountForm} from './useAssetsOverview.types';

const useAssetsOverview = () => {
    const {t} = useTranslation();

    usePortfolioProcessNavigation({preventNavigationLock: true});
    const {advisoryIdSelector, advisoryDataSelector} = useCreatePortfolioSelectors();
    const {saveAdvisoryDataAction} = useCreatePortfolioActions();
    const {getFormattedCurrency, getFormattedXAxisFormat} = useFormatting();
    const {numberError} = getValidationMessage();

    const {securityAllocations} = useGetSecurityAllocations({advisoryId: advisoryIdSelector});

    const [continueButtonLoading, setContinueButtonLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const advisoryMetaData = advisoryDataSelector?.metadata;

    const getTotalLoans = useCallback(() => advisoryMetaData?.incomeLiquidity?.loans?.reduce((acc, curr) => acc + (curr?.amount || 0), 0) ?? 0, [advisoryMetaData?.incomeLiquidity?.loans]);

    const schema: ObjectSchema<DesiredInvestAmountForm> = useMemo(() => object().shape({
        desiredInvestmentAmount: number().nullable().typeError(numberError),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        handleSubmit, formState: {errors}, control, reset, watch,
    } = useForm<DesiredInvestAmountForm>({resolver, defaultValues: {desiredInvestmentAmount: advisoryMetaData?.desiredInvestmentAmount ?? 200000.00}});

    const formData = watch();

    const securityAllocationsData = useMemo(() => (securityAllocations?.data ? Object?.keys(securityAllocations?.data)?.map(key => ({
        name: key,
        percent: (securityAllocations?.data?.[key]?.percentage * 100)?.toFixed(2),
        color: allocationColors?.[key],
        value: securityAllocations?.data?.[key]?.value,
        className: key,
        description: '',
    })) : []), [securityAllocations?.data]);

    const data = useMemo(() => ({
        liquidityForInvestment: {...advisoryMetaData?.incomeLiquidity, loans: getTotalLoans()},
        pensionSituation: {...advisoryMetaData?.pensionSituation, otherPensionFund: 1250.00},
        otherAssets: {
            investDepots: advisoryMetaData?.investments?.includedPortfolios?.reduce((acc, curr) => acc + (curr?.value || 0), 0),
            properties: getPropertyValueMinusMortgage(advisoryMetaData?.realEstate?.properties),
            thirdPartyAssets: advisoryMetaData?.otherInvestment?.investments?.filter(investment => investment?.categoryListItemId !== 1302)?.reduce((acc, curr) => acc + (curr?.value || 0), 0),
        },
        desiredInvestmentAmount: advisoryMetaData?.desiredInvestmentAmount,
        securityAllocations: securityAllocationsData,
    }), [advisoryDataSelector, getTotalLoans, getPropertyValueMinusMortgage, securityAllocations?.data]);

    const totalLiquidityForInvestment = useMemo(() => (data?.liquidityForInvestment?.expectedAssetInflow ?? 0) + (data?.liquidityForInvestment?.totalLiquidity ?? 0) - (data?.liquidityForInvestment?.plannedExpenses ?? 0) - getTotalLoans(), [data?.liquidityForInvestment]);
    const totalPensionSituation = useMemo(() => (data?.pensionSituation?.pillar3aAmount ?? 0) + (data?.pensionSituation?.otherPillar3aAmount ?? 0) + (data?.pensionSituation?.vbAmount ?? 0) + (data?.pensionSituation?.otherVbAmount ?? 0) + (data?.pensionSituation?.pensionFund ?? 0) + (data?.pensionSituation?.otherPensionFund ?? 0), [data?.pensionSituation]);
    const totalAmountOfOtherAssets = useMemo(() => ((data?.otherAssets?.investDepots ?? 0) + (data?.otherAssets?.properties ?? 0) + (data?.otherAssets?.thirdPartyAssets ?? 0)), [data?.otherAssets]);

    const formatValue = useCallback((value?: number, displaySing?: '-' | '+') => {
        if (isNil(value)) {
            return t('assetsOverview.notSpecified');
        }

        return getFormattedCurrency(value, {
            currency: `${displaySing ?? ''} ${DEFAULT_APP_CURRENCY}`,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            signDisplay: 'never',
        });
    }, []);

    const closeModal = useCallback(() => {
        setShowModal(false);
        reset();
    }, [reset]);

    const saveData = async (dta) => {
        const payload = {...dta};

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'asset-overview',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                closeModal();
            }
        }
    };

    const handleInvestmentAmount = useCallback(async (dta) => {
        setContinueButtonLoading(true);

        await saveData({
            calculatedInvestmentAmount: totalLiquidityForInvestment || null,
            desiredInvestmentAmount: dta?.desiredInvestmentAmount,
        });
    }, [saveData]);

    return {
        data,
        formData,
        control,
        errors,
        showModal,
        closeModal,
        isLoading: continueButtonLoading,
        handleInvestmentAmount: handleSubmit(handleInvestmentAmount),
        totalLiquidityForInvestment,
        totalPensionSituation,
        totalAmountOfOtherAssets,
        error: null,
        setShowModal,
        formatValue,
        getFormattedXAxisFormat,
    };
};

export default useAssetsOverview;
