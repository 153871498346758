import { useCallback, useEffect, useState } from 'react';
import { getItemFromSS } from 'utils/storage';
import AdapterError from 'errors/AdapterError';

const QUALITY_CHECK_DATA = 'create-portfolio/quality-check/data';

export const useFormData = ({serverData}) => {
    // Form data
    const [data, setData] = useState({});
    const getDataFromSS = useCallback(() => getItemFromSS(QUALITY_CHECK_DATA), []);
    const initializeData = useCallback(({ groupIds }) => {
        if (!groupIds) throw new AdapterError('There is no groupIds for initializeData function');
        const newData = groupIds.reduce((acc, key) => ({ ...acc, [key]: {} }), {});

        setData(newData);
        sessionStorage.setItem(QUALITY_CHECK_DATA, JSON.stringify(newData));
    }, []);
    const saveData = useCallback(({ groupId, questionId, value }) => {
        if (!groupId) throw new AdapterError('There is no groupId for saveData function');
        const newData = { ...data };

        if (!newData[groupId]) {
            newData[groupId] = {};
        }

        if (questionId) {
            newData[groupId][questionId] = value;
        } else {
            newData[groupId] = value;
        }

        setData(newData);
        sessionStorage.setItem(QUALITY_CHECK_DATA, JSON.stringify(newData));
    }, [data]);
    const clearData = useCallback(() => {
        setData(null);
        sessionStorage.removeItem(QUALITY_CHECK_DATA);
    }, []);

    useEffect(() => {
        if (!serverData) {
            return;
        }

        const group = serverData?.Groups?.[1];
        const questions = group?.Steps?.[0]?.Questions;

        const serverValues = {
            [group?.Id]: {
                ...questions?.reduce((capacitor, question) => {
                    if (question?.Value) {
                        capacitor[question?.Id] = question.Type === 'SingleAnswer' ? question?.Value?.Ids?.[0] : question?.Value?.Ids;
                    }

                    return capacitor;
                }, {}),
            },
        };
        const dataFromSS = getDataFromSS();
        const mergedData = {
            [group?.Id]: {
                ...serverValues?.[group?.Id],
                ...dataFromSS?.[group?.Id],
            },
        };

        if (dataFromSS || serverValues) {
            setData(mergedData);
        }
    }, [getDataFromSS, serverData, setData]);

    return {
        data,
        getDataFromSS,
        initializeData,
        saveData,
        clearData,
    };
};
