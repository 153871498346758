import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import SM from 'services/ServiceManager';
import moment from 'moment';
import {useCreatePortfolio} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/context';
import {FEE_TYPES} from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/constants';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
    useCreatePortfolioState,
} from 'datasource/CreatePortfolio';
import distinguishClientType from './distinguishClientType';

export const useCustodyFee = ({
    dfsClientId, isZivAzp, isZic, isAzp,
}) => {
    const {
        layout: { setData: setPageErrors },
    } = useCreatePortfolio();

    const {
        currentInvestmentApplicationSelector,
        currentInvestmentDescriptionSelector,
    } = useCreatePortfolioSelectors();
    const {
        currentInvestmentApplicationId: investmentApplicationId,
    } = useCreatePortfolioState();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();

    const modelPortfolioId = useMemo(() => currentInvestmentDescriptionSelector?.selectStrategy?.modelPortfolioId, [currentInvestmentDescriptionSelector?.selectStrategy?.modelPortfolioId]);
    const investedAmount = useMemo(() => (
        isAzp
            ? currentInvestmentDescriptionSelector?.withdrawal?.investmentAmount
            : currentInvestmentDescriptionSelector?.productConfiguration?.initialInvestment),
    [
        currentInvestmentDescriptionSelector?.productConfiguration?.initialInvestment,
        isAzp,
        currentInvestmentDescriptionSelector?.withdrawal?.investmentAmount,
    ]);
    const clientType = useMemo(() => distinguishClientType(currentInvestmentDescriptionSelector?.personalDetails?.zurichEmployee, currentInvestmentDescriptionSelector?.personalDetails?.destinataryVita),
        [

            currentInvestmentDescriptionSelector?.personalDetails?.zurichEmployee,
            currentInvestmentDescriptionSelector?.personalDetails?.destinataryVita,
        ]);

    const [fees, setFees] = useState([]);

    const defaultCustodyFee = useMemo(() => JSON.parse(currentInvestmentApplicationSelector?.modelPortfolio?.Attributes || '{}')?.custodyFee, [currentInvestmentApplicationSelector?.modelPortfolio?.Attributes]);
    const defaultEntryFee = useMemo(() => JSON.parse(currentInvestmentApplicationSelector?.modelPortfolio?.Attributes || '{}')?.entryCost, [currentInvestmentApplicationSelector?.modelPortfolio?.Attributes]);
    const [defaultAssetFee, setDefaultAssetFee] = useState(null);
    const [defaultAssetDiscount, setDefaultAssetDiscount] = useState(0); // used for vita and zurich customers

    useEffect(() => {
        const fetchAssetData = async () => {
            const payload = {modelPortfolioId, investedAmount, clientType};

            if (isZivAzp) {
                try {
                    const {data} = await SM.planService('getAssetManagement', [payload]);

                    setDefaultAssetFee(data?.standardFee);
                    setDefaultAssetDiscount(data?.discount || 0);
                } catch (error) {
                    // Handle error here
                    console.error(error);
                }
            }
        };

        fetchAssetData();
    }, [isZivAzp, modelPortfolioId, investedAmount, clientType]);

    const defaultFees = useMemo(() => {
        const tempData = [];

        tempData.push({fee: defaultCustodyFee, feeType: FEE_TYPES.custody, startDate: moment()});
        if (isZivAzp) {
            const tempFee = defaultAssetFee * (1 - defaultAssetDiscount / 100);

            tempData.push({
                fee: parseFloat(tempFee.toFixed(2).replace(/\.?0*$/, '')),
                discountValue: defaultAssetDiscount,
                feeType: FEE_TYPES.asset,
                startDate: moment(),
            });
        }

        if (isZic) {
            tempData.push({fee: defaultEntryFee, feeType: FEE_TYPES.entry});
        }

        return tempData;
    }, [isZivAzp, isZic, defaultCustodyFee, defaultAssetFee, defaultEntryFee, defaultAssetDiscount]);

    const saveFeeLocally = (feeType, feeData) => {
        setFees((oldData) => {
            const tempData = [...oldData].filter((item) => item.feeType !== feeType);

            tempData.push(feeData);

            return tempData;
        });
    };

    useEffect(() => {
        setFees(currentInvestmentDescriptionSelector?.fees || defaultFees);
    }, [currentInvestmentDescriptionSelector?.fees, defaultFees]);

    const onSave = useCallback(async () => {
        try {
            const payload = {
                fees,
                investmentApplicationId,
            };

            if (payload.investmentApplicationId) {
                const result = await saveInvestmentApplicationDataAction({
                    payload,
                    method: 'saveCustodyFee',
                });

                return fees;
            }
        } catch (err) {
            setPageErrors(err.message);
        }

        return undefined;
    }, [
        fees, dfsClientId, investmentApplicationId, saveInvestmentApplicationDataAction, defaultCustodyFee,
    ]);

    return {
        feeData: fees,
        onSave,
        defaultCustodyFee,
        defaultAssetFee,
        saveFeeLocally,
        defaultEntryFee,
    };
};
