/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React, { useMemo} from 'react';
import {Link} from 'react-router-dom';
import { Tooltip} from 'ui-library';

import {
    formatSignPercent, formatValueByType, getColorClass,
} from 'utils/formatting';
import {useFormatting} from 'locale';
import {getClassNames} from '../../utils';

import './OverviewItem.css';
import {
    OVERVIEW_SIZES_ENUM, OverviewItemProps, VALUE_TYPES, ValueWithLinks,
} from './Overview.types';

function OverviewItem({
    title,
    value = null,
    size = OVERVIEW_SIZES_ENUM.LAGRE,
    type = VALUE_TYPES.TEXT,
    colored = false,
    percent = null,
    link,
    linkLabel,
    itemLink,
    currency,
    percentTooltip = null,
    iconColored = false,
    additionalValue,
    currencySize = OVERVIEW_SIZES_ENUM.SMALL,
    RenderValue = undefined,
    className: classNameProp,
}: OverviewItemProps) {
    const { getFormattedNumber } = useFormatting();

    const overviewClassName = getClassNames('overview-item', classNameProp);

    const className = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__value'),
        `overview-item__value_${size}`,
    );

    const currencyClassName = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__currency'),
        `overview-item__currency_${currencySize}`,
    );

    let percentComponent: React.JSX.Element | null = null;

    if (percent !== null) {
        percentComponent = (
            <span className={getColorClass(percent, 'overview-item__percent')}>
                {formatSignPercent(percent)}
            </span>
        );
        if (percentTooltip !== null) {
            percentComponent = (
                <Tooltip title={percentTooltip}>
                    {percentComponent}
                </Tooltip>
            );
        }
    }

    const LinkComponent = useMemo(() => {
        if (!link) return null;

        return typeof link === 'function' ? (
            <span role="link" className="overview-item__link" onClick={link}>
                {linkLabel}
            </span>
        ) : (
            <Link className="overview-item__link" to={link}>
                {linkLabel}
            </Link>
        );
    }, [link, linkLabel]);

    const getFormattedValue = ({val = value, color} : {val?: typeof value, color?: boolean}) => {
        if (val === undefined || val === null || val === 'N/A') {
            return 'N/A';
        }
        if (color) {
            return formatValueByType(val, type, colored, getFormattedNumber);
        }

        return formatValueByType(val, type, false, getFormattedNumber);
    };

    return (
        <div className={getClassNames(overviewClassName, getColorClass(iconColored ? value : 0, 'overview-item__text'))}>
            {/* {iconComponent} */}
            {title && (
                <dt className="overview-item__title">
                    {itemLink ? <a href={itemLink}>{title}</a> : title }
                </dt>
            )}
            <dd>
                {RenderValue ? <RenderValue /> : (
                    <>
                        {typeof value === 'object' && value?.[0]?.linkTo ? (
                            value?.map((val: ValueWithLinks, index: number) => (
                                val?.linkTo ? (
                                    <>
                                        <Link to={val?.linkTo as string}>
                                            <span
                                                className={className}
                                                title={getFormattedValue({val: val?.label})}
                                            >
                                                {getFormattedValue({val: val?.label, color: colored})}
                                            </span>
                                        </Link>
                                        {index === value?.length - 1 ? '' : ', '}
                                    </>
                                ) : <></>
                            ))
                        ) : (
                            <span
                                className={className}
                                title={getFormattedValue({})}
                            >
                                {getFormattedValue({color: colored})}
                            </span>
                        )}
                        {type !== 'percent' && currency !== null && (
                            <span className={currencyClassName}>
                                {' '}
                                {currency}
                            </span>
                        )}
                        {' '}
                        {percentComponent}
                    </>
                )}
                {additionalValue && (<div className="additional-value">{additionalValue}</div>)}
                {LinkComponent}
            </dd>
        </div>
    );
}

export default OverviewItem;
