/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {AxiosResponse} from 'axios';
import {
    Icon, notification, Pagination, TabPane, Tabs,
} from 'ui-library';
import {ICON_PROXY_CONNECTION} from 'ui-library/components/Icon';
import SM from 'services/ServiceManager';
import PageSection from 'components/PageSection/PageSection';
import {withMainLayout} from 'hocs';
import Preloader from 'components/Preloader';
import {KYC} from 'constants/constants';
import {useContact} from 'domain/ClientService';
import {getClientId} from 'utils';
import {ContactGroupDetails} from 'components/Connections/types';
import {IsAllDataNull} from 'utils/validation';
import {KYCForm} from 'components/KYCForm';
import {errorModel, getModel, sendModel} from 'components/KYCForm/models';
import {JaUser} from './types';
import './KYCPage.css';
import {usePortfolioProcessNavigation} from '../../hooks/helpers/usePortfolioProcessNavigation';
import {
    usePortfolioProcessLayoutActions,
} from '../../datasource/PortfolioProcessLayout/usePortfolioProcessLayoutActions';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
    useCreatePortfolioState,
} from '../../datasource/CreatePortfolio';

const KYCPage = ({dfsClientId}) => {
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [hasActive3bPortfolio, setHasActive3bPortfolio] = useState<boolean>(false);

    const { t, i18n: {language} } = useTranslation();
    const contactId = getClientId(dfsClientId);
    const {getContact} = useContact({contactId});

    const [personalDetails, setPersonalDetails] = useState<any>({});
    const [jaPersonalDetails, setJaPersonalDetails] = useState<Record<string, any> | null>(null);
    const [data, setData] = useState<KYCDataPayload>({});
    const [jaData, setJaData] = useState<Record<string, any> | null>(null);
    const [jaUsers, setJaUsers] = useState<JaUser[]>([]);
    const [dataExists, setDataExists] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [pageErrors, setPageErrors] = useState();

    const [errorData, setErrorData] = useState<KYCErrorData | null>(null);
    const [jaErrorData, setJaErrorData] = useState<Record<string, KYCErrorData> | null>(null);

    const [isLoading, setLoading] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [completeFunction, setCompleteFunction] = useState<()=>void>(() => {});

    const isSwissNationality = useMemo(() => {
        if (personalDetails?.details?.personalInformation?.identification?.length > 0) {
            return personalDetails?.details?.personalInformation?.identification[0]?.countryOfNationality === KYC.swissNationality;
        }

        return false;
    },
    [personalDetails?.details?.personalInformation]);

    const {
        setAdditionalComponentLabelAction,
        setOnAdditionalComponentLabelAction,
    } = usePortfolioProcessLayoutActions();

    const {
        isJointAccountSelector: isJA,
        contactGroupIdSelector,
    } = useCreatePortfolioSelectors();

    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();

    const {
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();

    const fetchJaKYCData = async (cId) => {
        try {
            const { data: defaultData, status}: AxiosResponse<any> = await SM.customClientService(
                'getKYCData',
                [cId],
            );

            return {
                data: getModel(defaultData),
                status,
            };
        } catch (e: any) {
            notification.open({content: t('general.somethingWentWrong'), type: 'error'});

            return null;
        }
    };

    useEffect(() => {
        (async () => {
            const response = await SM.customClientService('hasThreeBActivePortfolio', [{contactId, contactGroupId: contactGroupIdSelector}]);

            setHasActive3bPortfolio(response.data?.hasActivePortfolios);
        })();
    }, [contactId, contactGroupIdSelector]);

    useEffect(() => {
        if (isJA && !jaUsers.length && contactGroupIdSelector) {
            (async () => {
                setIsUsersLoading(true);
                const response: AxiosResponse<ContactGroupDetails> = await SM.customClientService('getContactGroupDetails', [{ groupId: contactGroupIdSelector }]);

                const users: JaUser[] = [];

                Object.entries(response?.data?.owners || {}).sort((a, b) => +a - +b).forEach(([key, value]) => {
                    users.push({
                        id: key,
                        name: value,
                        isValid: true, // by default is valid, but after validation is done, it will be set to false if there are errors
                    });
                });

                setJaUsers(users);
                setIsUsersLoading(false);
            })();
        }
    }, [isJA, contactGroupIdSelector]);

    useEffect(() => {
        if (isJA && jaUsers.length) {
            (async () => {
                setLoading(true);
                const jaDetails = {};
                const newJaData = {};

                for (const user of jaUsers) {
                    try {
                        const contactDataForm = await getContact({contactId: user.id});

                        jaDetails[user.id] = {...user, ...contactDataForm || {}};

                        const jaKYCData = await fetchJaKYCData(user.id);

                        if (jaKYCData) {
                            newJaData[user.id] = jaKYCData.data;
                        } else {
                            newJaData[user.id] = {
                                contactId: +user.id,
                            };
                        }
                        jaDetails[user.id].dataExists = jaKYCData?.status === 200 || false;
                    } catch (err: any) {
                        setPageErrors(err.message);
                    }
                }

                setJaPersonalDetails(jaDetails);
                setJaData(newJaData);
                setLoading(false);
            })();
        }
    }, [isJA, jaUsers]);

    const fetchKYCData = async () => {
        setLoading(true);
        try {
            const { data: defaultData, status}: AxiosResponse<any> = await SM.customClientService(
                'getKYCData',
                [contactId],
            );

            setData(getModel(defaultData));
            setDataExists(status === 200);
        } catch (e: any) {
            notification.open({content: t('general.somethingWentWrong'), type: 'error'});
        }
        setLoading(false);
    };

    useEffect(() => {
        if (isJA) return;

        /* Get user data */
        setLoading(true);
        if (contactId) {
            getContact({
                contactId,
            }).then((contactDataForm) => {
                setPersonalDetails(contactDataForm);
            }).catch((err) => {
                setPageErrors(err.message);
            });
        }
        fetchKYCData();
    }, [isJA, contactId]);

    const onJaContinue = useCallback(async () => {
        const err = {...jaErrorData};

        if (jaData === null) return;

        const jaValidatedUsers = [...jaUsers];

        let isError = false;

        for (const user of jaValidatedUsers) {
            const errors = await errorModel(jaData[user.id], jaData[user.id]?.details?.personalInformation?.identification[0]?.countryOfNationality === KYC.swissNationality);

            err[user.id] = errors;
            user.isValid = IsAllDataNull(errors);
            isError = isError || !user.isValid;
        }

        setJaErrorData(err);
        if (!isError) {
            try {
                setSaveInProgress(true);
                let allWentGood = true;

                for (const usr of jaUsers) {
                    const payload = sendModel({
                        data: jaData[usr.id],
                        contactId: usr.id,
                    });

                    const dataExistsForUser = jaPersonalDetails ? jaPersonalDetails[usr.id]?.dataExists : false;

                    const response = await SM.customClientService(dataExistsForUser ? 'putKYCData' : 'postKYCData', [usr.id, payload]);

                    if (response) {
                        allWentGood = allWentGood && true;
                    } else {
                        allWentGood = false;
                    }
                }

                if (!allWentGood) return;

                try {
                    await saveInvestmentApplicationDataAction({
                        payload: {kycDone: true},
                        method: 'saveKYCDone',
                        additionalData: {
                            kycDone: true,
                            investmentApplicationId: currentInvestmentApplicationId,
                        },
                    });
                    stepComplete();
                } catch (ex:any) {
                    setPageErrors(ex?.message);
                }
            } catch (ex: any) {
                setPageErrors(ex.message);
            } finally {
                setSaveInProgress(false);
            }
        }
    }, [jaData, jaPersonalDetails]);

    const onContinue = useCallback(async () => {
        if (isJA) {
            onJaContinue();

            return;
        }

        const errors = await errorModel(data, isSwissNationality);

        setErrorData(errors);

        if (IsAllDataNull(errors) !== false) {
            try {
                setSaveInProgress(true);
                const payload = sendModel({data, contactId});

                const response = await SM.customClientService(dataExists ? 'putKYCData' : 'postKYCData', [contactId, payload]);

                let result;

                if (response) {
                    try {
                        result = await saveInvestmentApplicationDataAction({
                            payload: {kycDone: true},
                            method: 'saveKYCDone',
                            additionalData: {
                                kycDone: true,
                            },
                        });
                    } catch (err:any) {
                        setPageErrors(err?.message);
                    }
                }

                setSaveInProgress(false);
                if (!response || !result) return;
                completeFunction();
            } catch (err: any) {
                setPageErrors(err?.message);
                setSaveInProgress(false);
            }
        }
    }, [
        dfsClientId, completeFunction, saveInvestmentApplicationDataAction, data, contactId, dataExists, isJA, jaData, jaPersonalDetails,
    ]);

    // @ts-ignore
    const isBeneficialOwner = useMemo(() => data?.beneficialOwner === KYC.thirdParty || Object.values(jaData || {}).some(s => s?.beneficialOwner === KYC.thirdParty), [data?.beneficialOwner, jaData]);

    const {stepComplete} = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        continueDisabled: isLoading || saveInProgress || isUsersLoading || isBeneficialOwner,
        continueInProgress: saveInProgress,
        pageErrors,
    });

    useEffect(() => {
        setCompleteFunction(stepComplete);
    }, [stepComplete]);

    const onSkip = useCallback(() => {
        setOnAdditionalComponentLabelAction(undefined);
        stepComplete();
    }, [setOnAdditionalComponentLabelAction, stepComplete]);

    useEffect(() => {
        setAdditionalComponentLabelAction(t('cnpLayout.skipButton'));
    }, [setAdditionalComponentLabelAction, language]);
    useEffect(() => {
        setOnAdditionalComponentLabelAction(hasActive3bPortfolio ? onSkip : undefined);
    }, [setOnAdditionalComponentLabelAction, hasActive3bPortfolio, onSkip]);

    useEffect(() => {
        if (jaPersonalDetails === null || !Object.keys(jaPersonalDetails).length || !isJA) return;

        const smallerIndex = Math.min(...Object.keys(jaPersonalDetails).map((id) => +id));

        setCurrentTab(smallerIndex);
    }, [isJA, JSON.stringify(jaPersonalDetails)]);

    useEffect(() => {
        if (jaErrorData === null || !jaUsers.length) return;

        const jaValidatedUsers = [...jaUsers];

        for (const usr of jaValidatedUsers) {
            if (jaErrorData[usr.id]) {
                usr.isValid = IsAllDataNull(jaErrorData[usr.id]);
            }
        }
    }, [JSON.stringify(jaErrorData)]);

    return (
        <PageSection className="newStyle">
            <div className="newStyleContent">
                <Preloader isLoading={isLoading || isUsersLoading}>
                    <div className="container">
                        {!isJA ? (<KYCForm data={data} personalDetails={personalDetails} setData={setData} errorData={errorData} setErrorData={setErrorData} />)
                            : (
                                <>
                                    {jaUsers.length && jaData && (
                                        <Tabs activeKey={currentTab.toString()} onChange={(key) => setCurrentTab(+key)}>
                                            {jaUsers.map((user) => (
                                                <TabPane
                                                    tab={(
                                                        <div className={`tab-title ${!IsAllDataNull(jaErrorData?.[user.id] ?? {}) ? 'tab-error' : ''}`}>
                                                            {/* @ts-ignore */}
                                                            <Icon type={ICON_PROXY_CONNECTION} size={24} />
                                                            <span>{user.name}</span>
                                                        </div>
                                                    )}
                                                    key={user.id}
                                                >
                                                    <KYCForm
                                                        className="ja-form"
                                                        key={user.id}
                                                        data={jaData && jaData[user.id] ? jaData[user.id] : {}}
                                                        personalDetails={jaPersonalDetails && jaPersonalDetails[user.id] ? jaPersonalDetails[user.id] : {}}
                                                        setData={(incomingData) => setJaData(prev => ({...prev, [user.id]: {...incomingData}}))}
                                                        errorData={jaErrorData ? jaErrorData[user.id] : {}}
                                                        setErrorData={(incomingData) => {
                                                            setJaErrorData({
                                                                ...jaErrorData,
                                                                [user.id]: {
                                                                    ...incomingData,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </TabPane>
                                            ))}
                                        </Tabs>
                                    )}
                                </>
                            )}
                    </div>
                    {isJA && jaUsers.length && (
                        <Pagination
                            className="ja-pagination"
                            total={jaUsers.length}
                            currentPage={jaUsers.findIndex((usr) => +usr.id === currentTab) + 1}
                            pageSize={1}
                            onChange={(page) => {
                                jaUsers.forEach((usr, index) => {
                                    if (index === (page - 1)) {
                                        setCurrentTab(+usr.id);
                                    }
                                });
                            }}
                        />
                    )}
                </Preloader>
            </div>
        </PageSection>
    );
};

export default withMainLayout(KYCPage);
