import {useEffect} from 'react';
import {useStepperActions} from '../../core/stepper/useStepperActions';
import {
    usePortfolioProcessLayoutActions,
} from '../../datasource/PortfolioProcessLayout/usePortfolioProcessLayoutActions';
import {useCreatePortfolioActions, useCreatePortfolioState} from '../../datasource/CreatePortfolio';

type SaveOnNavigationProps = {
    saveOnContinue?: () => any;
    saveOnBack?: () => any;
    enableAutoStepComplete?: boolean;
    enableAutoStepBack?: boolean;
    pageErrors?: TServerError | null;
    continueInProgress?: boolean;
    continueDisabled?: boolean;
    navigationDisabled?: boolean;
    backDisabled?: boolean;
    preventNavigationLock?: boolean;
}

export const usePortfolioProcessNavigation = ({
    saveOnContinue,
    saveOnBack,
    enableAutoStepComplete = true,
    enableAutoStepBack = true,
    pageErrors = null,
    navigationDisabled = false,
    continueInProgress = false,
    continueDisabled = false,
    backDisabled = false,
    preventNavigationLock = false,
}: SaveOnNavigationProps = {}) => {
    const {
        stepComplete, stepBack, readyToNavigateAction, setStep,
    } = useStepperActions();
    const {
        onContinueAction, onBackAction, continueLoadingAction, continueDisabledAction, backDisabledAction,
    } = usePortfolioProcessLayoutActions();
    const {
        readyToNavigate,
    } = useCreatePortfolioState();

    const {
        lockNavigationAction,
        unlockNavigationAction,
    } = useCreatePortfolioActions();

    useEffect(() => {
        if (!preventNavigationLock) {
            lockNavigationAction();
            readyToNavigateAction(false);
            stepComplete(false);
        }
    }, [preventNavigationLock]);

    useEffect(() => {
        readyToNavigateAction(!!readyToNavigate);
    }, [readyToNavigate, readyToNavigateAction]);

    useEffect(() => {
        continueLoadingAction(continueInProgress);
    }, [continueInProgress]);
    useEffect(() => {
        continueDisabledAction(continueDisabled);
    }, [continueDisabled]);
    useEffect(() => {
        backDisabledAction(backDisabled);
    }, [backDisabled]);

    useEffect(() => {
        backDisabledAction(navigationDisabled);
        continueDisabledAction(navigationDisabled);
    }, [navigationDisabled]);

    useEffect(() => {
        onContinueAction(async () => {
            if (saveOnContinue) {
                await saveOnContinue();
                unlockNavigationAction();
            } else unlockNavigationAction();
            if (enableAutoStepComplete) stepComplete(true);
        });
    }, [stepComplete, saveOnContinue, enableAutoStepComplete]);

    useEffect(() => {
        onBackAction(async () => {
            if (saveOnBack) await saveOnBack(); else unlockNavigationAction();
            if (enableAutoStepBack) stepBack();
        });
    }, [stepBack, saveOnBack, enableAutoStepBack]);

    return {
        stepComplete,
        unlockNavigationAction,
        setStep,
    };
};
