import React from 'react';
import StarSVG from 'assets/images/star.svg';
import InvestmentApplicationNavigationButton
    from 'components/InvestmentApplicationsActionButton/InvestmentApplicationNavigationButton';
import {APPLICATION_STATUS}
    from '../../../../../../components/InvestmentApplicationsActionButton/InvestmentApplicationsActionButton';

export const applicationsAdapter = ({
    data, t, getFormattedDate,
}) => {
    const tableData = data?.applications?.map(
        ({
            applicationIds,
            advisoryNumber,
            productNames,
            modelPortfolioNames,
            createdDate,
            advisor,
            status,
            containerId,
        }) => ({
            applicationId: () => (
                <>{applicationIds.map((id) => <p>{id}</p>)}</>
            ),
            advisory: advisoryNumber ? () => (
                <p className="advisory-cell nowrap">
                    <img className="star-icon" src={StarSVG} alt={t('starImg')}/>
                    [
                    {advisoryNumber}
                    ]
                </p>
            ) : '',
            products: () => (
                <>{productNames.map((product) => <p className="nowrap">{product}</p>)}</>
            ),
            strategy: () => (
                <>{modelPortfolioNames.map((modelPortfolioName) => <p className="nowrap">{modelPortfolioName}</p>)}</>
            ),
            createdDate: getFormattedDate(createdDate),
            advisorName: () => (
                <>{advisor.map((name) => <p className="nowrap">{name}</p>)}</>
            ),
            status: APPLICATION_STATUS(t)?.[status],
            createNewPlan: () => InvestmentApplicationNavigationButton(
                {
                    destinationId: containerId === '00000000-0000-0000-0000-000000000000' ? applicationIds?.[0] : containerId,
                    destination: containerId === '00000000-0000-0000-0000-000000000000' ? 'application' : 'container',
                },
            ),
        }),
    );

    return {
        totalPages: data?.total,
        applications: tableData,
    };
};
