import React from 'react';
import {useParams} from 'react-router-dom';
import {getClientId} from 'utils';
import {useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';
import QualityCheck from './QualityCheck';
import {QualityCheckProvider} from './Provider';

import './QualityCheck.css';

export function QualityCheckWithProvider() {
    const { dfsClientId } = useParams();
    const clientId = getClientId(dfsClientId);
    const {
        selectedProductIdSelector,
    } = useCreatePortfolioSelectors();

    return (
        <QualityCheckProvider options={{ clientId, productId: selectedProductIdSelector }}>
            <QualityCheck />
        </QualityCheckProvider>
    );
}
