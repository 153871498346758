import React, { FC } from 'react';
import { Title } from 'ui-library';

import './AssetComp.css';
import IconWithTooltip from 'components/common/IconWithTooltip';
import { allocationColors } from 'constants/constants';

interface AssetCompProps {
    colorClass: string;
    title: string;
    amount: string;
    percentage: string;
    description: string;
}

const AssetComp:FC<AssetCompProps> = ({
    colorClass, title, amount, percentage, description,
}) => (
    <div className="asset-comp">
        <div className="asset-comp--color-dot" style={{backgroundColor: allocationColors?.[colorClass]}} />
        <div className="asset-details">
            <div className="asset-title-with-icon">
                <Title type={4} className="asset-title">
                    {title}
                </Title>
                {description && (
                    <IconWithTooltip iconSize={13} description={description} />
                )}
            </div>
            <div>{amount}</div>
            <div>{percentage}</div>
        </div>
    </div>
);

export default AssetComp;
