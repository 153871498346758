import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { Title } from 'ui-library';
import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';

const InvestmentAmountOptions: FC<{desiredInvestmentAmount: number; remainingInvestmentAmount: number }> = ({desiredInvestmentAmount, remainingInvestmentAmount}) => {
    const {t} = useTranslation();

    return (
        <div className="mb-32">
            <div className="investment-amount-options">
                <div className="investment-amount-option-item">
                    <Title type={3}>
                        {/* {t('portfolio.createNewPortfolio.overview.investmentAmount.desired')} */}
                        Desired investment amount
                    </Title>
                    <div className="investment-amount">
                        <strong>
                            CHF
                            {' '}
                            {`${applyThousandSeparator(`${desiredInvestmentAmount}`, '\'')}.00`}
                        </strong>
                    </div>
                </div>
                <div className={`investment-amount-option-item remaining-amount ${remainingInvestmentAmount < 0 ? 'negative-amount' : ''}`}>
                    <Title type={3}>
                        {/* {t('portfolio.createNewPortfolio.overview.investmentAmount.remaining')} */}
                        Remaining investment amount
                    </Title>
                    <div className="investment-amount">
                        <strong>
                            {remainingInvestmentAmount < 0 && '- '}
                            CHF
                            {' '}
                            {`${applyThousandSeparator(`${Math.abs(remainingInvestmentAmount)}`, '\'')}.00`}
                        </strong>
                    </div>
                </div>
            </div>

            {remainingInvestmentAmount < 0 && (
                <div className="negative-remaining-amount-error">
                    {/* {t('portfolio.createNewPortfolio.overview.remainingInvestmentAmountError')} */}
                    Please check the selected investment amounts.
                </div>
            )}
        </div>

    );
};

export default InvestmentAmountOptions;
