import {adaptAnswers} from 'adaptors/adaptAnswers';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import {useClientProfile} from 'prodivers/clientProfile';
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {getClientId} from 'utils';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import {useParams} from 'react-router-dom';
import {useCreatePortfolioState} from 'datasource/CreatePortfolio';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {formDataSelector, questionnaireSelector} from './selectors';
import {useSelector} from './useSelector';

function QualityCheck() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const {dfsClientId} = useParams();
    const clientId = getClientId(dfsClientId);

    const {t} = useTranslation();

    // Client Provider
    const {...information} = useClientProfile();
    const {
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();
    const [pageErrors, setPageErrors] = useState();
    const {
        data, isLoading, getQuestionnaire, validateQuestionnaire,
        isLoadingPost, postQuestionnaire, error: postuseQuestionnaireError,
    } = useSelector(questionnaireSelector);
    const groupId = useMemo(() => data?.Groups?.[1]?.Id, [data]);
    const {
        data: formData, initializeData, saveData,
    } = useSelector(formDataSelector);
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(
        () => validateQuestionnaire({groupId, answers: formData}),
        [formData, groupId, validateQuestionnaire],
    );

    useEffect(() => {
        getQuestionnaire({investmentApplicationId: currentInvestmentApplicationId});
    }, [getQuestionnaire, currentInvestmentApplicationId]);
    useEffect(() => {
        if (!formData && data?.Groups?.length) {
            const groupIds = data?.Groups.map(({Id}) => Id);

            initializeData({groupIds});
        }
    }, [data, formData, initializeData]);

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            setSending(true);
            try {
                const answers = adaptAnswers({data, values: formData});

                await postQuestionnaire({answers, investmentApplicationId: currentInvestmentApplicationId});
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading: setSending,
                    }),
                )(err);
                setPageErrors();
            } finally {
                setSending(false);
                setIsSubmitted(false);
            }
        }
    }, [allQuestionsFilled, clientId, data, formData, postQuestionnaire]);

    const onChange = useCallback(({questionId, value}) => {
        saveData({groupId, questionId, value});
    }, [groupId, saveData]);

    useEffect(() => {
        setPageErrors(postuseQuestionnaireError);
    }, [postuseQuestionnaireError]);

    usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        navigationDisabled: information?.isLoading || isLoading || isLoadingPost || isSending,
        pageErrors,
    });

    return (
        <Preloader
            isLoading={information?.isLoading || isLoading || isLoadingPost || isSending}
        >
            <div className="new-portfolio-content">
                <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                    <QuestionnaireGroup
                        data={data?.Groups?.[1]}
                        title={data?.Groups?.[1]?.Name}
                        value={formData?.[groupId] || {}}
                        errors={isSubmitted ? errors : {}}
                        onChange={onChange}
                    />
                </EmptyContent>
            </div>
        </Preloader>
    );
}

export default QualityCheck;
