import {
    formatBigNumber, getfromDataObject, NA, percentIsZero, validateData, validateNumber,
} from 'utils/formatting';
import { adaptProjection } from 'adaptors/adaptAllocations';

export const adaptPortfolioDetails = (data, options = {}) => {
    const { clientId } = options;
    const t = options?.t || ((d) => d);
    const getFormattedDate = options?.getFormattedDate || ((d) => d);
    const getFormattedCurrency = options?.getFormattedCurrency || ((d) => d);

    let currency;
    let positions = [];
    let currencyId;
    let customAttributes = {};
    const productID = getfromDataObject(data, 'Product.Id');

    const formatCurrency = (value) => getFormattedCurrency(
        value, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        },
    );

    try {
        const attributes = JSON.parse(data.CustomAttributes);

        customAttributes = {
            ...attributes,
            YearlyPayments: formatCurrency(attributes?.YearlyPayments),
            AbsoluteReturnPostGoLive: formatCurrency(attributes?.AbsoluteReturnPostGoLive),
            AbsoluteReturnPreGoLive: formatCurrency(attributes?.AbsoluteReturnPreGoLive),
            AbsoluteReturn: formatCurrency(attributes?.AbsoluteReturn),
            CreateDate: getFormattedDate(attributes?.CreateDate),
            ReturnStartDate: getFormattedDate(attributes?.ReturnStartDate),
        };
    } catch (e) {
        // Do not throw error
    }

    if (data && data?.Currency) {
        currency = data?.Currency.CurrencyCode;
        currencyId = data?.Currency.Id;
    } else if (data && data?.BaseCurrency) {
        currency = data?.BaseCurrency.CurrencyCode;
        currencyId = data?.Currency.Id;
    }

    if (data && data?.Positions) {
        positions = data?.Positions;
    } else if (data && data?.ConsolidatedPositions) {
        positions = data?.ConsolidatedPositions;
    }

    return {
        id: data?.Id,
        backTitle: getfromDataObject(data, 'Name'),
        title: getfromDataObject(data, 'Name'),
        currency,
        currencyId,
        contactGroupId: data?.ContactGroupId,
        customAttributes,
        uid: data?.UId,
        benchmarkId: data?.BenchmarkId,
        createDate: getFormattedDate(data?.CreateDate),
        portfolioValue: data?.CurrentValue,
        investedAmount: data?.InvestedAmount,
        securitiesValue: data?.SecuritiesValue,
        modelPortfolioId: data?.ModelPortfolioId,
        esgPortfolioId: customAttributes?.esg,
        overview: {
            portfolioValue: data?.CurrentValue,
            currency,
            strategy: getfromDataObject(data, 'RiskCategory.Name'),
            product: getfromDataObject(data, 'Product.Name'),
            performance: validateNumber(data?.CurrentPerformance) * 100,
            sharpRatio: data?.SharpeRatio,
            expectedReturn: validateNumber(data?.CalculatedExpectedReturn) * 100,
            expectedRisk: validateNumber(data?.CalculatedRisk) * 100,
            firstInvestment: getFormattedDate(data?.FirstInvestmentDate),
            modelPortfolio: data?.ModelPortfolioName,
        },
        riskCategoryId: getfromDataObject(data, 'RiskCategory.Id'),
        constraints: data?.OptimizationConstraints || {},
        investmentAllocations: (data?.Positions || []).map((item) => ({
            InstrumentId: item.Security.Id,
            Allocation: Number(item.Allocation),
        })),
        productId: productID,
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyId,
            ProjectionYears: 5,
            InitialInvestmentAmount: data?.CurrentValue,
        }),
        riskReturn: [
            +(validateNumber(data?.CalculatedRisk) * 100),
            +(validateNumber(data?.CalculatedExpectedReturn) * 100),
        ],
        riskReturnMatrix: {
            x: [validateNumber(data?.RiskCategory?.Min) * 100,
                validateNumber(data?.RiskCategory?.Max) * 100],
        },
        HistoricalPerformance: data?.HistoricalPerformance,
        isBreached: data?.IsBreached,
        portfolioStatusId: data?.PortfolioStatusId,
        hasPendingOrders: data?.HasPendingOrders,
    };
};
