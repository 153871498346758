import { CellWithLinkOrHyperlinkIcon } from 'components/renderers';
import {renderers} from '../../ui-library';
import {modifyPlan} from './utils/utils';

const {
    CellWithSubtext,
} = renderers;

export const informationColumns = [
    {
        key: 'Information',
        render: CellWithSubtext,
        width: 'calc(100% - 40px)',
    },
    {
        key: 'Icon',
        width: 40,
        render: CellWithLinkOrHyperlinkIcon,
        className: 'text-right',
    },
];

export const FIELD_LENGTH = {
    firstName: 68,
    lastName: 68,
    street: 35,
    city: 40,
    firstAndLastNameCombined: 69,
};

export const portfolioActions = ({
    dfsId, id, t, status, navigate,
}) => {
    const actions = [
        {
            text: `${t('portfoliosColumns.openCase')}`,
            onClick: () => modifyPlan({
                path: `/client/${dfsId}/portfolios/${id}/case-management/`, action: 'OpenCase', id, t, status, navigate,
            }),
        },
        {
            text: `${t('portfoliosColumns.changePlan')}`,
            onClick: () => modifyPlan({
                path: `/client/${dfsId}/portfolios/${id}/change-strategy`, action: 'ChangeStrategy', id, t, status, navigate,
            }),
        },
    ];

    return actions;
};

export const INVESTMENT_HORIZON = {
    lessThen3Years: 0,
    from3To5Years: 1,
    from5to7Years: 2,
    from7to10Years: 3,
    from5To10Years: 5,
    from11To14Years: 6,
    from15To19Years: 7,
    moreThen10Years: 4,
    moreThen20Years: 8,
    lessThen5Years: 9,
};

export const INVESTMENT_HORIZON_OPTIONS = (t, isAzp) => {
    const options = [
        {Id: String(INVESTMENT_HORIZON.lessThen3Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.lessThen3Years')},
        {Id: String(INVESTMENT_HORIZON.from3To5Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.from3To5Years')},
        {Id: String(INVESTMENT_HORIZON.from5to7Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.from5to7Years')},
        {Id: String(INVESTMENT_HORIZON.from7to10Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.from7to10Years')},
        {Id: String(INVESTMENT_HORIZON.moreThen10Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.moreThen10Years')},
    ];

    const azpOptions = [
        {Id: String(INVESTMENT_HORIZON.lessThen5Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.lessThen5Years')},
        {Id: String(INVESTMENT_HORIZON.from5To10Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.from5to10Years')},
        {Id: String(INVESTMENT_HORIZON.from11To14Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.from11to14Years')},
        {Id: String(INVESTMENT_HORIZON.from15To19Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.from15to19Years')},
        {Id: String(INVESTMENT_HORIZON.moreThen20Years), Text: t('portfolio.createNewPortfolio.strategy.investmentHorizon.moreThen20Years')}];

    return isAzp ? azpOptions : options;
};

export const riskCategories = {
    Interest: {
        Id: 1,
        Max: 0.08,
        Min: 0,
        Name: 'Interest',
        Risk: 0,
    },
    Income: {
        Id: 2,
        Name: 'Income',
        Min: 0.08,
        Max: 0.0,
        Risk: 1,
    },
    Balanced: {
        Id: 3,
        Name: 'Balanced',
        Min: 0.1,
        Max: 0.125,
        Risk: 2,
    },
    Growth: {
        Id: 4,
        Name: 'Growth',
        Min: 0.125,
        Max: 0.15,
        Risk: 3,
    },
    CapitalGain: {
        Id: 5,
        Name: 'Capital Gain',
        Min: 0.15,
        Max: 1,
        Risk: 4,
    },
};

export const recommendedCategoryMap = {
    [INVESTMENT_HORIZON.lessThen3Years]: () => riskCategories.Interest,
    [INVESTMENT_HORIZON.lessThen5Years]: () => riskCategories.Interest,
    [INVESTMENT_HORIZON.from3To5Years]: () => riskCategories.Income,
    [INVESTMENT_HORIZON.from5to7Years]: (score) => {
        if (score < 70) {
            return riskCategories.Income;
        }

        return riskCategories.Balanced;
    },
    [INVESTMENT_HORIZON.from7to10Years]: (score) => {
        if (score < 70) {
            return riskCategories.Income;
        }

        if (score <= 100) {
            return riskCategories.Balanced;
        }

        return riskCategories.Growth;
    },
    [INVESTMENT_HORIZON.moreThen10Years]: (score) => {
        if (score < 70) {
            return riskCategories.Balanced;
        }

        if (score <= 100) {
            return riskCategories.Growth;
        }

        return riskCategories.CapitalGain;
    },
    [INVESTMENT_HORIZON.from5To10Years]: () => riskCategories.Income, // change this
    [INVESTMENT_HORIZON.from11To14Years]: (score) => {
        if (score < 70) {
            return riskCategories.Income;
        }

        return riskCategories.Balanced;
    }, // change this
    [INVESTMENT_HORIZON.from15To19Years]: (score) => {
        if (score < 70) {
            return riskCategories.Income;
        }

        if (score <= 100) {
            return riskCategories.Balanced;
        }

        return riskCategories.Growth;
    }, // change this
    [INVESTMENT_HORIZON.moreThen20Years]: (score) => {
        if (score < 70) {
            return riskCategories.Balanced;
        }

        if (score <= 100) {
            return riskCategories.Growth;
        }

        return riskCategories.CapitalGain;
    }, // change this

};

export const SELECETED_PRODUCT_TYPE = 'selected_product_type';

export const ZIFD_MIN_WITHDRAWAL_AMOUNT = 100;
export const ZIV_MIN_WITHDRAWAL_AMOUNT = 1000;

export const SELECTED_INVESTMENT_APP = 'selected-investment-app';

export const CHART_SIZE = {
    INVESTMENT_STRATEGY_CHART: 250,
};
