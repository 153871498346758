import {useCallback, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {getClientId} from 'utils';

import {validateIban} from 'utils/validation';
import HandlerError from '../../../errors/HandlerError';
import handlerRequestCanceling from '../../../utils/handlerRequestCanceling';
import {getModel} from '../models/getModel';
import {sendModel} from '../models/sendModel';
import {useWithdrawalPlanBase} from './useWithdrawalPlanBase';
import {UseWithdrawalPlanReturn} from '../types';
import {usePortfolioProcessNavigation} from '../../../hooks/helpers/usePortfolioProcessNavigation';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
    useCreatePortfolioState,
} from '../../../datasource/CreatePortfolio';

export const useWithdrawalPlan = (): UseWithdrawalPlanReturn => {
    const { dfsClientId } = useParams();
    const contactId = getClientId(dfsClientId);
    const [pageErrors, setPageErrors] = useState();
    const [submitting, setSubmitting] = useState(false);

    const {
        currentClientSelector,
        selectedProductSelector,
        currentInvestmentDescriptionSelector,
        isAzp, isPk, isZiv, isZifd, isAzpI, isAzpD,
    } = useCreatePortfolioSelectors();
    const {
        currentInvestmentApplicationId: investmentApplicationId,
    } = useCreatePortfolioState();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();
    const productSettings = selectedProductSelector?.settings;

    console.log('productSettings', productSettings);

    const {
        data,
        setData,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        trigger,
        setErrorData,
        isRegularTransferPlan,
        setIsRegularTransferPlan,
        memberId,
        option,
        isAtLeastOneProductOpened,
        setLoading,
        isLoading,
        optionWithdrawalPeriodicity,
        isOptionsLoading,
        calculateWithdrawalPlan,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setPlanCalculationLoading,
        control,
        handleSubmit,
        register,
    } = useWithdrawalPlanBase({
        isAzp,
        isPk,
        isZiv,
        isZifd,
        productSettings,
        modelPortfolioId:
            currentInvestmentDescriptionSelector?.selectStrategy
                ?.modelPortfolioId,
        strategyId:
            currentInvestmentDescriptionSelector?.selectStrategy
                ?.strategyId,
        investmentApplicationId,
        setPageErrors,
        productExternalId: selectedProductSelector?.externalId,
        productConfiguration: currentInvestmentDescriptionSelector?.productConfiguration,
    });

    useEffect(() => {
        const firstName = currentClientSelector?.name?.split(' ')[0];
        const lastName = currentClientSelector?.name?.split(' ')[1];

        const adaptedData = getModel(
            currentInvestmentDescriptionSelector,
            { firstName, lastName, isAzp },
        );

        setData(adaptedData);
    }, [currentClientSelector?.name, isAzp]);

    const onContinue = useCallback(
        async (dta) => {
            try {
                const ibanError = await validateIban(dta?.iban);

                if (ibanError) {
                    setErrorData('iban', {
                        message: ibanError as string,
                    });

                    return;
                }

                setSubmitting(true);
                const result = await saveInvestmentApplicationDataAction({
                    payload: {
                        ...sendModel({
                            data: dta,
                            isAzp,
                            ...(withdrawalPlanData || {}),
                        }),
                    },
                    method: 'saveWithdrawalData',
                });

                if (!result) return;
                stepComplete();
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError: setPageErrors,
                        setLoading,
                    }),
                )(err);
            } finally {
                setSubmitting(false);
            }
        },
        [
            contactId,
            memberId,
            isAzp,
            withdrawalPlanData,
            isRegularTransferPlan,
            setPageErrors,
            investmentApplicationId,
        ],
    );

    const {stepComplete} = usePortfolioProcessNavigation({
        saveOnContinue: useCallback(() => (handleSubmit && typeof handleSubmit === 'function'
            ? handleSubmit(onContinue)
            : onContinue)(), [onContinue]),
        enableAutoStepComplete: false,
        navigationDisabled: isLoading,
        pageErrors,
    });

    return {
        data,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        isRegularTransferPlan,
        optionWithdrawalPeriodicity,
        isLoading,
        isOptionsLoading,
        productExternalId: selectedProductSelector?.externalId,
        productDesc: selectedProductSelector?.description,
        isAzp,
        isPk,
        isZiv,
        isZifd,
        isAzpI,
        isAzpD,
        calculateWithdrawalPlan,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setData,
        setErrorData,
        trigger,
        option,
        isAtLeastOneProductOpened,
        setIsRegularTransferPlan,
        setPlanCalculationLoading,
        control,
        register,
    };
};
