import React from 'react';
import Error from './Error';

import './styles/Input.css';
import {createUniqueKey, getClassNames} from '../../../utils';

function Input(props) {
    const elId = createUniqueKey('i-', props.label || 'undefField');

    return (
        <div className={getClassNames(
            'form-group inputBlock',
            props.formClassName,
        )}
        >
            {props.label && (
                <label
                    className="Form-control-label"
                    htmlFor={elId}
                >
                    {props.label}
                    {' '}
                    {props.required && <span>*</span>}
                </label>
            )}
            <input
                id={elId}
                {...props}
                type={props.type || 'text'}
                className={`input ${props.className} ${props.error ? 'errorBorder' : ''}`}
            />
            {props.error && <Error>{props.error}</Error>}
        </div>
    );
}

export default Input;
