import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {AdvisoryIncomeLiquidityMetadataResponse, LoanResponse} from 'core/types/api/Api';
import {notification} from 'ui-library';

import {
    array, object, ObjectSchema, string,
} from 'yup';
import {useFieldArray, useForm} from 'react-hook-form';
import {useYupValidationResolver} from '../../hooks/useYupValidationResolver';

type FormPropsType = {
    grossAnnualIncome: number | null;
    annualSavedAmount: number | null;
    totalLiquidity: number | null;
    plannedExpenses: number | null;
    intendedUse: string | null;
    expectedAssetInflow: number | null;
    originOfAssetInflow: string | null;
    loans: Array<LoanResponse> | null;
}

export const useIncomeAndLiquidity = () => {
    const {t} = useTranslation();
    const {advisoryIdSelector, advisoryDataSelector, advisoryOptions} = useCreatePortfolioSelectors();
    const {saveAdvisoryDataAction} = useCreatePortfolioActions();
    const [continueButtonLoading, setContinueButtonLoading] = useState(false);
    const instituteOptions = advisoryOptions?.institute || {};

    const incomeAndLiquidityData = useMemo(() => advisoryDataSelector?.metadata?.incomeLiquidity || {}, [advisoryDataSelector?.metadata?.incomeLiquidity]);
    const schema: ObjectSchema<FormPropsType> = useMemo(() => object().shape({
        grossAnnualIncome: string().nullable(),
        annualSavedAmount: string().nullable(),
        totalLiquidity: string().nullable(),
        plannedExpenses: string().nullable(),
        intendedUse: string().nullable(),
        expectedAssetInflow: string().nullable(),
        originOfAssetInflow: string().nullable(),
        loans: array().of(object().shape({
            instituteListItemId: string().nullable().required(t('validation.mandatoryField')),
            amount: string().nullable().required(t('validation.mandatoryField')),
        })).nullable(),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: {errors, isDirty},
        //  getValues,
    } = useForm<FormPropsType>({
        resolver,
        defaultValues: incomeAndLiquidityData,
    });

    const {
        fields, append, remove,
    } = useFieldArray({
        name: 'loans',
        control,
    });

    const addNewLoan = useCallback(() => {
        append({instituteListItemId: undefined, amount: undefined});
    }, [append]);

    const removeLoan = useCallback((index: number) => {
        remove(index);
    }, [remove]);

    const saveData = async (data: AdvisoryIncomeLiquidityMetadataResponse) => {
        const payload = {...data};

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'income-liquidity',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                unlockNavigationAction();
                stepComplete();
            }
        } else {
            notification.open({content: `${t('contactGroups.somethingWentWrong')}`, type: 'error'});
            setContinueButtonLoading(false);
        }
    };

    const onSubmit = useCallback(async (dta) => {
        if (isDirty) {
            setContinueButtonLoading(true);

            await saveData({
                loans: dta?.loans?.filter((l) => l.instituteListItemId && l.amount)?.map((loan) => ({
                    amount: +loan?.amount,
                    instituteListItemId: +loan?.instituteListItemId,
                })),
                originOfAssetInflow: dta?.originOfAssetInflow,
                expectedAssetInflow: +dta?.expectedAssetInflow,
                intendedUse: dta?.intendedUse,
                plannedExpenses: +dta?.plannedExpenses,
                totalLiquidity: +dta?.totalLiquidity,
                annualSavedAmount: +dta?.annualSavedAmount,
                grossAnnualIncome: +dta?.grossAnnualIncome,
            });
        } else {
            unlockNavigationAction();
            stepComplete();
        }
    }, [isDirty]);

    const {stepComplete, unlockNavigationAction} = usePortfolioProcessNavigation({
        saveOnContinue: useCallback(handleSubmit(onSubmit), [onSubmit]),
        continueInProgress: continueButtonLoading,
        enableAutoStepComplete: !isDirty,
    });

    return {
        instituteOptions,
        fields,
        control,
        errors,
        addNewLoan,
        removeLoan,
    };
};
