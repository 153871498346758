/* eslint-disable react/require-default-props */
import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal, RadioButtonGroup, Select} from '../../ui-library';
import {ContactGroup, ContactGroupEnum} from '../Connections/types';

import './ThreeBGroupsModal.css';

type ThreeBGroupsModalProps = {
    show: boolean;
    onClose: () => void;
    onSelect: (groupId: string, isJointAccount: boolean) => void;
    onChangeInvestorProfileClient?: (clientId) => void
    groups: Array<ContactGroup>;
    clientId?: number;
}

export const ThreeBGroupsModal = ({
    show, onClose, groups, onSelect, clientId, onChangeInvestorProfileClient,
}: ThreeBGroupsModalProps) => {
    const {t} = useTranslation();
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const [error, setError] = useState(null);
    const selectedGroupItem = useMemo(() => groups.find(f => f.groupId === parseInt(selectedGroup, 10)), [selectedGroup]);

    const closeModal = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (selectedGroup) {
            setError(null);
        }
    }, [selectedGroup]);

    const onSelectedGroup = useCallback(() => {
        if (!selectedGroup) {
            setError(t('threeBGroupsModal.requiredField'));

            return;
        }

        setError(null);
        onSelect(selectedGroup, selectedGroupItem?.groupName === ContactGroupEnum.ja);
    }, [onSelect, selectedGroup, t, selectedGroupItem]);

    const groupItems = useMemo(() => [{value: '', label: t('position.pleaseSelect')}, ...(groups.map(i => ({label: `${i.groupName !== ContactGroupEnum.threeB ? `${t(`connections.groupName.${i.groupName.toLowerCase()}`)} | ` : ''}${Object.values(i.owners).join(', ')}`, value: i.groupId, desc: i.groupName})).sort((a, b) => {
        if (a.desc < b.desc) return -1;
        if (a.desc > b.desc) return 1;

        return 0;
    }))], [groups]);

    const joinAccountOwners = useMemo(() => Object?.keys(selectedGroupItem?.owners || {})?.map(key => ({
        label: selectedGroupItem?.owners?.[key],
        value: key,
    })), [selectedGroupItem?.owners]);

    const handleSelectMainClient = useCallback((e) => {
        onChangeInvestorProfileClient?.(e.target.value);
    }, [onChangeInvestorProfileClient]);

    return (
        <Modal className="three-b-groups-modal" visible={show} onCancel={closeModal} title={t('threeBGroupsModal.title')} onOk={onSelectedGroup} width={515}>
            {/* <p className="desc">{t('threeBGroupsModal.desc')}</p> */}
            <Select
                options={groupItems}
                label=""
                value={selectedGroup}
                onChange={setSelectedGroup}
                error={error}
            />
            {clientId && Object?.keys(selectedGroupItem?.owners || {})?.length > 1 && (
                <div className="select-main-client">
                    <p>
                        {t('productOffer.3bGroup.defineMainClient.title')}
                        :
                    </p>
                    <RadioButtonGroup
                        horizontal
                        options={joinAccountOwners}
                        onChange={handleSelectMainClient}
                        error=""
                        value={`${clientId}`}
                    />
                </div>
            )}
        </Modal>
    );
};
