import React, {forwardRef, useMemo} from 'react';
import { Select } from '../../../ui-library';
import Error from './Error';

import './styles/Input.css';
import './styles/SelectElement.css';
import { getDataOrAriaProps } from '../../../utils';

const SelectElement = (props) => {
    const optionsDefault = [{ label: 'N/A', value: 'N/A' }];

    const {
        options,
        onChange,
        required,
        value,
        placeholder,
        hasSearch,
        asyncSearch,
        searchInProgress,
        loadMoreItems,
        hasMoreItems,
        disabled: disableSelect,
        innerRef,
        className,
        ...rest
    } = props;

    const optionsMemo = useMemo(() => (options === undefined || options === null ? optionsDefault : [...new Set(options?.map(({ id, label, disabled }) => ({ label, value: String(id), disabled })))]), [options]);

    return (
        <div className={`form-group selectElement inputBlock ${className}`}>
            {props.label && (
                <label className="inputLabel" htmlFor="inputLabel">
                    {props.label}
                    {' '}
                    {props.required && <span> *</span>}
                </label>
            )}
            <div className={`${props.error ? 'errorBorder' : ''}`}>
                <Select
                    disabled={disableSelect}
                    ref={innerRef}
                    options={optionsMemo}
                    value={props.defaultValue ? props.defaultValue : value}
                    withInfobox={false}
                    onChange={onChange}
                    required={required}
                    placeholder={placeholder}
                    hasSearch={hasSearch}
                    asyncSearch={asyncSearch}
                    searchInProgress={searchInProgress}
                    loadMoreItems={loadMoreItems}
                    hasMoreItems={hasMoreItems}
                    {...getDataOrAriaProps(rest)}
                />
            </div>
            {props.error && <Error>{props.error}</Error>}
        </div>
    );
};

export default forwardRef((props, ref) => <SelectElement {...props} innerRef={ref} />);
