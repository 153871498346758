import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from 'ui-library';
import { getClassNames } from 'utils';
import './CellWithSubtextAndTooltip.css';

function CellWithSubtextAndTooltip({ title, subtitle, maxLength }) {
    const showTooltip = subtitle.length > maxLength;
    const className = getClassNames(
        'table-cell_tooltipped',
        { 'table-cell_tooltipped-show': showTooltip },
    );

    const trimmedValue = subtitle.length > maxLength
        ? `${subtitle.substring(0, maxLength)}...`
        : subtitle;

    return (
        <div className="table-cell_with-subtext">
            <b>{title}</b>
            <span className={className}>
                <span>{trimmedValue}</span>
                {showTooltip && (
                    <Tooltip
                        title={subtitle}
                        overlayClassName="ant-tooltip-table"
                        className="table-tooltip"
                        trigger="click"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Icon className="icon__tooltipped" size={10} type="questionmark" />
                    </Tooltip>
                )}
            </span>
        </div>
    );
}

CellWithSubtextAndTooltip.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    maxLength: PropTypes.number,
};

CellWithSubtextAndTooltip.defaultProps = {
    title: null,
    subtitle: null,
    maxLength: 20,
};

export default CellWithSubtextAndTooltip;
