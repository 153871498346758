import React, {
    FC, useCallback, useMemo, useState,
} from 'react';
import {useSelector as useReduxSelector} from 'react-redux';
import {useFieldArray, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {
    number, object, ObjectSchema, string,
} from 'yup';

import {useYupValidationResolver} from 'hooks/useYupValidationResolver';
import {Infobox, Title} from 'ui-library';
import NewProducts from 'ui-library/components/NewProducts/NewProducts';
import ExistingProducts from 'ui-library/components/ExistingProducts/ExistingProducts';
import InvestmentAmountOptions from 'ui-library/components/InvestmentAmountOptions/InvestmentAmountOptions';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {InvestmentItemProps, OverviewStepProps} from './ShoppingCard.types';
import {defaultProducts} from './static';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from '../../datasource/CreatePortfolio';
import {memberIdSelector} from '../../redux-store/auth/authSelectors';
import './ShoppingCard.css';

const ShoppingCard: FC<OverviewStepProps> = () => {
    const {t} = useTranslation();

    const desiredInvestmentAmount: number = 600000.00;

    const schema: ObjectSchema<InvestmentItemProps> = useMemo(() => object().shape({
        product: string().nullable(),
        amount: number().nullable(),
        strategy: string().nullable(),
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: {errors}, watch,
    } = useForm({
        resolver,
        defaultValues: defaultProducts,
    });
    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'products',
    });

    const data = watch();

    const [totalInvestmentAmount, setTotalInvestmentAmount] = useState<number>(0);
    const remainingInvestmentAmount = desiredInvestmentAmount - totalInvestmentAmount;

    const newProducts: InvestmentItemProps[] = useMemo(() => data?.products?.filter(product => product.new), [data]);
    const existingProducts: InvestmentItemProps[] = useMemo(() => data?.products?.filter(product => product.new === false), [data]);

    const handleAddProduct = useCallback(() => {
        append({
            id: Date.now(), new: true, product: '', amount: null,
        });
    }, [append]);

    const handleDeleteRow = useCallback((index: number) => {
        // remove(index);
    }, [remove]);

    // testing mock:
    const {
        selectedProductIdSelector: selectedProductId,
        selectedProductSelector,
        contactGroupIdSelector,
        isJointAccountSelector,
        currentClientSelector,
        is3bProductGroupSelector,
    } = useCreatePortfolioSelectors();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();
    const memberId = useReduxSelector(memberIdSelector);

    const onContinue = useCallback(async () => {
        try {
            const payload = {
                productId: 21,
                contactId: currentClientSelector?.id,
                memberId,
                productExternalId: 'ZIFD',
                contactGroupId: contactGroupIdSelector,
                isJointAccount: isJointAccountSelector,
            };

            const additionalData = {
                product: selectedProductSelector,
            };

            const result = await saveInvestmentApplicationDataAction({
                payload,
                method: 'saveProductDetails',
                additionalData,
            });

            if (!result) return;

            stepComplete();
        } catch (e) {
            console.error(e);
        }
    }, [
        selectedProductSelector,
        contactGroupIdSelector,
        isJointAccountSelector,
        currentClientSelector?.id,
        memberId, selectedProductId,
    ]);
    // testing mock end

    const {stepComplete} = usePortfolioProcessNavigation({
        preventNavigationLock: true,
        saveOnContinue: is3bProductGroupSelector ? onContinue : () => {},
    });

    return (
        <div className="shopping-card new-portfolio agent-info">
            <Title type={2} className="mb-16">
                {/* {t('portfolio.createNewPortfolio.overview.portfolioPersonalOverview')} */}
                Personal Overview
            </Title>
            {totalInvestmentAmount >= 200000 && (
                <Infobox warning showIcon={false} className="mb-32">
                    Products without consultation can only be opened up to a planned investment within the next 6 months
                    of CHF 200’000. For larger investments, a full consultation must be conducted
                </Infobox>
            )}
            <InvestmentAmountOptions
                desiredInvestmentAmount={desiredInvestmentAmount}
                remainingInvestmentAmount={remainingInvestmentAmount}
            />

            <NewProducts
                remainingInvestmentAmount={remainingInvestmentAmount}
                handleAddProduct={handleAddProduct}
                handleDeleteRow={handleDeleteRow}
                products={newProducts || []}
                control={control}
                errors={errors}
            />
            <ExistingProducts products={existingProducts || []} control={control} errors={errors}/>
        </div>
    );
};

export default ShoppingCard;
