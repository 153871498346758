import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    DatePicker, Paragraph, Select, Modal,
} from 'ui-library';
import { compareDates, formatDate, getDateFormat } from 'utils/datetime';
import { datePickerLocale } from 'constants/constants';
import { useLocale } from 'locale';
import { CUSTOM_RANGE } from 'filters/investementPerformanceFilters';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileSize';
import { useCustomRange } from './hooks/useCustomRange';
import './CustomRangeSelect.css';

const CustomRangeSelect = (props) => {
    const {
        width,
        value,
        options,
        onChange,
    } = props;
    const { t } = useTranslation();
    const { locale } = useLocale();
    const previousFilter = useRef();
    const [filter, setFilter] = useState(value?.startDate ? CUSTOM_RANGE : value);
    const [visible, setVisible] = useState(false);
    const format = useMemo(() => getDateFormat(locale), [locale]);
    const defaultData = useMemo(() => ({ endDate: new Date() }), []);
    const { isTabletSize } = useDetectedMobileDevice();

    // Form
    const {
        register, formErrors, handleSubmit, onChange: onFormChange, values,
    } = useCustomRange(defaultData);

    // Data
    const optionsWithCustom = useMemo(() => [
        ...options,
        { value: CUSTOM_RANGE, label: t('dashboard.assetDevelopment.filters.customRange') },
    ], [options, t]);
    const modalBody = useMemo(() => (
        <div className="CustomRangeForm">
            <Paragraph type="secondary">
                {t('confirmation.customRange.description')}
            </Paragraph>
            <DatePicker
                {...register('startDate')}
                name="startDate"
                value={values.startDate && formatDate(values.startDate, format)}
                label={t('confirmation.customRange.startDate')}
                onChange={(date) => onFormChange('startDate')(date)}
                format={format}
                labels={datePickerLocale(t, locale)}
                error={formErrors.startDate}
                disabledDate={(current) => current && compareDates(current, new Date())}
                dropdownClassName="CustomRangeForm__date-picker"
                inputReadOnly={isTabletSize}
            />
            <DatePicker
                {...register('endDate')}
                name="endDate"
                value={values.endDate && formatDate(values.endDate, format)}
                label={t('confirmation.customRange.endDate')}
                onChange={(date) => onFormChange('endDate')(date)}
                format={format}
                labels={datePickerLocale(t, locale)}
                error={formErrors.endDate}
                disabledDate={(current) => current && compareDates(current, new Date())}
                dropdownClassName="CustomRangeForm__date-picker"
                inputReadOnly={isTabletSize}
            />
        </div>
    ), [t, register, values, format, locale, formErrors, onFormChange]);

    // Callbacks
    const onOk = handleSubmit(() => {
        setVisible(false);
        onChange(values);
    });
    const onCancel = () => {
        setFilter(previousFilter.current);
        setVisible(false);
    };
    const onSelectChange = useCallback((selectOption) => {
        previousFilter.current = filter;
        setFilter(selectOption);
        if (selectOption === CUSTOM_RANGE) {
            setVisible(true);
        } else {
            onChange(selectOption);
        }
    }, [filter, onChange]);

    return (
        <>
            <Select
                onChange={onSelectChange}
                options={optionsWithCustom}
                defaultValue={value}
                width={width}
                value={filter}
            />
            {visible && (
                <Modal
                    className="custom-range-form"
                    title={t('confirmation.customRange.title')}
                    okText={t('confirmation.confirm')}
                    cancelText={t('confirmation.cancel')}
                    visible={visible}
                    onOk={onOk}
                    onCancel={onCancel}
                >
                    {modalBody}
                </Modal>
            )}
        </>
    );
};

CustomRangeSelect.propTypes = {
    width: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string, PropTypes.shape({
            startDate: PropTypes.string,
            endDate: PropTypes.string,
        }),
    ]),
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    onChange: PropTypes.func,
};

CustomRangeSelect.defaultProps = {
    width: null,
    value: null,
    options: [],
    onChange: () => {},
};

export default CustomRangeSelect;
