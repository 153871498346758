import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import './ProductSummaryItem.css';
import {useFormatting} from '../../../../../../../../../../locale';
import {DEFAULT_APP_CURRENCY} from '../../../../../../../../../../constants/constants';
import {formatDateWithoutLocalConversion} from '../../../../../../../../../../utils/datetime';

type WithdrawalType = {
    amount: number;
    frequency: number;
    start: string;
    iban: string;
    holder: string | Array<string>;
    bank: string;
    plannedEndDate: string;
}

type ProductSummaryItemProps = {
    oneTimeInvestment: number;
    periodicPayment: number;
    frequency: number;
    transfer: number;
    category: string;
    strategy: string;
    pensionSchema: string;
    isPk: boolean;
    isZic: boolean;
    is3a: boolean;
    isFz: boolean;
    isFullTransfer: boolean;
    // eslint-disable-next-line react/require-default-props
    withdrawal?: Partial<WithdrawalType>
}

const ProductSummaryItem: FC<ProductSummaryItemProps> = ({
    oneTimeInvestment,
    periodicPayment,
    strategy,
    category,
    transfer,
    frequency,
    pensionSchema,
    isPk,
    isZic,
    is3a,
    isFz,
    isFullTransfer,
    withdrawal,
}) => {
    const {t} = useTranslation();
    const {
        getFormattedCurrency,
    } = useFormatting();

    return (
        <section className="product-summary-item">
            <p className="title">{strategy}</p>
            {!isPk && (
                <div className="data">
                    {is3a && (
                        <>
                            <div className="data-row">
                                <p>{t('portfolio.createNewPortfolio.overview.yearlyAmount')}</p>
                                <p>{getFormattedCurrency(transfer, {currency: DEFAULT_APP_CURRENCY})}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('clients.portfolioNav.transfer')}</p>
                                <p>{getFormattedCurrency(oneTimeInvestment, {currency: DEFAULT_APP_CURRENCY})}</p>
                            </div>
                        </>
                    )}
                    {isFz && (
                        <>
                            <div className="data-row">
                                <p>{t('3b.withdrawalPlan.transferAmount')}</p>
                                <p>{getFormattedCurrency(oneTimeInvestment, {currency: DEFAULT_APP_CURRENCY})}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('productSummary.transferType')}</p>
                                <p>{t(isFullTransfer ? 'portfolio.createNewPortfolio.configuration.options.fullTransfer' : 'portfolio.createNewPortfolio.configuration.options.partialTransfer')}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('portfolio.createNewPortfolio.previousPensionScheme')}</p>
                                <p>{pensionSchema}</p>
                            </div>
                        </>
                    )}
                    {!is3a && !isFz && (
                        <div className="data-row">
                            <p>{t('portfolio.createNewPortfolio.oneTimeInvestment')}</p>
                            <p>{getFormattedCurrency(oneTimeInvestment, {currency: DEFAULT_APP_CURRENCY})}</p>
                        </div>
                    )}
                    {!isZic && !is3a && !isFz && (
                        <>
                            <div className="data-row">
                                <p>{t('portfolio.createNewPortfolio.periodicPayment')}</p>
                                <p>{periodicPayment ? getFormattedCurrency(periodicPayment, {currency: DEFAULT_APP_CURRENCY}) : '-'}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('productSummary.frequency')}</p>
                                <p>{periodicPayment ? frequency : '-'}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('clientDashboard.documents.category')}</p>
                                <p>{category ?? '-'}</p>
                            </div>
                        </>
                    )}
                    {withdrawal && (
                        <>
                            <h3>{t('portfolio.createNewPortfolio.withdrawalPlan')}</h3>
                            <div className="data-row">
                                <p>{t('clientDashboard.portfolio.columns.amount')}</p>
                                <p>{getFormattedCurrency(withdrawal.amount, {currency: DEFAULT_APP_CURRENCY})}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('productSummary.frequency')}</p>
                                <p>{withdrawal.frequency}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('surfly.tabStart')}</p>
                                <p>{formatDateWithoutLocalConversion(withdrawal.start, 'MM.YYYY')}</p>
                            </div>
                            {withdrawal.plannedEndDate && (
                                <div className="data-row">
                                    <p>
                                        {t('3b.withdrawalPlan.plannedEndDate')
                                            .replace('*', '')}
                                    </p>
                                    <p>{formatDateWithoutLocalConversion(withdrawal.plannedEndDate, 'MM.YYYY')}</p>
                                </div>
                            )}
                            <div className="data-row">
                                <p>IBAN</p>
                                <p>{withdrawal.iban}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('3b.withdrawalPlan.accountHolder')}</p>
                                <p>{withdrawal.holder}</p>
                            </div>
                            <div className="data-row">
                                <p>{t('onBoarding.bank')}</p>
                                <p>{withdrawal.bank}</p>
                            </div>
                        </>
                    )}
                </div>
            )}
        </section>
    );
};

export default ProductSummaryItem;
