import {useMemo} from 'react';
import usePersonalDetails from './hooks/usePersonalDetails';
import {useCreatePortfolioSelectors} from '../CreatePortfolio';

export const useMultiplePersonalDetails = () => {
    const {
        currentClientPersonalDetailsDataSelector,
        jointClientPersonalDetailsDataSelector,
        isJointAccountSelector,
        contactGroupIdSelector,
    } = useCreatePortfolioSelectors();

    const clientPersonalDetails = usePersonalDetails({
        personalDetails: currentClientPersonalDetailsDataSelector,
    });
    const jointPersonalDetails = usePersonalDetails({
        personalDetails: jointClientPersonalDetailsDataSelector,
    });

    return {
        client: clientPersonalDetails,
        jointClient: jointPersonalDetails,
        isJA: isJointAccountSelector,
        contactGroupId: contactGroupIdSelector,
        isPageLoading: useMemo(() => (isJointAccountSelector
            ? clientPersonalDetails?.isPageLoading || jointPersonalDetails?.isPageLoading : clientPersonalDetails?.isPageLoading
        ), [clientPersonalDetails?.isPageLoading, jointPersonalDetails?.isPageLoading, isJointAccountSelector]),
    };
};
