import { useMemo } from 'react';
import { allocationColors } from 'constants/constants';
import { useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';
import { useGetSecurityAllocations } from 'hooks/rest/useGetSecurityAllocations';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { useCalculateStrategyRiskBandwidth } from 'hooks/rest/useCalculateStrategyRiskBandwidth';
import { useGetPortfolioVolatility } from 'hooks/rest/useGetPortfolioVolatility';

export const useStrategyRiskComparison = () => {
    const {setStep: setCNPStep} = usePortfolioProcessNavigation({ preventNavigationLock: true});

    const {advisoryIdSelector, currentSelectedStrategySelector} = useCreatePortfolioSelectors();
    const {securityAllocations, securityAllocationsAfterOptimization} = useGetSecurityAllocations({advisoryId: advisoryIdSelector, afterOptimization: true});

    const securityAllocationsData = useMemo(() => (securityAllocations?.data ? Object?.keys(securityAllocations?.data)?.map(key => ({
        name: key,
        percent: (securityAllocations?.data?.[key]?.percentage * 100)?.toFixed(2),
        color: allocationColors?.[key],
        value: securityAllocations?.data?.[key]?.value,
        className: key,
        description: '',
    })) : []), [securityAllocations?.data]);

    const securityAllocationsDataAfterOptimization = useMemo(() => (securityAllocationsAfterOptimization?.data ? Object?.keys(securityAllocationsAfterOptimization?.data)?.map(key => ({
        name: key,
        percent: (securityAllocationsAfterOptimization?.data?.[key]?.percentage * 100)?.toFixed(2),
        color: allocationColors?.[key],
        value: securityAllocationsAfterOptimization?.data?.[key]?.value,
        className: key,
        description: '',
    })) : []), [securityAllocationsAfterOptimization?.data]);

    const {data} = useCalculateStrategyRiskBandwidth({advisoryId: advisoryIdSelector, isEnabled: !!advisoryIdSelector});
    const {data: portfolioVolatilities} = useGetPortfolioVolatility({isEnabled: !!advisoryIdSelector});

    const selectedStrategyId = currentSelectedStrategySelector?.Id;
    const portfolioVolatility = portfolioVolatilities?.portfolioVolatililties?.find(v => v?.riskCategoryId === selectedStrategyId);

    return {
        securityAllocations: {
            beforeOptimization: securityAllocationsData,
            afterOptimization: securityAllocationsDataAfterOptimization,
        },
        riskBandwidth: {
            portfolioVolatility: {
                from: Math.round((portfolioVolatility?.minRiskValue || 0) * 100),
                to: Math.round((portfolioVolatility?.maxRiskValue || 0) * 100),
            },
            calculatedRiskValue: Math.round(((data?.metadata?.calculatedRiskValue || 0) * 100)) ?? 0,
        },
        setCNPStep,
    };
};
