import {
    Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import { INVESTMENT_HORIZON_OPTIONS, recommendedCategoryMap } from 'pages/ClientOverview/constants';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { Modal } from 'ui-library';
import { useHistorialPerformanceData } from 'hooks/rest/useHistorialPerformanceData';
import { useRiskReturnChartData } from 'hooks/rest/useRiskReturnChartData';
import { useGetPortfolioVolatility } from 'hooks/rest/useGetPortfolioVolatility';
import { convertToMilliseconds } from 'utils';
import { PortfolioVolatilityResponse } from 'core/types/api/Api';
import { useCreatePortfolioActions, useCreatePortfolioSelectors } from '../CreatePortfolio';
import { getRiskScrores } from './static';

type RiskScore = {
    id: number;
    label: string;
}

type RiskData = {
    data: Array<number[]>,
    name: string
}

type UseStrategy = {
    riskScores: Array<RiskScore>;
    investmentHorizon: {Id: string; Text: string}[];
    selectedHorizon: number | null;
    investmentHorizonError: string | null | undefined;
    riskAnalysisData: RiskData[];
    historicalPerformanceData: RiskData[];
    riskProfile: number | undefined;
    recommendedStrategy: {
        Id: number;
        Name: string;
        Min: number;
        Max: number;
        Risk: number;
    } | undefined;
    selectedRiskProfile: {
        id: number;
        label: string;
    } | undefined;
    chartModal: string | null;
    portfolioVolatilities: PortfolioVolatilityResponse[] | null | undefined,
    handleInvestmentHorizon: ({value}: {value: string}) => void;
    onRiskScoreChange: (value: number) => void;
    setChartModal: Dispatch<SetStateAction<string | null>>;
    handleOpenChartInFullScreen: (param: string) => () => void;
    riskReturnChartLoading: boolean;
    historicalPerformanceChartLoading: boolean;
}

const useStrategy = (): UseStrategy => {
    const {t} = useTranslation();
    const riskScores = getRiskScrores(t);
    const {currentRecommendedStrategySelector, advisoryIdSelector, advisoryDataSelector} = useCreatePortfolioSelectors();
    const {saveAdvisoryDataAction} = useCreatePortfolioActions();
    const {data: portfolioVolatilities} = useGetPortfolioVolatility({isEnabled: !!advisoryIdSelector});

    const advisoryStrategy = advisoryDataSelector?.metadata?.advisoryStrategy || null;
    const recommendedRiskCategory = currentRecommendedStrategySelector?.RiskCategory?.Id;
    const recommendedStrategyScore = currentRecommendedStrategySelector?.Score;

    const {data: riskReturnChartData, statusCode: riskReturnChartStatus} = useRiskReturnChartData({isEnabled: true});
    const {data: historicalPerformance, statusCode} = useHistorialPerformanceData({isEnabled: true});

    const [recommendedStrategy, setRecomendedStrategy] = useState<any>(null);
    const [selectedHorizon, setSelectedHorizon] = useState<number | null>(advisoryStrategy?.investmentHorizon || null);
    const [investmentHorizonError, setInvestmentHorizonError] = useState<string | null>();
    const [continueButtonLoading, setContinueButtonLoading] = useState<boolean>(false);
    const [riskProfile, setRiskProfile] = useState(advisoryStrategy?.chosenRiskCategoryId ?? recommendedRiskCategory);
    const [chartModal, setChartModal] = useState<string | null>(null);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const investmentHorizon = useMemo(() => INVESTMENT_HORIZON_OPTIONS(t, false), []);
    const selectedRiskProfile = useMemo(() => riskScores.find(f => f.id === riskProfile), [riskProfile, riskScores]);

    const getColor = useCallback((id) => {
        if (recommendedStrategy?.Id === id) {
            return 'recommended';
        } if (riskProfile === id) {
            return 'selected';
        }

        return '';
    }, [recommendedStrategy, riskProfile]);

    const riskAnalysisData = useMemo(() => riskReturnChartData?.map((riskReturn) => {
        const data = {
            x: riskReturn.riskValue * 100,
            y: riskReturn.returnValue * 100,
            z: 2,
            name: riskReturn.riskCategoryId,
            color: getColor(riskReturn?.riskCategoryId),
        };

        return data;
    }), [riskReturnChartData, recommendedStrategy, riskProfile]) as RiskData[];

    const historicalPerformanceData = useMemo(() => historicalPerformance?.reduce((acc, performance) => {
        const data = performance?.values?.map(value => [convertToMilliseconds(value.date), value.performance]);

        acc.push({ name: riskScores?.find(score => score?.id === performance.investmentMetricType)?.label, data });

        return acc;
    }, []), [historicalPerformance]);

    const getRecommendedStrategy = useCallback((horizon) => {
        const score = +(recommendedStrategyScore as number);

        return recommendedCategoryMap[horizon](score);
    }, [recommendedStrategyScore]);

    useEffect(() => {
        if (selectedHorizon !== null) {
            const selectedCategory = getRecommendedStrategy(selectedHorizon);

            setRecomendedStrategy(selectedCategory);
        }
    }, [selectedHorizon]);

    const onRiskScoreChange = useCallback((value: number) => {
        if (value !== recommendedStrategy?.Id && value !== riskProfile) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Modal.confirm({
                title: t('portfolio.disclaimer'),
                content: t(`portfolio.createNewPortfolio.strategy.disclaimerText.${value > (recommendedStrategy?.Id as number) ? 'high' : 'low'}`),
                okText: t('clientDashboard.confirm'),
                onOk: async () => {
                    setRiskProfile(value);
                    setIsDirty(true);
                },
                onCancel: () => setRiskProfile(recommendedStrategy?.Id),
                cancelText: false,
                triggerCancelOnClose: true,
            });
        }
    }, [recommendedStrategy?.Id, riskProfile]);

    const saveData = async () => {
        const payload = {
            investmentHorizon: selectedHorizon,
            chosenRiskCategoryId: riskProfile,
        };

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'strategy',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                unlockNavigationAction();
                stepComplete();
            }
        }
    };

    const onContinue = useCallback(() => {
        if (!selectedHorizon) {
            setInvestmentHorizonError(t('validation.mandatoryField'));
        } else if (isDirty) {
            setContinueButtonLoading(true);
            saveData();
        } else {
            setContinueButtonLoading(false);
            unlockNavigationAction();
            stepComplete();
        }
    }, [selectedHorizon, riskProfile]);

    const {stepComplete, unlockNavigationAction} = usePortfolioProcessNavigation({
        enableAutoStepComplete: false,
        saveOnContinue: onContinue,
        continueInProgress: continueButtonLoading,
    });

    const handleOpenChartInFullScreen = (param: string) => () => {
        setChartModal(param);
    };

    const handleInvestmentHorizon = useCallback(({value}) => {
        setSelectedHorizon(+value);
        setInvestmentHorizonError(null);
        setIsDirty(true);
        setRiskProfile(getRecommendedStrategy(+value)?.Id);
    }, [recommendedStrategy?.Id]);

    return {
        riskScores,
        riskAnalysisData,
        historicalPerformanceData,
        investmentHorizon,
        selectedHorizon,
        investmentHorizonError,
        riskProfile,
        recommendedStrategy,
        selectedRiskProfile,
        chartModal,
        portfolioVolatilities: portfolioVolatilities?.portfolioVolatililties,
        handleInvestmentHorizon,
        onRiskScoreChange,
        setChartModal,
        handleOpenChartInFullScreen,
        historicalPerformanceChartLoading: statusCode === 'pending',
        riskReturnChartLoading: riskReturnChartStatus === 'pending',
    };
};

export default useStrategy;
