import React, { FC } from 'react';
import { Button } from 'ui-library';
import Icon, { ICON_TYPE_PLUS_SLIM } from 'ui-library/components/Icon';
import { AddButtonProps } from './AddButton.types';
import './AddButton.css';

const AddButton:FC<AddButtonProps> = ({
    label, onClick = () => {}, className = '', disabled = false,
}) => (
    <Button onClick={onClick} className={`add-btn ${className}`} disabled={disabled}>
        <Icon type={ICON_TYPE_PLUS_SLIM} size={20} color={undefined} ariaLabel={undefined} component={undefined} className={undefined} />
        {label}
    </Button>
);

export default AddButton;
