import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector as useReduxSelector} from 'react-redux';
import {SELECETED_PRODUCT_TYPE} from 'pages/ClientOverview/constants';
import useCommonAllocationDataProvider
    from 'pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';
import {useActive3bApplication} from 'hooks/rest/useActive3bApplication';
import {isNil} from 'lodash/fp';
import {useCreatePortfolio} from '../../context';
import {Title} from '../../../../../../../../ui-library';
import {useProductsList} from '../../../../../../../../hooks/useProducts';
import {getClientId} from '../../../../../../../../utils';
import {ProductOfferSelection} from '../../../../../../../../components/product-offer-selection';
import {PRODUCTS, THREEBTYPES} from '../../../../../../../../constants/constants';
import Preloader from '../../../../../../../../components/Preloader';
import handlerRequestCanceling from '../../../../../../../../utils/handlerRequestCanceling';
import HandlerError from '../../../../../../../../errors/HandlerError';
import {memberIdSelector} from '../../../../../../../../redux-store/auth/authSelectors';
import ServiceManager from '../../../../../../../../services/ServiceManager';
import {NEW_PORTFOLIO} from '../../../../constants';

export const ProductSelection = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { dfsClientId } = useParams();
    const contactId = getClientId(dfsClientId);
    const memberId = useReduxSelector(memberIdSelector);
    const [selectedProductId, setSelectedProductId] = useState();
    const [isLoading, setLoading] = useState(true);
    const {
        newPortfolio, saveNewPortfolio, layout: { setData: setLayoutData, setPageErrors }, setSelectedInvestmentApp,
    } = useCreatePortfolio();
    const baseUrl = useMemo(() => `/client/${dfsClientId}/portfolios/new-portfolio`, [dfsClientId]);

    const containerId = useMemo(() => {
        const cachedInvestmentApps = JSON.parse(global.sessionStorage.getItem(NEW_PORTFOLIO) || 'null');

        if (cachedInvestmentApps) {
            const selectedApp: any = Object.values(cachedInvestmentApps).find((f: any) => f.applicationData?.investmentDescription?.containerId);

            return selectedApp?.applicationData?.investmentDescription?.containerId;
        }

        return null;
    }, []);

    const {data: isActive3bApplicationAvailable, isLoading: active3bApplicationAvailableLoading, error: active3bApplicationAvailableError} = useActive3bApplication({contactGroupId: newPortfolio?.connectionGroupId});

    const {setSetupWithdrawalPlan} = useCommonAllocationDataProvider();

    const {
        getProducts, products, isLoadingProducts, errorProducts,
    } = useProductsList(contactId);

    const bbbProducts = useMemo(() => products.filter(f => f.settings.productKey === PRODUCTS.bbb)?.map(product => {
        const productToSave = { ...product };

        if (!(newPortfolio?.isAdvisoryCompleted
                 ?? newPortfolio?.applicationData?.investmentDescription?.advisoryCompleted)) {
            if (productToSave.externalId !== THREEBTYPES.pk && productToSave.externalId !== THREEBTYPES.zifd && productToSave.externalId !== THREEBTYPES.zic) {
                productToSave.isEnabled = false;
            }
        }

        if (!isActive3bApplicationAvailable && productToSave.externalId === THREEBTYPES.pk) {
            productToSave.isEnabled = false;
        }

        return productToSave;
    }), [products, isActive3bApplicationAvailable, newPortfolio]);

    useEffect(() => {
        (async () => getProducts())();
    }, [getProducts]);

    useEffect(() => {
        setSelectedInvestmentApp(undefined);
    }, []);

    const saveAdvisoryProcessCompleted = useCallback(async (applicationDetails) => {
        try {
            await ServiceManager.customInvestmentService('saveAdvisoryDocumentCompletedFlag', [{
                data: {advisoryDocumentId: newPortfolio.advisoryDocument?.advisoryDocumentId},
                investmentApplicationId: applicationDetails?.investmentApplicationId,
            }]);
        } catch (err) {
            console.log(err);
        }
    }, [newPortfolio]);

    useEffect(() => {
        setSetupWithdrawalPlan(false);
        const selectedProduct = products?.find(({id}) => (selectedProductId === id));

        global.sessionStorage.setItem(SELECETED_PRODUCT_TYPE, selectedProduct?.externalId || '');
    }, [selectedProductId]);

    const onContinue = useCallback(async () => {
        try {
            const selectedProduct = products?.find(({id}) => (selectedProductId === id));
            const payload = {
                productId: selectedProductId,
                contactId,
                memberId,
                productExternalId: selectedProduct?.externalId,
                contactGroupId: newPortfolio.connectionGroupId,
                isJointAccount: newPortfolio.isJointAccount,
                containerId,
            };

            const additionalData = {
                product: selectedProduct,
            };

            const result = await saveNewPortfolio({
                data: payload, method: 'saveProductDetails', additionalData, callback: saveAdvisoryProcessCompleted,
            });

            if (!result) return;

            navigate(`${baseUrl}/personal-details`);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: setPageErrors,
                    setLoading,
                }),
            )(err);
        }
    }, [products, contactId, memberId, selectedProductId, newPortfolio, saveAdvisoryProcessCompleted, containerId]);

    const onBack = useCallback(() => {
        navigate(`${baseUrl}/advisory-document-init`);
    }, [baseUrl]);

    useEffect(() => {
        setLayoutData({
            stepNavBarActive: 3,
            onBack,
            onContinue,
            disabled: isLoadingProducts || !bbbProducts.length || !selectedProductId,
        });
    }, [onBack, onContinue, selectedProductId, bbbProducts, isLoadingProducts, active3bApplicationAvailableLoading]);
    const apisError = useMemo(() => (
        [errorProducts, active3bApplicationAvailableError].find(e => !isNil(e))
    ), [errorProducts, active3bApplicationAvailableError]);

    return (
        <Preloader
            isLoading={isLoadingProducts || active3bApplicationAvailableLoading}
            error={apisError}
        >
            <div>
                <Title type={1}>
                    {t('productSelection.step.title')}
                </Title>
                <div className="fi-products-selection">
                    {bbbProducts.map(item => (
                        <ProductOfferSelection
                            disabled={!item.isEnabled}
                            isSelected={selectedProductId === item.id}
                            key={item.id}
                            productOffer={item}
                            onSelectionChange={setSelectedProductId}
                        />
                    ))}
                </div>
            </div>
        </Preloader>
    );
};
