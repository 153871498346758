import React, {useMemo} from 'react';
import {FooterMini} from 'ui-library';
import {useTranslation} from 'react-i18next';
import LanguageSwitcher from 'translations/component/LanguageSwitcher';

import './Footer.css';

const Footer = () => {
    const {t} = useTranslation();

    return (
        <FooterMini
            title={t('footer.copyright', {date: new Date().getFullYear()})}
        >
            <div className={"Footer__Inner"}>
                <LanguageSwitcher/>
                <div>
                    {t('footer.copyright', {date: new Date().getFullYear()})}
                </div>
            </div>
        </FooterMini>
    );
};

export default Footer;
