/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import {Button, Modal} from 'ui-library';

import { getClientId } from 'utils';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';
import './Connections.css';
import Card from '../Card/Card';
import Icon, {ICON_TYPE_NEW_DOCUMENT, ICON_TYPE_PLUS, ICON_TYPE_REMOVE} from '../../ui-library/components/Icon';
import { useConnections } from './components';
// eslint-disable-next-line import/no-cycle
import NewConnectionModal
    from '../../pages/ClientOverview/pages/ClientConnections/components/NewConnectionModal/NewConnectionModal';
import {ContactGroup, ContactGroupEnum} from './types';

export default function Connections() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const { dfsClientId } = useParams();
    const clientId = getClientId(dfsClientId);

    const {
        data, rawData, isLoading, error, onAddConnection,
        deleteContactGroup, setContactGroupDetails,
        getProxyAdditionStatus, allowProxyAddition,
    } = useConnections();

    useEffect(() => {
        setContactGroupDetails(null);
    }, []);

    const deleteGroup = useCallback((contactGroupdId: number) => {
        // @ts-ignore
        Modal.confirm({
            triggerCancelOnClose: true,
            title: t('connections.deleteGroupModal.title'),
            content: null,
            okText: t('connections.deleteGroupModal.ok'),
            width: 400,
            className: 'employment-continuation-confirmation-modal',
            cancelText: t('connections.deleteGroupModal.cancel'),
            onOk: async () => {
                await deleteContactGroup(contactGroupdId);
            },

        });
    }, [deleteContactGroup]);

    const hasJAOpened = useMemo(() => data.some((c: ContactGroup) => c.groupName === ContactGroupEnum.ja), [data]);

    const owner3bGroupId: string | undefined = useMemo(() => {
        const ownerGroup = clientId && rawData.find((c: ContactGroup) => c.groupName === ContactGroupEnum.threeB && c.owners[clientId]);

        return ownerGroup ? ownerGroup.groupId?.toString() : undefined;
    }, [rawData, clientId]);

    useEffect(() => {
        (async () => getProxyAdditionStatus(owner3bGroupId))();
    }, [getProxyAdditionStatus, owner3bGroupId]);

    return (
        <>
            <div className="table_header">
                <span>{t('connections.title')}</span>
                <div>
                    {/* <Button */}
                    {/*    type="secondary" */}
                    {/*    size="small" */}
                    {/*    onClick={onGeneratePOAform} */}
                    {/*    className="header_button" */}
                    {/* > */}
                    {/*    /!* @ts-ignore *!/ */}
                    {/*    <Icon type={ICON_TYPE_NEW_DOCUMENT} size={24} /> */}
                    {/*    <span className="button_label">{t('connections.generatePOAform')}</span> */}
                    {/* </Button> */}
                    <Button
                        type="secondary"
                        size="small"
                        onClick={onAddConnection}
                        className="plus_button"
                        disabled={!allowProxyAddition && !!owner3bGroupId && hasJAOpened}
                    >
                        {/* @ts-ignore */}
                        <Icon type={ICON_TYPE_PLUS} size={24}/>
                        <span className="button_label">{t('connections.addConnection')}</span>
                    </Button>
                </div>
            </div>
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={data} text={t('connections.noHistory')}>
                    <div className="contact_groups_container ">
                        {data.map(i => (
                            <Card key={i.groupId} className="group_item" onClick={() => { navigate(`${pathname}/${i.groupId}/details`); }}>
                                <div>
                                    <div style={{marginBottom: '10px'}}>{i.externalId}</div>
                                    {Object.values(i.owners).map(o => <div key={o}>{o}</div>)}
                                </div>
                                <div className="group_name">{t(`connections.groupName.${i.groupName.toLowerCase()}`)}</div>
                                {(i.groupName !== ContactGroupEnum.threeB && i.isDeletable) && (
                                    <button type="button" className="delete-icon" onClick={async (e) => { e.stopPropagation(); deleteGroup(i.groupId); }}>
                                        {/* @ts-ignore */}
                                        <Icon size={24} type={ICON_TYPE_REMOVE} />
                                    </button>
                                )}
                            </Card>
                        ))}
                    </div>
                </EmptyContent>
            </Preloader>
            <NewConnectionModal hasJAOpened={hasJAOpened} allowProxyAddition={allowProxyAddition || !owner3bGroupId} />
        </>
    );
}
