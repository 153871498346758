import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Title } from 'ui-library';
import PieChartWithLegend from 'components/PieChartWIthLegend/PieChartWithLegend';
import { RiskComparisonCompProps } from './RiskComparisonComp.types';
import './RiskComparisonComp.css';

const RiskComparisonComp:FC<RiskComparisonCompProps> = ({title, subTitle, chartData}) => {
    const {t} = useTranslation();

    return (
        <section className="strategy-risk-comparison-comp">
            <Title type={2}>
                {title}
            </Title>
            <div className="strategy-risk-comparison-comp--subtitle">{subTitle}</div>
            <Title type={4} className="strategy-risk-comparison-comp--description">
                {t('RiskBandwidthComparison.Description.BeforeOptimization')}
            </Title>
            <PieChartWithLegend chartData={chartData} />
        </section>
    );
};

export default React.memo(RiskComparisonComp);
