import {PRODUCT_VALUES_FOR_3B} from 'constants/constants';
import {AdvisoryInvestmentsResponse, AdvisoryPortfolioDataResponse} from 'core/types/api/Api';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {useClientProfile} from 'prodivers/clientProfile';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';

export type InvestmentDepot = {
    portfolioId: number;
    value: number;
    shares?: number;
    modelPortfolioName: string;
    name: string;
}

export const useInvestDepots = () => {
    const {advisoryIdSelector, isJointAccountSelector, advisoryDataSelector} = useCreatePortfolioSelectors();
    const {saveAdvisoryDataAction} = useCreatePortfolioActions();
    const {sectionedPortfolios, isOverviewLoading} = useClientProfile();

    const previousExcludedInvestmentRecord = useMemo(() => advisoryDataSelector?.metadata?.investments?.excludedPortfolios?.map(portfolio => portfolio?.portfolioId), [advisoryDataSelector?.metadata?.investments?.excludedPortfolios]);

    const getPortfolios = useCallback(():AdvisoryPortfolioDataResponse[] => sectionedPortfolios?.jointRegularPortfolios?.filter(
        portfolio => portfolio?.Status?.value === 'active'
        && [...PRODUCT_VALUES_FOR_3B]?.includes(portfolio?.ProductId)
        && (isJointAccountSelector ? portfolio?.AccountHolder?.length > 1 : portfolio?.AccountHolder?.length < 2),
    )?.map(p => ({
        portfolioId: p?.Id,
        name: p?.Name?.label,
        modelPortfolioName: p?.Profile,
        value: +p?.Value?.split(' ')?.[1]?.replace(/[^0-9.]/g, '') || 0,
        checked: !previousExcludedInvestmentRecord?.includes(p?.Id),
    })), [sectionedPortfolios, isJointAccountSelector, previousExcludedInvestmentRecord]);

    const [touched, setIsTouched] = useState<boolean>(false);
    const [continueButtonLoading, setContinueButtonLoading] = useState<boolean>(false);
    const portfolios: AdvisoryPortfolioDataResponse[] = getPortfolios() || [];

    const initialInvestments = {
        includedPortfolios: portfolios?.filter(port => !previousExcludedInvestmentRecord?.includes(port?.portfolioId))?.map(({portfolioId, value}) => ({portfolioId, value })),
        excludedPortfolios: portfolios?.filter(port => previousExcludedInvestmentRecord?.includes(port?.portfolioId))?.map(({portfolioId, value}) => ({portfolioId, value })) || [],
    };

    const [investments, setInvestments] = useState<AdvisoryInvestmentsResponse>(initialInvestments);

    useEffect(() => {
        setInvestments(initialInvestments);
    }, [previousExcludedInvestmentRecord]);

    const handleProfileSelection = useCallback(({checked, item}) => {
        setInvestments((prevState: AdvisoryInvestmentsResponse) => {
            const {includedPortfolios, excludedPortfolios} = prevState || {};

            if (checked) {
                return {
                    includedPortfolios: includedPortfolios?.concat(item),
                    excludedPortfolios: excludedPortfolios?.filter(pFolio => pFolio?.portfolioId !== item?.portfolioId),
                };
            }

            return {
                includedPortfolios: includedPortfolios?.filter(pFolio => pFolio?.portfolioId !== item?.portfolioId),
                excludedPortfolios: excludedPortfolios?.concat(item),

            };
        });
    }, []);

    const saveData = async (data: AdvisoryInvestmentsResponse) => {
        const payload = {...data};

        if (advisoryIdSelector) {
            const result:any = await saveAdvisoryDataAction({
                key: 'investments',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                unlockNavigationAction();
                stepComplete();
            }
        }
    };

    const onSubmit = useCallback(async () => {
        if (touched) {
            setContinueButtonLoading(true);

            await saveData(investments);
        } else {
            unlockNavigationAction();
            stepComplete();
        }
    }, [investments, touched]);

    const {stepComplete, unlockNavigationAction} = usePortfolioProcessNavigation({
        saveOnContinue: onSubmit,
        continueInProgress: continueButtonLoading,
        enableAutoStepComplete: false,
    });

    return {
        data: portfolios,
        isLoading: isOverviewLoading,
        isJoint: isJointAccountSelector,
        handleProfileSelection,
        setIsTouched,
    };
};
