import {DOCUMENT_SIGN_STATUS, DOCUMENT_SIGN_STATUS_TEXT} from '../../pages/ClientOverview/pages/Dashboard/utils';

export const collapseTableAdapter = (data, t, contactId, getDocuments, applicationStatus, updateData) => {
    const tableData = data.map(
        ({
            id,
            investmentApplicationId,
            documentName,
            signStatus,
            date,
            isSent,
        }) => (
            {
                key: id,
                name: documentName,
                status: t(DOCUMENT_SIGN_STATUS_TEXT[signStatus]),
                saved: date,
                action: {
                    id, investmentApplicationId, contactId, getDocuments, isSent, signStatus, applicationStatus, hasUnsignedDocuments: data.some(s => s.signStatus !== DOCUMENT_SIGN_STATUS.signed), updateData,
                },
            }),
    );

    return tableData;
};
