import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Title} from 'ui-library';
import {NewProductProps} from '../../../widgets/ShoppingCard/ShoppingCard.types';
import ProductRow from '../ProductRow/ProductRow';
import AddButton from 'components/common/AddButton';

const NewProducts: FC<NewProductProps> = ({
    remainingInvestmentAmount,
    handleAddProduct,
    handleDeleteRow,
    products,
    control,
    errors,
}) => {
    const {t} = useTranslation();

    return (
        <div className="products-on-cart">
            <Title type={4}>
                {/* {t('portfolio.createNewPortfolio.overview.newProductsTitle')} */}
                New Products
            </Title>
            {products?.map((product, index) => (
                <ProductRow
                    control={control}
                    errors={errors}
                    key={product?.id || index}
                    index={index}
                    product={product}
                    configured={false}
                    handleDeleteRow={handleDeleteRow}
                />
            ))}
            <AddButton
                onClick={handleAddProduct}
                label={
                    // t('portfolio.createNewPortfolio.overview.addNewProductButton.label')}
                    'Add new product'
                }
                className="mb-60"
                disabled={remainingInvestmentAmount === 0}
            />
        </div>
    );
};

export default NewProducts;
