import React from 'react';
import { PageVariant } from 'ui-library';
import {useNavigate} from 'react-router-dom';

const PageNotFoundContent = () => {
    const navigate = useNavigate();

    return (
        <div className="page-not-found">
            <PageVariant
                title="404"
                type="error"
                message="Page not found."
                actionButtonLabel="Back to Dashboard"
                onActionButtonClick={() => {
                    navigate('/');
                }}
            />
        </div>
    );
};

export default PageNotFoundContent;
