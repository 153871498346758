import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'ui-library';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';

import './ActionCellWithIconAndText.css';

// eslint-disable-next-line object-curly-newline
const ActionCellWithIconAndText = ({ icon, size = 18, action, text, infoText }) => (
    <div className="table-cell_icon">
        <div className="table-cell_action-with-icon-text">
            {(icon && infoText) && (
                <Tooltip
                    title={infoText}
                    overlayClassName="ant-tooltip-table"
                    className="table-tooltip"
                    trigger="hover"
                    placement="topRight"
                    disabled
                >
                    <ButtonWithIcon>
                        <Icon className="icon__tooltipped" size={size} type={icon} />
                    </ButtonWithIcon>
                </Tooltip>
            )}

            {(icon && !infoText) && (
                <button type="button" onClick={action} className="table-cell-action-with-icon-text_button">
                    <Icon type={icon} size={size} />
                    {text && <span className="table-cell">{text}</span>}
                </button>
            )}
        </div>
    </div>
);

ActionCellWithIconAndText.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    infoText: PropTypes.string,
    size: PropTypes.number,
    action: PropTypes.func,
};

ActionCellWithIconAndText.defaultProps = {
    icon: '',
    size: 18,
    text: undefined,
    action: () => { },
    infoText: undefined,
};

export default ActionCellWithIconAndText;
