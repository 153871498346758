import React from 'react';
import { withMainLayout } from 'hocs';
import PageNotFoundContent from './PageNotFoundContent';
import './PageNotFound.css';

const PageNotFound = () => (
    <PageNotFoundContent />
);

export default withMainLayout(PageNotFound);
