import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Title} from 'ui-library';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import {InfoErrorList} from 'components/InfoErrorList/InfoErrorList';
import {PortfolioFlowLayoutContract} from './PortfolioProcessLayout.types';
import StepperLayout from '../Stepper/Stepper';

import './PortfolioProcessLayout.css';
import {getClassNames} from '../../ui-library/utils';

interface PortfolioFlowLayoutProps {
    dataSource: () => PortfolioFlowLayoutContract;
    children: React.ReactNode;
}

export const PortfolioProcessLayout = ({
    dataSource,
    children,
}: PortfolioFlowLayoutProps) => {
    const {
        stepper,
        footerAdditionalInfo,
        onBack,
        onContinue,
        continueLabel,
        continueDisabled,
        backLabel,
        backDisabled,
        pageErrors,
        onAdditionalComponentClick,
        additionalComponentLabel,
        inProgress,
        isStepperEnabled,
        isFooterSimplified,
        contentTitle,
        contentClassName,
    } = dataSource();

    const {t, i18n: {language}} = useTranslation();
    const contentClassnames = useMemo(() => (
        getClassNames(
            contentClassName,
        )
    ), [contentClassName]);

    const ButtonsBlock = useMemo(() => (
        <ButtonsBlockRow
            additionalButton={{
                text: backLabel || t('portfolio.back'),
                onClick: onBack,
                disabled: backDisabled,
            }}
            additionalComponent={onAdditionalComponentClick
                && (
                    <Button
                        type="secondary"
                        disabled={continueDisabled}
                        onClick={onAdditionalComponentClick}
                    >
                        {additionalComponentLabel}
                    </Button>
                )}
            primaryButton={{
                text: continueLabel || t('portfolio.continue'),
                onClick: onContinue,
                disabled: continueDisabled,
                loading: inProgress,
            }}
        />
    ), [
        language,
        backLabel,
        backDisabled,
        onAdditionalComponentClick,
        continueDisabled,
        additionalComponentLabel,
        continueLabel,
        onBack,
        onContinue,
        continueDisabled,
        inProgress,
    ]);

    return (
        <div className="PortfolioProcessLayout">
            <div className="fi-body">
                {isStepperEnabled && (
                    <StepperLayout
                        config={stepper.steps}
                        currentStepNumber={stepper.currentStepNumber}
                        title={stepper.title}
                    />
                )}
                <div className="container">
                    <div className={contentClassnames}>
                        {contentTitle && (
                            <Title type={1} className="font-normal">
                                {t(contentTitle)}
                            </Title>
                        )}

                        {children}
                    </div>
                    <InfoErrorList errors={pageErrors}/>
                </div>
            </div>

            {isFooterSimplified ? (
                <div className="fi-footer">
                    <div className="container">
                        <div className="fi-footer-data">
                            <div className="fi-footer-client-details">
                                {
                                    footerAdditionalInfo.map(({label, values}, i) => (
                                        <div
                                            key={`item${i}`}
                                            className="fi-footer-client-details__block fi-footer-client-details__block--age"
                                        >
                                            <div className="fi-footer-client-details_label">{t(label)}</div>
                                            {
                                                values.map((value, j) => (
                                                    <div
                                                        key={`value${j}`}
                                                        className="fi-footer-client-details__value"
                                                    >
                                                        {value}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            {ButtonsBlock}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container">
                    {ButtonsBlock}
                </div>
            )}
        </div>
    );
};
