import React, {ReactNode, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Stepper} from '../../core/stepper';
import {stepsKeys, useCreatePortfolioFlow} from './useCreatePortfolioFlow';
import {PortfolioProcessLayoutManager} from '../../datasource/PortfolioProcessLayout';
import {useCreatePortfolioLayoutProcess} from '../../hooks/adaptors/useCreatePortfolioLayoutProcess';
import {PortfolioProcessLayout} from '../../components/PortfolioProcessLayout';
import {useClientLayout} from '../../pages/ClientOverview/hooks/useClientLayout';

export const CreatePortfolio = () => {
    useClientLayout(
        false,
        false,
    );
    const {stepsBasedOnState} = useCreatePortfolioFlow();
    const {dfsClientId} = useParams();
    const exitURL = useMemo(() => (`/client/${dfsClientId}`), [dfsClientId]);

    const Layout = useMemo(() => ({children}: { children: ReactNode }) => (
        <PortfolioProcessLayoutManager
            withProductGroupName
        >
            <PortfolioProcessLayout dataSource={useCreatePortfolioLayoutProcess}>
                {children}
            </PortfolioProcessLayout>
        </PortfolioProcessLayoutManager>
    ), []);

    return (
        <Stepper
            startStep={stepsKeys.GeneralProductSelection}
            completeURL={exitURL}
            backURL={exitURL}
            items={stepsBasedOnState}
            layout={Layout}
            withCache
        />
    );
};
