import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MAX_FILE_SIZE} from 'constants/constants';
import {bytesToSize} from '../../utils/formatting';
import {
    FILE_TYPES_ALLOWED,
    MAXALLOWEDFILES,
} from '../../pages/ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/const';
import {
    validateFile,
    validateUploadCount,
} from '../../pages/ClientOnboardingFlow/pages/NewsPlan/UploadDocumentsPage/validation/validation';

import './UploadDocuments.css';

const UploadDocuments = ({
    onError,
    onSuccess,
    className = '',
    numberOfFiles = MAXALLOWEDFILES,
    uploadLabel = '',
    maxMbFileSize = MAX_FILE_SIZE,
    sizeError = '',
    fileFormatError = '',
    fileTypesAllowed = FILE_TYPES_ALLOWED,
    ...rest
}) => {
    const {t} = useTranslation();
    const [files, setFiles] = useState([]);

    const removeFile = (e, item) => {
        const filteredArray = Array.from(files)
            .filter(file => file !== item);

        setFiles(filteredArray);

        onSuccess(filteredArray);
    };

    const onFileChange = (e) => {
        const fileData = e.target.files;

        onError(null);

        if ((files.length + e.target.files.length) > numberOfFiles) {
            onError(validateUploadCount(true, numberOfFiles));

            return false;
        }

        const hasErrors = validateFile(e.target.files, {
            maxMbFileSize,
            sizeError,
            fileFormatError,
            fileTypesAllowed,
        });

        if (!hasErrors) {
            setFiles([...files, ...fileData]);
            onSuccess([...files, ...fileData]);
        } else {
            onError(hasErrors);
        }

        return null;
    };

    const isImageFormat = (file) => file.type.match(/(x-png|png|jpg|jpeg)/g);

    function Thumbnail({url}) {
        return (
            <img src={url} alt=""/>
        );
    }

    return (
        <div className={`UploadWidget ${className}`}>
            {files.length === numberOfFiles ? null
                : (
                    <div className="UploadWidget__Item">
                        <div className="UploadWidget__Item__Icon">
                            <span className="UploadIcon UploadIcon--Select"/>
                        </div>
                        <label htmlFor="FileSelect" className="Upload__Label Upload__Label--FileSelect">
                            <input
                                {...rest}
                                onClick={(e) => {
                                    e.target.value = null;
                                }}
                                onChange={(e) => {
                                    // clearFilesState();
                                    onFileChange(e);
                                }}
                                type="file"
                                id="FileSelect"
                                multiple
                                accept={fileTypesAllowed}
                                className="Upload__FileSelectInput"
                            />
                            {t('onboardingFlow.documetsPage.uploadButton')}
                            {uploadLabel && <div className="Upload__AdditionalLabel">{uploadLabel}</div>}
                        </label>
                    </div>
                )}
            {files.length > 0
                && (
                    <div className="UploadedFilesList">
                        <ul>
                            {[...files].map((file, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <li key={`f-${file}-${index}`} className="UploadedFilesList__Item">

                                    {isImageFormat(file)
                                        && (
                                            <div className="UploadedFilesList__Item__Preview">
                                                <Thumbnail url={URL.createObjectURL(file)}/>
                                            </div>
                                        )}
                                    <div className="UploadedFilesList__Item__Text">
                                        {file.name}
                                        {' '}
                                        <span>
                                            {file.type.split('/')[1].toUpperCase()}
                                            {', '}
                                            {bytesToSize(file.size)}
                                        </span>
                                    </div>
                                    <div className="UploadedFilesList__Item__Button">
                                        <button
                                            onClick={(e) => {
                                                removeFile(e, file);
                                            }}
                                            type="button"
                                            className="UploadedFilesList__DeleteButton"
                                        >
                                            <span className="UploadedFilesList__DeleteButton__Icon"/>
                                            <span
                                                className="sr-only"
                                            >
                                                {t('onboardingFlow.documetsPage.extinguish')}
                                            </span>
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
        </div>
    );
};

export default UploadDocuments;
