export const productOptions = [
    {
        label: 'ZIFD',
        value: 'ZIFD',
    },
    {
        label: 'PK',
        value: 'PK',
    },
    {
        label: 'AZP',
        value: 'AZP',
    },
    {
        label: 'ZIVV',
        value: 'ZIVV',
    },
];

export const productStrategyOptions = [
    {label: 'TIF 25', value: 'TIF25'},
    {label: 'TIF 36', value: 'TIF36'},
];

export const preferenceStocks = [{
    label: 'Option 1',
    value: 'option1',
},
{
    label: 'Option 2',
    value: 'option2',
},
{
    label: 'Option 3',
    value: 'option3',
}];

export const preferenceExclusionOptions = [
    {
        label: 'Real Estate',
        value: 'realEstate',
    },
    {
        label: 'Bonds',
        value: 'bonds',
    },
    {
        label: 'Stock',
        value: 'stock',
    },
];

export const substituteForExecution = [
    {
        label: 'Equities / Alternative investments',
        value: 'equities',
    },
];

export const strategyOptions = [];
export const defaultProducts = {
    products: [
        {
            id: '1',
            new: false,
            product: 'ZIFD',
            amount: 25918,
            strategy: 'TIF25',
            pendingReq: false,
        },
        {
            id: '2',
            new: false,
            product: 'ZIVV',
            amount: 10000,
            strategy: 'Growth 11 (ZIAM411)',
            pendingReq: false,
        },
        {
            id: '3',
            new: false,
            product: 'ZIWP Immediate',
            amount: 83293.93,
            strategy: 'Growth 11 (ZIAM411)',
            pendingReq: false,
        },
        {
            id: '4',
            new: false,
            product: 'ZIC',
            amount: 10000,
            strategy: '-',
            pendingReq: false,
        },
        {
            id: '5',
            new: false,
            product: 'PK',
            amount: 10000,
            strategy: '-',
            pendingReq: false,
        },
        {
            id: '1',
            new: false,
            product: 'ZIFD',
            amount: 25918,
            strategy: 'TIF25',
            pendingReq: true,
        },
    ],
};
