import PropTypes from 'prop-types';
import React from 'react';
import { InvestorProfileContext } from './context';
import { useFormData } from './hooks/useFormData';
import { useQuestionnaire } from './hooks/useQuestionnaire';

export const InvestorProfileProvider = ({ options, children }) => {
    const { clientId, sessionStorageKey } = options;
    const questionnaire = useQuestionnaire({ clientId });
    const formData = useFormData({serverData: questionnaire?.data, sessionStorageKey });
    const getValue = (callback) => callback({
        clientId,
        questionnaire,
        formData,
    });

    return (
        <InvestorProfileContext.Provider
            value={{
                clientId, questionnaire, formData, getValue,
            }}
        >
            {children}
        </InvestorProfileContext.Provider>
    );
};

InvestorProfileProvider.propTypes = {
    options: PropTypes.shape({
        clientId: PropTypes.number || undefined,
        productId: PropTypes.number,
        sessionStorageKey: PropTypes.string.isRequired,
    }).isRequired,
};
