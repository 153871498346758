import React, {
    useEffect, useMemo, useState, useCallback,
} from 'react';
import { useLayout } from 'prodivers/layout';
import { useTranslation } from 'react-i18next';
import { Logo } from 'components/Logo';
import ThemeToggle from 'components/ThemeToggle';
import SideNavigation from 'components/SideNavigation';
import QuickAccessMenu from 'components/QuickAccessMenu';
import {useNavigate} from 'react-router-dom';
import { useDetectedHandheldDevice } from './useDetectedHandheldDevice';
import { useManagerProfile } from '../prodivers/managerProfile';
import { useNotifications } from './useNotifications';
import {useAuth} from '../domain/Auth/hooks/useAuth';
import {useClientProfile} from '../prodivers/clientProfile';

export const useMainLayout = ({ pathname }) => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { initHeaderState } = useLayout();
    const iframeURL = useMemo(() => (global.AppConfig.IFRAME_CASE_MANAGEMENT_URL));
    const { t } = useTranslation();
    const { isHandheldSize } = useDetectedHandheldDevice();
    const { data: { name, email, image }, hasAccessPolicy } = useManagerProfile();
    const { onReadNotification, allNotifications, errorNotifications } = useNotifications();
    const { clientId } = useClientProfile();

    const logoutAction = useCallback(() => logout(), [logout]);

    const [isOpen, setOpen] = useState(false);
    const [isOpenProfile, setOpenProfile] = useState(false);
    const toggleNavigation = useCallback(() => setOpen((v) => !v), []);

    const current = useMemo(() => pathname.split('/')[1], [pathname]);

    const navigation = useMemo(() => {
        const navigationHeader = [
            {
                title: t('navigation.sideNav.dashboard'), link: '/dashboard', active: current === 'dashboard', name: 'dashboard',
            },
            {
                title: t('navigation.sideNav.clients'), link: '/clients', active: current === 'clients', name: 'clients',
            },
            {
                title: t('navigation.sideNav.documents'),
                link: '/documents',
                active: current === 'documents',
                submenu: null,
                disabled: !hasAccessPolicy('EnableDocumentLibraryTab'),
                name: 'documents',
            },
            {
                title: t('navigation.sideNav.caseManagement'),
                to: {pathname: iframeURL},
                active: current === 'case-management',
                target: '_blank',
                disabled: !hasAccessPolicy('CaseManagement'),
                name: 'caseManagement',
            },
            {
                title: t('navigation.sideNav.reports'),
                link: '/reports',
                active: current === 'reports',
                name: 'reports',
                disabled: !hasAccessPolicy('EnableGeneralReports'),
            },
        ];

        return navigationHeader.filter(f => !f.disabled);
    }, [t, pathname, hasAccessPolicy]);
    const navigationTablet = useMemo(() => (
        isHandheldSize && (
            <SideNavigation
                isOpen={isOpen}
                current={pathname}
                onClose={() => setOpen(false)}
            />
        )
    ), [isHandheldSize, isOpen, pathname]);
    const profileInfo = useMemo(() => ({
        name,
        image,
        email,
        withAlert: true,
        withEdit: true,
        editText: t('advisoryDashboard.profileMenu.editProfile'),
        onEdit: () => {
            setOpenProfile(false);
            navigate('/edit-profile');
        },
        onLogout: logoutAction,
        logoutText: t('advisoryDashboard.profileMenu.logout'),
        notifications: {
            data: allNotifications,
            onReadNotification,
            emptyMessage: t('advisoryDashboard.profileMenu.notifications.empty'),
            errorMessage: errorNotifications,
        },
        visible: isOpenProfile,
        onVisibleChange: setOpenProfile,
        footer: <ThemeToggle />,
    }), [
        allNotifications,
        email,
        errorNotifications,
        image,
        logoutAction,
        name,
        onReadNotification,
        t,
        isOpenProfile,
        setOpenProfile,
    ]);

    useEffect(() => {
        if (!clientId) {
            initHeaderState({
                content: Logo,
                navigation,
                profileInfo,
                navigationTablet,
                quickAccessMenuComponent: QuickAccessMenu,
                onLeftButtonClick: toggleNavigation,
                onRightButtonClick: logoutAction,
            });
        }
    }, [
        logoutAction,
        initHeaderState,
        navigation,
        navigationTablet,
        toggleNavigation,
        profileInfo,
        clientId,
    ]);
};
