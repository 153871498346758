import {useQuery} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import ServerError from '../../errors/ServerError';
import SM from '../../services/ServiceManager';
import {AdvisoryResponse} from '../../core/types/api/Api';

export const usePostAdvisoryProcessData = ({
    contactGroupId, isEnabled = true,
}) => {
    const result = useQuery({
        queryKey: ['postAdvisoryProcessData', contactGroupId],
        enabled: !!contactGroupId && isEnabled,
        retry: false,
        queryFn: async () => {
            const response: AxiosResponse<AdvisoryResponse> = await SM.customAdvisoryServices(
                'getAdvisoryProcessData',
                [{payload: {contactGroupId}}],
            );

            return response;
        },
    });

    return {
        ...result,
        data: result?.data?.data,
        statusCode: result?.data?.status,
        error: result.error ? new ServerError(result.error) : result.error,
    };
};
