import React from 'react';
import {
    arrayOf, func, shape, number,
} from 'prop-types';
import 'ui-library/components/Table/Table.css';

import { Pagination } from 'ui-library';
import { createUniqueKey } from 'utils';

import { TableRow, columnPropType, rowPropType } from './TableRow';
import ActionButtons, { actionsPropTypes } from './ActionsButtons/ActionsButtons';

const CustomTable = ({
    columns, data, onChange, paging, actions,
}) => (
    <>
        {actions && <ActionButtons actions={actions}/>}
        <div className="Table-wrapper table-wrapper">
            <table className="Table table">
                <thead>
                    <tr>
                        {columns.map(({ key, title }) => (
                            <th key={key}>{title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((rowData, idx) => (
                        <TableRow
                            key={createUniqueKey(idx, JSON.stringify(rowData))}
                            rowData={rowData}
                            columns={columns}
                        />
                    ))}
                </tbody>
            </table>
            {paging.pageSize && (
                <Pagination
                    total={paging.totalPages}
                    currentPage={paging.pageNumber}
                    pageSize={paging.pageSize}
                    onChange={onChange}
                />
            )}
        </div>
    </>
);

CustomTable.defaultProps = {
    data: [],
    onChange: null,
    paging: {
        pageSize: null,
        currentPage: null,
    },
    actions: null,
};

CustomTable.propTypes = {
    columns: columnPropType.isRequired,
    data: arrayOf(rowPropType),
    onChange: func,
    paging: shape({
        pageSize: number,
        currentPage: number,
    }),
    actions: actionsPropTypes,
};

export default CustomTable;
