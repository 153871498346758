/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, {
    FC,
    Fragment, useCallback, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Button, Checkbox, Icon, Loader,
    Title,
} from 'ui-library';

import {InvestmentDepot} from '../../datasource/useInvestDepots/useInvestDepots';
import {useFormatting} from '../../locale';
import {DEFAULT_APP_CURRENCY} from '../../constants/constants';

import './InvestDepot.css';

const InvestDepots:FC<{datasource: () => any}> = ({datasource}) => {
    const {t} = useTranslation();
    const {dfsClientId} = useParams();
    const navigate = useNavigate();
    const {
        data, isLoading, isJoint, handleProfileSelection, setIsTouched,
    } = datasource();

    const {getFormattedCurrency} = useFormatting();
    const [selectedAmounts, setSelectedAmount] = useState<Record<string, number>>({});

    const openDepotDetails = useCallback((portfolioId: number) => {
        navigate(`/client/${dfsClientId}/portfolios/${portfolioId}/tab/overview`);
    }, [navigate]);

    const onDepotSelect = useCallback((event: { target: { checked: boolean; }; }, item: InvestmentDepot) => {
        setIsTouched(true);
        handleProfileSelection({
            checked: event?.target?.checked,
            item: {
                portfolioId: item?.portfolioId, value: item.value, checked: event?.target?.checked,
            },
        });
        setSelectedAmount(prev => ({
            ...prev, [item.portfolioId]: !prev[item.portfolioId] ? item.value : 0,
        }));
    }, []);

    const totalAmountSelected = useMemo(() => Object.values(selectedAmounts).reduce((acc, cur) => acc + cur, 0), [selectedAmounts]);

    const renderItem = useMemo(() => data?.map((item: InvestmentDepot) => (
        <Fragment key={item.portfolioId}>
            {/* @ts-ignore */}
            <Checkbox label="" checked={item?.checked} onChange={(event) => onDepotSelect(event, item)}/>
            <div className="item">
                <Button type="link" onClick={() => openDepotDetails(item.portfolioId)}>
                    <Title type={4}>
                        {item?.modelPortfolioName}
                    </Title>
                    {item.name}
                    {isJoint && <p>{t('connections.groupName.ja')}</p>}
                    {/* @ts-ignore */}
                    <Icon type="open" size={16} />
                </Button>
                <p className="amount">
                    {getFormattedCurrency(item.value, {
                        currency: DEFAULT_APP_CURRENCY,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </p>
            </div>
        </Fragment>
    )), [data]);

    if (isLoading) {
        return <Loader/>;
    }

    if (!data?.length) {
        return <section><p>{t('investDepots.noDepots')}</p></section>;
    }

    return (
        <section className="invest-depot">
            <p className="span">{t('investDeposts.pleaseSelect')}</p>
            <p />
            <p className="title">{t('investDepots.depot')}</p>
            <p className="title">{t('investDepots.value')}</p>
            <p/>
            {renderItem}
            <p className="span">
                {t(`investDepots.${isJoint ? 'ja' : 'single'}`)}
            </p>
            <section className="totals">
                <p className="label">{t('investDepots.totalAmount')}</p>
                <p className="amount">
                    {getFormattedCurrency(totalAmountSelected, {
                        currency: DEFAULT_APP_CURRENCY,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })}
                </p>
            </section>
        </section>
    );
};

export default InvestDepots;
