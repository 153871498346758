export const getMeterChartOptions = ({max, range, value, width = 450, height = 297}) => {
    return {
        chart: {
            type: "gauge",
            plotBackgroundColor: "#fff",
            plotBackgroundImage: undefined,
            plotBorderWidth: 0,
            plotShadow: false,
            width: width,
            height: height,
        },
        title: {
            text: "",
        },
        plotOptions: {
            gauge: {
                dial: {
                    radius: "95%",
                    backgroundColor: "#23366F",
                    borderColor: "#23366F",
                    borderWidth: 1,
                    baseWidth: 12,
                    topWidth: 3,
                    baseLength: "0%", // of radius
                    rearLength: "0%",
                    //   color: '#ff0000'
                },
            },
        },

        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: undefined,
            center: ["50%", "75%"],
            size: "120%",
        },

        // the value axis
        yAxis: {
            min: 0,
            max: max || 20,

            minorTickInterval: "auto",
            minorTickWidth: 0,
            minorTickLength: 2,
            minorTickPosition: "inside",
            minorTickColor: "#666",

            tickPixelInterval: 40,
            tickWidth: 0,
            tickPosition: "inside",
            tickLength: 1,
            tickColor: "#666",
            labels: {
                step: 1,
                rotation: 0,
                y: 0,
                style: {
                    color: "#2C438B",
                    fontSize: "21px",
                },
            },
            title: {
                text: "",
            },
            plotBands: [
                {
                    from: 0,
                    to: max,
                    color: {
                        linearGradient:  { x1: 1, x2: 0, y1: 0, y2: 0 },
                        stops: [
                            [0, '#91C8F2'],
                            [1, '#23366F']
                        ],
                    },
                    outerRadius: "125%",
                    thickness: "25%",
                },
                {
                    from: range?.from || 10,
                    to: range?.to || 15,
                    color: "#DDE4E3",
                    outerRadius: "125%",
                    thickness: "25%",
                },
            ],
        },

        series: [
            {
                type: "gauge",
                name: "Percentage",
                data: [value || 12],
                tooltip: {
                    valueSuffix: " %",
                },
                dataLabels: {
                    format: "{y} %",
                    borderWidth: 0,
                    color: "#0F67BF",
                    style: {
                        fontSize: "27px",
                    },
                },
            },
        ],
    }
}