import React, {useEffect} from 'react';
import {Title} from 'ui-library';

import pencilIcon from 'clientRequirements/images/pencilIcon.png';
import {useTranslation} from 'react-i18next';
import {InvestorProfileBase} from 'components/InvestorProfileBase/InvestorProfileBase';
import {useInvestorProfile} from 'components/InvestorProfileBase/hooks/useInvestorProfile';

import './InvestorProfile.css';
import { useCreatePortfolioSelectors } from 'datasource/CreatePortfolio';

export function InvestorProfile({
    dfsClientId, clientAge, clientName, title, isSubmitted, isSending, onQuestionnaireUpdate,
}) {
    const {t} = useTranslation();
    const {advisoryDataSelector} = useCreatePortfolioSelectors();

    const investorProfile = useInvestorProfile({dfsClientId, clientAge, advisoryDataSelector});

    useEffect(() => {
        onQuestionnaireUpdate?.(dfsClientId, investorProfile);
    }, [
        investorProfile?.data,
        investorProfile?.formData,
        investorProfile?.groupId,
        investorProfile?.isDataChanged,
        investorProfile?.postQuestionnaire,
        investorProfile?.calculateRisk,
        investorProfile?.setChosenRisk,
        investorProfile?.isLoading,
        investorProfile?.clientId,
    ]);

    return (
        <div className="new-portfolio investor-profile">
            {
                title && (
                    <Title type={1}>
                        {title}
                    </Title>
                )
            }
            {!investorProfile?.isEditMode
                ? (
                    <>
                        <div className="LinkedHeader">
                            <Title type={2}>
                                {t('portfolio.createNewPortfolio.investorProfile')}
                                {' '}
                                {clientName}
                            </Title>

                            <button
                                className="button-link"
                                type="button"
                                onClick={() => {
                                    investorProfile.editInvestorProfile();
                                }}
                            >
                                <span>
                                    <img
                                        className="penclIicon"
                                        src={pencilIcon}
                                        alt=""
                                    />
                                </span>
                                {t('interaction.edit')}
                            </button>
                        </div>
                        <p>
                            {t('investorProfile.validTill')}
                            :
                            {' '}
                            {investorProfile.expirationDate}
                        </p>
                    </>
                ) : (
                    <InvestorProfileBase
                        errors={isSubmitted ? investorProfile.errors : {}}
                        isLoading={investorProfile.isLoading || isSending}
                        investorProfileGroup={investorProfile.investorProfileGroup}
                        title={investorProfile.investorProfileGroup?.Name}
                        values={investorProfile.formData?.[investorProfile.groupId] || {}}
                        onChange={investorProfile.onChange}
                        data={investorProfile.investorProfileGroup}
                        t={t}
                    />
                )}
        </div>
    );
}
