import React, {
    useEffect, useState, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Row from 'ui-library/components/Table/Body/Row';
import NoDataCell from 'ui-library/components/Table/NoDataCell';
import { useTableConfig } from 'ui-library/components/Table/hooks/useTableConfig';
import { setDataKeys, getUpdatedSnapshot, onRowFunction } from 'ui-library/components/Table/utils/utils';
import withTheme from 'ui-library/hocs/withTheme';

const Rows = (props) => {
    const {
        data: dataInit,
        noDataText,
        onRow,
        onChange,
        rowClassName,
    } = props;
    const table = useRef(null);
    const dataSource = useMemo(() => setDataKeys(dataInit), [dataInit]);
    const [data, setData] = useState(dataSource);
    const {
        columns,
        paging,
        sorting,
        filtering,
        expandedRows,
        expandOnClick,
    } = useTableConfig({ ...props, table, dataSource });

    useEffect(() => {
        const {
            data: dataUpdated,
            total,
        } = getUpdatedSnapshot({
            data: dataSource,
            columns,
            sorting,
            filtering,
            paging,
        });

        setData(dataUpdated);
        if (typeof onChange === 'function') {
            onChange(
                paging,
                filtering,
                sorting,
                { currentDataSource: dataSource, updatedData: dataUpdated, updatedTotal: total },
            );
        }
    }, [dataSource, sorting, filtering, paging]);

    return (
        data.length
            ? data.map((item, index) => (
                <Row
                    data={item}
                    rowClassName={rowClassName}
                    index={index}
                    columns={columns}
                    expandedRows={expandedRows}
                    onRow={onRowFunction(onRow)}
                    expandOnClick={expandOnClick}
                    key={`row-${item.key}`}
                />
            ))
            : <NoDataCell colSpan={columns.length} noDataText={noDataText} />
    );
};

Rows.propTypes = {
    /** Data record array to be displayed */
    data: PropTypes.arrayOf(PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
            PropTypes.func,
        ]),
    })),
    /** Setting the default sorting for the column use
    * an object {key: string, direction: string } to define the
    * column key and 'asc' or 'desc' sort order */
    noDataText: PropTypes.string,
    scroll: PropTypes.shape({
        y: PropTypes.number,
    }),
    rowSelection: PropTypes.shape({
        type: PropTypes.oneOf(['radio', 'checkbox']),
        selectedRowKeys: PropTypes.arrayOf(PropTypes.string),
        onChange: PropTypes.func,
    }),
    onRow: PropTypes.func,
    onChange: PropTypes.func,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
        sortByLabel: PropTypes.string,
    }),
};

Rows.defaultProps = {
    data: [],
    noDataText: 'No data',
    scroll: null,
    onRow: null,
    rowSelection: null,
    onChange: () => {
    },
    headerControls: {
        selectControlsLabel: {
            selectAll: 'Select all',
            reset: 'Reset',
        },
        sortByLabel: 'By:',
    },
};

export default withTheme(Rows);
