import React from 'react';
import { Title } from 'ui-library';

interface StaticFieldProps {
  label?: string; 
  value: string|number;
  secondaryValue?: string;
  Icon?: any
  width?: string
}

export const StaticField: React.FC<StaticFieldProps> = ({ label, value, secondaryValue, Icon, width }) => (
    <div style={{'width':width}}>
      <Title type={4}><strong>{label}</strong></Title>
      {/* <label>{label}</label> */}
      <div>
        <div><span>{value}</span><span style={{"marginLeft":'5px'}}>{Icon}</span></div>
        <div>{secondaryValue}</div>
      </div>
    </div>
  );