import {useNavigate, useParams} from 'react-router-dom';
import React, {useCallback, useMemo} from 'react';
import {Icon} from '../../ui-library';
import {ICON_LONG_ARROW_RIGHT} from '../../ui-library/components/Icon';

import './InvestmentApplicationNavigationButton.css';
import {genarateDFSClientId} from '../../utils';

type InvestmentApplicationNavigationButtonProps = {
    destinationId: number,
    destination: string,
    clientId?: number | null
}

const InvestmentApplicationNavigationButton = ({destinationId, destination, clientId = null}: InvestmentApplicationNavigationButtonProps) => {
    const navigate = useNavigate();
    const {dfsClientId} = useParams();

    const urlClientId = useMemo(() => (dfsClientId || genarateDFSClientId(clientId)), [dfsClientId, clientId]);

    const onNavigationClick = useCallback(() => {
        navigate(`/client/${urlClientId}/${destination}/${destinationId}`);
    }, [navigate, destinationId, destination, urlClientId]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Icon type={ICON_LONG_ARROW_RIGHT} className="navigationButtonArrow" onClick={onNavigationClick} />; // <Button class="btn--link" onClick={onNavigationClick}>{'->'}</Button>;
};

export default InvestmentApplicationNavigationButton;
