import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Title} from 'ui-library';
import ProductRow from 'ui-library/components/ProductRow/ProductRow';
import { ProductListProps } from 'widgets/ShoppingCard/ShoppingCard.types';


// eslint-disable-next-line react/require-default-props
const ExistingProducts: FC<ProductListProps> = ({products, control, errors}) => {
    const {t} = useTranslation();

    return (
        <div className="products-on-cart">
            <Title type={4}>
                {/* {t('portfolio.createNewPortfolio.overview.existingProductsTitle')} */}
                Existing 3b portfolios
            </Title>

            {products?.map((product, index) => (
                <ProductRow
                    strategyOptions={[]}
                    control={control}
                    errors={errors}
                    key={product?.id || index}
                    index={index}
                    product={product}
                    configured
                />
            ))}
        </div>
    );
};

export default ExistingProducts;
