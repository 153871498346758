import React from 'react';
import PropTypes from 'prop-types';

const CellUrgency = ({ text, color }) => (
    <span className={`table-cell_urgency table-cell_urgency-${color}`}>
        {text}
    </span>
);

CellUrgency.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
};

CellUrgency.defaultProps = {
    text: null,
    color: null,
};

export default CellUrgency;
