/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import './CellPDFViewIcon.css';
import downloadIcon from 'clientRequirements/images/download_Icon.png';
import {Loader} from '../../../ui-library';

const CellPDFViewIcon = ({ value, onDownload, inProgress }, { id, contactId }) => {
    if (value === undefined) return null;

    return (
        <button type="button" role="link" className="table-cell_linked cell-pdf-view" onClick={() => (!inProgress ? onDownload(id, contactId) : undefined)}>
            {inProgress ? <Loader/> : <img width="18" height="18" src={downloadIcon} alt="" />}
        </button>
    );
};

CellPDFViewIcon.propTypes = {
    value: PropTypes.string,
    onDownload: PropTypes.func,
};

CellPDFViewIcon.defaultProps = {
    value: '',
    onDownload: () => {},
};

export default CellPDFViewIcon;
