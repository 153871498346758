import React, {
    useCallback, useMemo, useRef, useState,
} from 'react';
import {useSelector} from 'react-redux';

import { isAnyOfThreeBProductTypes } from 'hooks/isThreeBProduct';
import {useActions} from 'components/InvestmentApplicationsActionButton/hooks/useActions';
import { useFileExist } from 'hooks/useFileCheck';
import { APPLICATION_MAX_FILE_SIZE } from 'constants/constants';
import ServiceManager from '../../services/ServiceManager';
import {
    Button, Icon, Loader, Modal, notification,
} from '../../ui-library';
import {useLayout} from '../../prodivers/layout';
import {useInterval} from '../../hooks/useInterval';

import './InvestmentApplicationsActionButton.css';
import {memberProfileSelector} from '../../redux-store/auth/authSelectors';
import Popover from '../../ui-library/components/Popover';
import {getBase64FromFile} from '../../utils/file';

export const STATUS = {
    in_progress: 0,
    completed: 1,
    canceled: 2,
    ready_for_sign: 3,
    submitted: 4,
    ready_for_submit: 5,
    closed: 6,
    ready_for_iban_reservation: 7,
};

export const APPLICATION_STATUS = (t) => ({
    [STATUS.in_progress]: t('applicationTable.status.InProgress'),
    [STATUS.completed]: t('applicationTable.status.Completed'),
    [STATUS.canceled]: t('applicationTable.status.Canceled'),
    [STATUS.ready_for_sign]: t('applicationTable.status.ReadyForSigning'),
    [STATUS.submitted]: t('applicationTable.status.Submitted'),
    [STATUS.ready_for_submit]: t('applicationTable.status.ReadyToSubmit'),
    [STATUS.closed]: t('applicationTable.status.Closed'),
    [STATUS.ready_for_iban_reservation]: t('applicationTable.status.ReadyForIbanReservation'),
});

const POLLING_STATUS = {
    notFound: 'NotFound',
    inProgress: 'InProgress',
    error: 'Error',
    success: 'Success',
};

let errorCounter = 0;
const InvestmentApplicationsActionButton = ({
    t, status, investmentApplicationId, contactId, changePlan, updateData, getInvestmentOverview, productExternalId, isContainerCase,
}) => {
    const [inProgress, setInProgress] = useState(false);
    const { setLoadingOverlayActive } = useLayout();
    const [identifier, setIdentifier] = useState(null);
    const [cancelInProgress, setCancelInProgress] = useState(false);
    const [reGenerateInProgress, setReGenerateInProgress] = useState(false);
    const [documentUploadInProgress, setDocumentUploadInProgress] = useState(false);
    const profile = useSelector(memberProfileSelector);
    const inputFileRef = useRef();
    const {continueApplication} = useActions();
    const {checkIfFileSizeExceed, FileSizeErrorComponent} = useFileExist(APPLICATION_MAX_FILE_SIZE);

    const isThreeBProduct = isAnyOfThreeBProductTypes(productExternalId);

    const hideLoaders = () => {
        setInProgress(false);
        setLoadingOverlayActive(false);
    };

    const clearIntervalData = (destroyInterval) => {
        destroyInterval();
        errorCounter = 0;
        setIdentifier(null);
        hideLoaders();
    };

    const getStatus = useCallback(async (destroyInterval) => {
        try {
            const {data} = await ServiceManager.customService('heartBeat', [{identifier}]);

            if (data?.status === POLLING_STATUS.success) {
                const agent = `${profile.FirstName} ${profile.LastName}`;

                updateData(investmentApplicationId, {
                    status: STATUS.submitted, submittedBy: agent, agent, submittedDate: new Date(),
                });
                if (getInvestmentOverview) { getInvestmentOverview(); }
            } else if (data?.status === POLLING_STATUS.notFound) {
                notification.open({content: JSON.parse(data?.jsonResult)?.Error, type: 'error'});
            } else if (data?.status === POLLING_STATUS.error) {
                notification.open({content: JSON.parse(data?.jsonResult)?.Error || t('clientDashboard.application.completionFailed'), type: 'error'});
            }

            if ([POLLING_STATUS.success, POLLING_STATUS.error, POLLING_STATUS.notFound].includes(data?.status)) {
                clearIntervalData(destroyInterval);
            }
        } catch (err) {
            errorCounter += 1;
            if (errorCounter >= 5) {
                clearIntervalData(destroyInterval);
                notification.open({content: t('clientDashboard.application.completionFailed'), type: 'error'});
            }

            console.error(err);
        }
    }, [identifier, investmentApplicationId, updateData, getInvestmentOverview]);

    useInterval(getStatus, identifier ? 2500 : null);

    const cancelInvestmentApplication = useCallback(async () => {
        try {
            setCancelInProgress(true);
            await ServiceManager.customInvestmentService('cancelApplication', [{applicationId: investmentApplicationId}]);
            updateData(investmentApplicationId, {status: STATUS.canceled});
            notification.open({content: t('dashboard.overviewClientApplications.canceledSuccessfully'), type: 'success'});
        } catch (err) {
            notification.open({content: t('dashboard.overviewClientApplications.cancelError'), type: 'error'});
        } finally {
            setCancelInProgress(false);
        }
    }, [investmentApplicationId, updateData]);

    const onCancelClick = useCallback(() => {
        Modal.confirm({
            title: t('dashboard.overviewClientApplications.confirmTitle'),
            content: t('dashboard.overviewClientApplications.confirmMessage'),
            okText: cancelInProgress ? <Loader /> : t('dashboard.overviewClientApplications.confirmOk'),
            cancelText: t('dashboard.overviewClientApplications.confirmCancel'),
            onOk: cancelInvestmentApplication,
        });
    }, [cancelInvestmentApplication]);

    const reGenerateDocuments = useCallback(async () => {
        try {
            setReGenerateInProgress(true);
            updateData(investmentApplicationId, {forceCollapse: true});
            await ServiceManager.customInvestmentService('reGenerateInvestmentApplicationDocuments', [{investmentApplicationId}]);
            updateData(investmentApplicationId, {status: STATUS.ready_for_sign, forceCollapse: false});
        } finally {
            setReGenerateInProgress(false);
        }
    }, [investmentApplicationId, updateData]);

    const onReGenerateDocumentsClick = useCallback(() => {
        Modal.confirm({
            title: t('dashboard.overviewClientApplications.reGenerateDocsTitle'),
            content: t('dashboard.overviewClientApplications.reGenerateDocsMessage'),
            okText: reGenerateInProgress ? <Loader /> : t('dashboard.overviewClientApplications.confirmReGenerate'),
            cancelText: t('dashboard.overviewClientApplications.confirmCancel'),
            onOk: reGenerateDocuments,
        });
    }, [reGenerateDocuments]);

    const getCurrentThreeBDocumntInfo = useCallback(async () => {
        const { data: docs } = await ServiceManager.customDocumentsService(
            'getDocumentsByInvestmentApplicationId',
            [{ investmentApplicationId, contactId }],
        );

        return docs;
    }, [investmentApplicationId]);

    const handleDocumentUpload = useCallback(async (e) => {
        let documentId = null;

        try {
            setDocumentUploadInProgress(true);

            if (isThreeBProduct && status === STATUS.ready_for_submit) {
                const docs = await getCurrentThreeBDocumntInfo();

                documentId = docs?.[0]?.id;
            }

            const file = e.target.files[0];

            if (!file.type.includes('pdf')) {
                notification.open({content: t('validation.wrongFormat'), type: 'error'});

                return;
            }

            if (checkIfFileSizeExceed(file.size)) return;
            const fileForUpload = await getBase64FromFile(file);

            await ServiceManager.customDocumentsService('upload3bApplicationFormDocuments', [{investmentApplicationId, document: {documentId, filename: file.name, file: fileForUpload.split(',')[1]}}]);
            updateData(investmentApplicationId, {status: STATUS.ready_for_submit});
        } catch (err) {
            notification.open({content: t('general.somethingWentWrong'), type: 'error'});
        } finally {
            setDocumentUploadInProgress(false);
        }
    }, [investmentApplicationId, updateData, getCurrentThreeBDocumntInfo]);

    const executeChangePlan = useCallback(async () => {
        await ServiceManager.customInvestmentService('changePlan', [{investmentApplicationId}]);
        const agent = `${profile.FirstName} ${profile.LastName}`;

        updateData(investmentApplicationId, {
            status: STATUS.submitted, submittedBy: agent, agent, submittedDate: new Date(),
        });
        if (getInvestmentOverview) { getInvestmentOverview(); }
        hideLoaders();
    }, [investmentApplicationId, profile, getInvestmentOverview, updateData]);

    const onMakeIbanReservationClick = useCallback(async () => {
        try {
            setInProgress(true);

            await ServiceManager.customInvestmentService('makeIbanReservation', [{investmentApplicationId}]);
            updateData(investmentApplicationId, {
                status: STATUS.ready_for_sign,
            });
            notification.open({content: t('dashboard.overviewClientApplications.ibanReserved'), type: 'success'});
        } catch (err) {
            notification.open({content: t('general.somethingWentWrong'), type: 'error'});
        } finally {
            setInProgress(false);
        }
    }, [investmentApplicationId]);

    const onButtonClick = useCallback(async () => {
        if (status === STATUS.ready_for_submit) {
            try {
                setInProgress(true);
                setLoadingOverlayActive(true);

                if (isThreeBProduct) {
                    if (changePlan) {
                        await executeChangePlan();
                    } else {
                        const {data} = await ServiceManager.customInvestmentService('signDocuments', [{investmentApplicationId}]);

                        setIdentifier(data);
                    }
                } else {
                    const { data: docs } = await ServiceManager.customDocumentsService(
                        'getDocumentsByInvestmentApplicationId',
                        [{investmentApplicationId, contactId}],
                    );

                    let canSign = false;
                    let isSent = false;

                    if (docs?.length) {
                        canSign = !docs.find((doc) => (
                            // TODO: not signed - constant needed
                            doc?.signStatus === 2 || doc?.signStatus === 3
                        ));
                        const transferDocument = docs.filter((doc) => (
                            typeof doc?.isSent === 'boolean'
                        ));

                        isSent = transferDocument?.length ? !transferDocument.find((doc) => (doc?.isSent === false)) : true;
                    }

                    if (canSign && isSent) {
                        if (changePlan) {
                            await executeChangePlan();
                        } else {
                            const {data} = await ServiceManager.customInvestmentService('signDocuments', [{investmentApplicationId}]);

                            setIdentifier(data);
                        }
                    } else {
                        hideLoaders();
                        Modal.info({
                            title: t('documentSign.modal.title'),
                            content: t('documentSign.modal.description'),
                            okText: t('clientDashboard.confirm'),
                        });
                    }
                }
            } catch (err) {
                notification.open({content: err.message, type: 'error'});
                hideLoaders();
            }
        } else if (status === STATUS.in_progress) {
            try {
                setInProgress(true);

                await continueApplication({
                    contactId,
                    hideLoaders,
                    investmentApplicationId,
                });
            } catch (err) {
                notification.open({content: err.message, type: 'error'});
                hideLoaders();
            }
        }
    }, [status, updateData, executeChangePlan]);

    const translations = useMemo(() => {
        switch (status) {
            case STATUS.in_progress:
                return t('dashboard.overviewClientApplications.createNewPlan');
            case STATUS.ready_for_submit:
                return t('dashboard.overviewClientApplications.signDocuments');
            default:
                return null;
        }
    }, [status]);

    if ([STATUS.completed, STATUS.canceled, STATUS.submitted].includes(status)) {
        return null;
    }

    const showCancelButton = [STATUS.in_progress, STATUS.ready_for_sign, STATUS.ready_for_submit, STATUS.ready_for_iban_reservation].includes(status);
    const showReGenerateButton = [STATUS.ready_for_sign, STATUS.ready_for_submit].includes(status) && !isThreeBProduct;
    const showUploadDocumentsFor3b = [STATUS.ready_for_sign].includes(status) && isThreeBProduct;
    const disableButtons = documentUploadInProgress || reGenerateInProgress || inProgress || cancelInProgress;
    const showMakeIbanReservation = status === STATUS.ready_for_iban_reservation;
    const showContinueSubmitButton = (isContainerCase ? [STATUS.ready_for_submit] : [STATUS.in_progress, STATUS.ready_for_submit]).includes(status);

    return (
        <>
            <FileSizeErrorComponent />
            <div className="flex flex-wrap investment-app-buttons">
                {showContinueSubmitButton && (
                    <Button onClick={onButtonClick} disabled={disableButtons}>
                        {inProgress ? <Loader /> : translations}
                    </Button>
                )}
                {showReGenerateButton && (
                    <Popover content={<div className="investment_applications_popover">{t('dashboard.overviewClientApplications.reGenerateDocuments')}</div>} trigger="hover">
                        <Button onClick={onReGenerateDocumentsClick} disabled={disableButtons}>
                            {reGenerateInProgress ? <Loader /> : (
                                <>
                                    <Icon type="sync" size={30}/>
                                    <p>
                                        {t('dashboard.overviewClientApplications.labels.reGenerateDocuments')}
                                    </p>
                                </>
                            )}
                        </Button>
                    </Popover>
                )}

                {isThreeBProduct && status === STATUS.ready_for_submit && (
                    <Popover
                        content={(
                            <div className="investment_applications_popover">
                                {t('dashboard.overviewClientApplications.ReUploadDocuments')}
                            </div>
                        )}
                        trigger="hover"
                    >
                        <Button
                            onClick={() => inputFileRef.current.click()}
                            disabled={disableButtons}
                        >
                            {documentUploadInProgress ? (
                                <Loader />
                            ) : (
                                <>
                                    <Icon
                                        className="upload-button"
                                        type="download"
                                        size={30}
                                    />
                                    <p>{t('dashboard.overviewClientApplications.ReUploadDocuments')}</p>
                                </>
                            )}
                        </Button>
                    </Popover>
                )}

                {showUploadDocumentsFor3b && (
                    <Popover content={<div className="investment_applications_popover">{t('dashboard.overviewClientApplications.uploadDocuments')}</div>} trigger="hover">
                        <Button onClick={() => inputFileRef.current.click()} type="button" disabled={disableButtons} >
                            {documentUploadInProgress ? <Loader/> : (
                                <>
                                    <Icon className="upload-button" type="download" size={30} />
                                    <p>
                                        {t('dashboard.overviewClientApplications.uploadDocuments')}
                                    </p>
                                </>
                            )}

                        </Button>
                    </Popover>
                )}
                {showMakeIbanReservation && (
                    <Button onClick={onMakeIbanReservationClick} disabled={disableButtons}>
                        {inProgress ? <Loader /> : t('dashboard.overviewClientApplications.makeIbanReservation')}
                    </Button>
                )}
                {showCancelButton && (
                    <Button onClick={onCancelClick} disabled={disableButtons}>
                        {cancelInProgress ? <Loader /> : (
                            <>
                                <Icon type="delete" size={30} />
                                <p>
                                    {t('dashboard.overviewClientApplications.confirmTitle')}
                                </p>
                            </>
                        )}
                    </Button>
                )}
                <input ref={inputFileRef} type="file" onChange={handleDocumentUpload} className="hide-manual-sign-input" accept="application/pdf"/>
            </div>
        </>
    );
};

export default InvestmentApplicationsActionButton;
