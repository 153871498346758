import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Note from 'components/common/Note/Note';
import {usePortfolioProcessNavigation} from '../../hooks/helpers/usePortfolioProcessNavigation';
import {Button} from '../../ui-library';

import './AdvisoryTypeSelection.css';
import {
    UploadAdvisoryDocumentModal,
} from '../../pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/components/UploadAdvisoryDocumentModal';
import {
    DocumentType,
} from '../../pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/hooks/useAdvisoryDocumentBase';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from '../../datasource/CreatePortfolio';

export const AdvisoryTypeSelection = () => {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);

    const {
        contactGroupIdSelector,
    } = useCreatePortfolioSelectors();

    const {
        setUploadedAdvisoryDocumentAction,
    } = useCreatePortfolioActions();

    const {stepComplete} = usePortfolioProcessNavigation({
        enableAutoStepComplete: false,
    });

    const onModalClose = useCallback(async (advisoryDocument: DocumentType | null) => {
        setShowModal(false);
        if (advisoryDocument) {
            setUploadedAdvisoryDocumentAction(advisoryDocument);
            stepComplete();
        }
    }, [setUploadedAdvisoryDocumentAction, stepComplete]);

    const onNewProcessClick = useCallback(() => {
        setUploadedAdvisoryDocumentAction(null);
        stepComplete();
    }, [stepComplete]);

    return (
        <section className="advisory-type-selection">
            <Button
                type="primary"
                className="start-new-process"
                onClick={onNewProcessClick}
            >
                {t('advisoryTypeSelection.startNewAdvisory')}
            </Button>
            <p>{t('advisoryTypeSelection.or')}</p>
            <Button
                className="upload-existing"
                type="outline"
                onClick={() => setShowModal(true)}
            >
                {t('advisoryTypeSelection.uploadExisting')}
            </Button>
            <Note text="advisoryTypeSelection.note"/>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <UploadAdvisoryDocumentModal
                show={showModal}
                onClose={onModalClose}
                inAdvisoryFlow
                contactGroupId={contactGroupIdSelector}
            />
        </section>
    );
};
