import React, {useEffect} from 'react';
import { number } from 'prop-types';

import {Loader} from 'ui-library';

import { useTranslation } from 'react-i18next';

import {
    columns,
} from './constants/const';

import { useGetData } from './useGetData';
import {useFormatting} from '../../locale';
import Rows from './Rows';

const DocumentsCollapseTable = ({
    investmentApplicationId, contactId, contractStatus: applicationStatus, updateData,
}) => {
    const { t } = useTranslation();
    const {getFormattedDate} = useFormatting();

    const tableColumns = columns(t, getFormattedDate);

    const {
        isLoading, data, getInvestmentApplications,
    } = useGetData(investmentApplicationId, contactId, applicationStatus, updateData);

    useEffect(() => {
        (async () => {
            if (investmentApplicationId && contactId) {
                await getInvestmentApplications();
            }
        })();
    }, [investmentApplicationId, contactId]);

    if (isLoading) {
        return (
            <tbody>
                <tr className="collapsible-row row-level-0"><td colSpan="9" className="collapsible-nodata"><Loader /></td></tr>
            </tbody>
        );
    }

    return (
        <tbody>
            {data.length === 0
                ? <tr className="collapsible-row row-level-0"><td colSpan="9" className="collapsible-nodata">{t('advisoryDashboard.documents.noDocuments')}</td></tr>
                : (
                    <Rows
                        rowClassName="collapsible-row"
                        columns={tableColumns}
                        data={data}
                    />
                )}
        </tbody>
    );
};

DocumentsCollapseTable.propTypes = {
    investmentApplicationId: number.isRequired,
    contactId: number.isRequired,
};

export default DocumentsCollapseTable;
