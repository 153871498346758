import {useCallback, useState} from 'react';
import {
    useBaseAgentInformation,
} from 'pages/ClientOverview/pages/Portfolios/components/AgentInformation/hooks/useBaseAgentInformation';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';

export const useAgentInformation = ({
    dfsClientId, saveFeeData, isPk,
}) => {
    const [pageErrors, setPageErrors] = useState([]);
    const {
        selectedProductSelector,
        currentInvestmentApplicationSelector,
    } = useCreatePortfolioSelectors();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();

    const newPortfolio = {
        product: selectedProductSelector,
        applicationData: currentInvestmentApplicationSelector,
        modelPortfolio: currentInvestmentApplicationSelector.modelPortfolio,
    };
    const {
        t,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbChange,
        advisorData,
        isValid,
        validation,
        setContinuesValidation,
        changeVSTNumber,
        vstNumber,
        isLoading,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    } = useBaseAgentInformation({
        dfsClientId,
        newPortfolio,
    });

    const onContinue = useCallback(async () => {
        setContinuesValidation(true);
        if (isValid) {
            try {
                const payload = {
                    issueCommission: issueCommissionSelected || '0',
                    pbNumber: String(pbSelected),
                    vstNumber,
                    agencyId: selectedGeneralAgency,
                    agentId: selectedAgent,
                };

                let feeOverview;

                if (saveFeeData && !isPk) {
                    feeOverview = await saveFeeData();
                }
                const result = await saveInvestmentApplicationDataAction({
                    payload,
                    method: 'saveAgentData',
                    additionalData: {
                        agentInformation: {
                            pbSelected,
                            issueCommissionSelected,
                            vstNumber,
                            selectedGeneralAgency,
                            selectedAgent,
                        },
                        feeOverview,
                    },
                });

                if (!result) return;
                stepComplete();
            } catch (err) {
                setPageErrors(err.message);
            }
        }
    }, [
        dfsClientId, pbSelected, vstNumber,
        issueCommissionSelected, isValid, selectedGeneralAgency, selectedAgent, saveFeeData, isPk,
    ]);

    const {stepComplete} = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        navigationDisabled: isLoading,
        pageErrors,
    });

    return {
        t,
        validation,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbChange,
        advisorData,
        changeVSTNumber,
        vstNumber,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    };
};
