/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import {DatePicker, NumberInput, Title} from 'ui-library';
import moment from 'moment';

import ButtonRadioElement from 'components/AtomicStructure/atoms/ButtonRadioElement';
import Input from 'components/AtomicStructure/atoms/Input';
import SelectElement from 'components/AtomicStructure/atoms/SelectElement';
import Preloader from 'components/Preloader';
import {withMainLayout} from 'hocs';
import {compareDates, formatDateWithoutLocalConversion} from 'utils/datetime';
import {validateIban} from 'utils/validation';
import {Status, StatusBox} from '../KYC/components/StatusBox/StatusBox';
import WithDrawalPlanSummary from './WithDrawalPlanSummary';
import {WithdrawalPlanProps} from './types';
import './WithdrawalPlan.css';
import PageSection from '../../components/PageSection';

const WithdrawalPlan = ({datasource}: WithdrawalPlanProps) => {
    const { t } = useTranslation();
    const {
        data,
        withdrawalPlanData,
        planCalculationLoading,
        errorData,
        isRegularTransferPlan,
        optionWithdrawalPeriodicity,
        isLoading,
        isOptionsLoading,
        productExternalId,
        productDesc,
        isAzp,
        isAzpI,
        isAzpD,
        isPk,
        isZiv,
        isZifd,
        trigger,
        setErrorData,
        option,
        isAtLeastOneProductOpened,
        displayWithdrawalPlanCalculations,
        disableDateHandler,
        setIsRegularTransferPlan,
        control,
    } = datasource();

    const getSelectedTitle = () => {
        if (isAzp) {
            return t('3b.withdrawalPlan.defineWithdrawalPlan');
        } if (isZifd || isZiv) {
            return t('3b.withdrawalPlan.setupWithdrawalTitle');
        } if (isPk && isRegularTransferPlan) {
            return t('3b.withdrawalPlan.setupTransferTitle');
        }

        return null;
    };

    return (
        <PageSection className="newStyle withdrawalPlan">
            <Preloader isLoading={isLoading || isOptionsLoading} >
                <div className="container">
                    {(isAzp || isZifd || isZiv) && (
                        <Title type={1}>
                            {productDesc}
                        </Title>
                    )}
                    {(isZifd || isZiv) && (
                        <Title type={2}>
                            {t('3b.withdrawalPlan.subtitle')}
                        </Title>
                    )}
                    {isPk && (
                        <div>
                            <Title type={1}>
                                {t('3b.withdrawalPlan.pkTitle')}
                            </Title>
                            <Title type={2}>
                                {t('3b.withdrawalPlan.pkSubtitle')}
                            </Title>
                            <StatusBox status={Status.Info} showIcon description={t('3b.withdrawalPlan.pkInfoDescription')} />
                            <div className="pk-form">
                                <p>{t('3b.withdrawalPlan.pkRegularTransferPlan')}</p>
                                <ButtonRadioElement
                                    data-cy="regularTransferPlan"
                                    value={isRegularTransferPlan}
                                    items={option}
                                    passData={(value: boolean) => setIsRegularTransferPlan(value)}
                                    required
                                    disabled={!isAtLeastOneProductOpened}
                                />
                            </div>
                            {!isAtLeastOneProductOpened && (<StatusBox status={Status.Warning} description={t('3b.withdrawalPlan.noOpenedZiagProducts')} className="warning-product" />)}
                        </div>
                    )}
                    <div className="withdrawalPlanGrid">
                        <div className="form">
                            <fieldset className="setup-section">

                                {getSelectedTitle() && (
                                    <Title type={3} tagName="legend">
                                        {getSelectedTitle()}
                                    </Title>
                                )}

                                {isAzp && (
                                    <>
                                        <div className="form">
                                            <Controller
                                                name="amount"
                                                control={control}
                                                render={({ field: { onChange, ...field } }) => (
                                                    <NumberInput
                                                        {...field}
                                                        placeholder={t('3b.withdrawalPlan.investmentAmountPlaceholder')}
                                                        thousandSeparator="'"
                                                        decimalSeparator="."
                                                        label={t('3b.withdrawalPlan.investmentAmount')}
                                                        value={data.amount}
                                                        onChange={(value: number) => {
                                                            onChange(value);
                                                            trigger('amount');
                                                        }}
                                                        error={errorData?.amount?.message}
                                                        required
                                                    />
                                                )}
                                            />

                                            <div className="withdrawals-start">
                                                <Controller
                                                    name="withdrawalsStartDate"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            /* @ts-ignore */
                                                            label={t('3b.withdrawalPlan.withdrawalsStart')}
                                                            value={data?.withdrawalsStartDate && formatDateWithoutLocalConversion(data.withdrawalsStartDate, 'DD.MM.YYYY')}
                                                            placeholder={t('3b.withdrawalPlan.withdrawalsStartPlaceholder')}
                                                            disabledDate={disableDateHandler}
                                                            error={errorData?.withdrawalsStartDate?.message}
                                                            format="DD.MM.YYYY"
                                                            required
                                                        />
                                                    )}
                                                />

                                                <span>
                                                    {t('3b.withdrawalPlan.withdrawalsStartNote')}
                                                        &nbsp;
                                                    {isAzpI && t('validation.dateNotWithinTwelveMonths')}
                                                    {isAzpD && t('validation.dateWithinTwelveMonths')}
                                                </span>
                                            </div>
                                            <Controller
                                                name="duration"
                                                control={control}
                                                render={({ field }) => (
                                                    <NumberInput
                                                        {...field}
                                                        data-cy="duration"
                                                        placeholder={t('3b.withdrawalPlan.durationPlaceholder')}
                                                        label={t('3b.withdrawalPlan.duration')}
                                                        value={data?.duration}
                                                        error={errorData?.duration?.message}
                                                        required
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="frequency"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectElement
                                                        {...field}
                                                        data-cy="periodicity"
                                                        /* @ts-ignore */
                                                        placeholder={t('3b.kyc.pleaseSelect')}
                                                        label={t('3b.withdrawalPlan.periodicity')}
                                                        value={data?.frequency}
                                                        options={optionWithdrawalPeriodicity}
                                                        error={errorData?.frequency?.message}
                                                        required
                                                    />
                                                )}
                                            />
                                        </div>
                                    </>
                                )}

                                {(isZifd || isZiv) && (
                                    <>
                                        <div className="form">
                                            <Controller
                                                name="amount"
                                                control={control}
                                                render={({ field: {onChange, ...field} }) => (
                                                    <NumberInput
                                                        {...field}
                                                        placeholder={t('3b.withdrawalPlan.withdrawalAmountPlaceholder')}
                                                        thousandSeparator="'"
                                                        decimalSeparator="."
                                                        label={t('3b.withdrawalPlan.amount')}
                                                        value={data.amount}
                                                        onChange={(value: number) => {
                                                            onChange(value);
                                                            trigger('amount');
                                                        }}
                                                        error={errorData?.amount?.message}
                                                        required
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="frequency"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectElement
                                                        {...field}
                                                        data-cy="withdrawalFrequency"
                                                        /* @ts-ignore */
                                                        placeholder={t('3b.kyc.pleaseSelect')}
                                                        label={t('3b.withdrawalPlan.frequency')}
                                                        value={data?.frequency}
                                                        options={optionWithdrawalPeriodicity}
                                                        error={errorData?.frequency?.message}
                                                        required
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="startDate"
                                                control={control}
                                                render={({ field: {onChange, ...field} }) => (
                                                    <DatePicker
                                                        {...field}
                                                        /* @ts-ignore */
                                                        label={t('3b.withdrawalPlan.withdrawalsStart')}
                                                        value={data?.startDate}
                                                        disabledDate={(current) => current && (compareDates(new Date(), current))}
                                                        onChange={(value) => {
                                                            const date = moment(value).format('MM/YYYY');

                                                            onChange(date);
                                                        }}
                                                        error={errorData?.startDate?.message}
                                                        format="MM/YYYY"
                                                        picker="month"
                                                        required
                                                    />
                                                )}
                                            />
                                        </div>
                                    </>
                                )}

                                {isPk && isRegularTransferPlan && (
                                    <>
                                        <div className="form">
                                            <Controller
                                                name="amount"
                                                control={control}
                                                render={({ field }) => (
                                                    <NumberInput
                                                        {...field}
                                                        placeholder={t('3b.withdrawalPlan.investmentAmountPlaceholder')}
                                                        thousandSeparator="'"
                                                        decimalSeparator="."
                                                        label={t('3b.withdrawalPlan.transferAmount')}
                                                        value={data.amount}
                                                        error={errorData?.amount?.message}
                                                        required
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="frequency"
                                                control={control}
                                                render={({ field }) => (
                                                    <SelectElement
                                                        {...field}
                                                        data-cy="transferFrequency"
                                                        /* @ts-ignore */
                                                        placeholder={t('3b.kyc.pleaseSelect')}
                                                        label={t('3b.withdrawalPlan.transferFrequency')}
                                                        value={data?.frequency}
                                                        options={optionWithdrawalPeriodicity}
                                                        error={errorData?.frequency?.message}
                                                        required
                                                    />
                                                )}
                                            />

                                            <Controller
                                                name="startDate"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        /* @ts-ignore */
                                                        label={t('3b.withdrawalPlan.transferStart')}
                                                        value={data?.startDate}
                                                        disabledDate={(current) => current && (compareDates(new Date(), current))}
                                                        error={errorData?.startDate?.message}
                                                        format="MM/YYYY"
                                                        picker="month"
                                                        required
                                                    />
                                                )}
                                            />
                                            <div className="transfer-destination-form">
                                                <Title type={3} tagName="legend">
                                                    {t('3b.withdrawalPlan.setupTransferDestinationTitle')}
                                                </Title>
                                                <Controller
                                                    name="targetPortfolio"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <SelectElement
                                                            {...field}
                                                            data-cy="targetPortfolio"
                                                            /* @ts-ignore */
                                                            placeholder={t('3b.kyc.pleaseSelect')}
                                                            label={t('3b.withdrawalPlan.targetPortfolio')}
                                                            value={data?.targetPortfolio}
                                                            options={optionWithdrawalPeriodicity}
                                                            error={errorData?.targetPortfolio?.message}
                                                            required
                                                        />
                                                    )}
                                                />

                                            </div>
                                        </div>
                                    </>
                                )}
                            </fieldset>

                            {!isPk && (
                                <fieldset className="bank-section">
                                    <Title type={3} tagName="legend">
                                        {t('3b.withdrawalPlan.bankTitle')}
                                    </Title>
                                    <div className="form">
                                        <Controller
                                            name="iban"
                                            control={control}
                                            render={({ field: { onChange, ...field } }) => (
                                                <Input
                                                    {...field}
                                                    data-cy="iban"
                                                    label={t('3b.withdrawalPlan.bankIban')}
                                                    value={data?.iban}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        onChange(e.target.value);
                                                        setErrorData(
                                                            'iban', {message: ''},
                                                        );
                                                    }}
                                                    error={errorData?.iban?.message}
                                                    onBlur={async (e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setErrorData('iban', {
                                                            message: await validateIban(e?.target?.value) as string,
                                                        });
                                                    }}
                                                    required
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="firstName"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    data-cy="firstName"
                                                    label={t('clientDetails.firstName')}
                                                    value={data?.firstName}
                                                    error={errorData?.firstName?.message}
                                                    required
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="lastName"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    data-cy="lastName"
                                                    label={t('clientDetails.lastName')}
                                                    value={data?.lastName}
                                                    error={errorData?.lastName?.message}
                                                    required
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="bankName"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    data-cy="bankName"
                                                    label={t('3b.withdrawalPlan.bankName')}
                                                    value={data?.bankName}
                                                    error={errorData?.bankName?.message}
                                                    required
                                                />
                                            )}
                                        />

                                        <div className="bankZip">
                                            <Controller
                                                name="zipCode"
                                                control={control}
                                                render={({ field: { onChange, ...field } }) => (
                                                    <Input
                                                        {...field}
                                                        data-cy="bankZip"
                                                        label={t('3b.withdrawalPlan.bankZipCode')}
                                                        value={data?.zipCode}
                                                        error={errorData?.zipCode?.message}
                                                        onChange={(value: number) => {
                                                            onChange(value);
                                                            trigger('zipCode');
                                                        }}
                                                        required
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="city"
                                                control={control}
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        data-cy="bankCity"
                                                        label={t('3b.withdrawalPlan.bankCity')}
                                                        value={data?.city}
                                                        error={errorData?.city?.message}
                                                        required
                                                    />
                                                )}
                                            />

                                        </div>
                                    </div>
                                </fieldset>
                            )}
                        </div>

                        {isAzp && (
                            <WithDrawalPlanSummary
                                display={displayWithdrawalPlanCalculations}
                                planCalculationLoading={planCalculationLoading}
                                isAzpD={isAzpD}
                                productExternalId={productExternalId}
                                withdrawalPlanData={withdrawalPlanData}
                            />
                        )}
                    </div>
                </div>
            </Preloader>
        </PageSection>
    );
};

export default withMainLayout(WithdrawalPlan);
