/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {FC,useCallback,useState} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';


import {
    Button, Icon, Infobox, LineChart, Modal, NumberInput, Select, Title,
} from 'ui-library';
import {
    ICON_EDIT_MINIMAL,
    ICON_TYPE_BIN,
    ICON_TYPE_CHART_ARROW,
    ICON_TYPE_CLOSE_SLIM,
} from 'ui-library/components/Icon';
import {ProductRowProps} from '../../../widgets/ShoppingCard/ShoppingCard.types';
import {
    preferenceExclusionOptions,
    preferenceStocks,
    productOptions,
    productStrategyOptions,
    substituteForExecution,
} from '../../../widgets/ShoppingCard/static';
import { StaticField } from '../StaticField/StaticField';
import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';
import './ProductRow.css';
import BuySellToggle from '../BuySellToggle/BuySellToggle';
import EmptyContent from 'components/EmptyContent';


const ProductRow: FC<ProductRowProps> = (props) => {
    const {t} = useTranslation();

    //  status = 'toInputData' /  'toSave' / 'toConfigure' / 'toEdit' / 'pendingReq'
    
    const {
        configured, product, index, control, errors, handleDeleteRow = () => {
        }, strategyOptions = [], handleAction = () => {
        },
    } = props;

    const [status, setStatus] = useState(configured? 'toEdit':'toSave')

    const handleSave = useCallback(() => {
        if (configured === false) {
            setStatus('toConfigure');
        } else {
            setStatus('toEdit');
        }
    }, []);

    const handleEdit = useCallback(() => {
        setStatus('toSave')
    },[])

    const handleConfig = useCallback(()=>{
        setStatus('toEdit')
    },[])
    

    const [showZIVVEditModal, setShowZivvEditModal] = useState<boolean>(false);
    const [showChartModal, setShowChartModal] = useState<boolean>(false);

    console.log('aa',product)
    return (
        <>
        {/* <form className="edit-strategy-form"> */}
            <div className="product-row">
                <div className="product-col">
                    {configured
                        ? (
                            <div className="configured-col">
                                <StaticField
                                    label="Product name"
                                    value={product?.product as string}
                                    width='140px'
                                />

                            {/* {status==='toEdit'||status==='toConfigure'?
                                <StaticField
                                    label="Product name"
                                    value="AAA"
                                />    
                                :       
                                <Controller
                                    name={`products.${index}.strategy`}
                                    control={control}
                                    render={({field}) => (
                                        <Select
                                            width='140px'
                                            label="Strategy"
                                            options={productOptions}
                                            placeholder={t('position.pleaseSelect')}
                                            data-cy={`products.${index}.strategy`}
                                            error={errors?.[`products[${index}].strategy`]?.message}
                                            // required={true}
                                        />
                                    )}
                                />
                            } */}

                            {status==="toSave" && product?.product==="ZIFD" ? 
                                <div style={{width: '140px'}}><Controller
                                    name={`products.${index}.strategy`}
                                    control={control}
                                    render={({field}) => (
                                        <Select
                                            label="Strategy"
                                            options={productStrategyOptions}
                                            placeholder='TIF 25'
                                            data-cy={`products.${index}.strategy`}
                                            error={errors?.[`products[${index}].strategy`]?.message}
                                            // required={true}
                                        />
                                    )}
                                /></div> :    
                                <StaticField
                                    label="Strategy"
                                    value={product?.strategy as string}
                                    width='140px'
                                    Icon={(product?.product==="ZIVV" && status==='toSave') &&(<Button
                                        type='link'
                                        onClick={() => setShowZivvEditModal(true)}
                                        className="edit-icon-button"
                                    >
                                        <Icon
                                            type={ICON_EDIT_MINIMAL}
                                            size={20}
                                            color={undefined}
                                            ariaLabel={undefined}
                                            component={undefined}
                                            className={undefined}
                                        />
                                    </Button>)}
                                /> }
                                <StaticField
                                    label="Investment amount"
                                    value=  {'CHF ' + applyThousandSeparator((product?.amount?.toString()), `'`)}
                                    width='180px'
                                /> 
                                {!["ZIWP Immediate","PK"].includes(product?.product as string) &&
                                    status==='toSave' &&(<div style={{"display":"flex", "gap":2, "alignContent": "center", 'width':'250px'}}>
                                    <BuySellToggle/>
                                    <Controller
                                        name={`products.${index}.amount`}
                                        control={control}
                                        render={({field}) => (
                                            <NumberInput
                                                id="investmentAmount"
                                                placeholder=""
                                                thousandSeparator="'"
                                                decimalSeparator="."
                                                // label={t('portfolio.createNewPortfolio.overview.productRow.PlannedInvestment.NumberInput.label')}
                                                label="Buy/Sell"
                                                data-cy={`products.${index}.amount`}
                                                // error=''
                                                prefix="CHF "
                                                required
                                                value={product?.amount}
                                                error={errors?.[`products[${index}].amount`]?.message}
                                                className="number-input"
                                            />
                                        )}
                                    />
                                </div>)}
                            </div>
                        ) : (
                            <div className="add-product-form">

                            <div>  
                                {status==='toEdit'||status==='toConfigure'?
                                <StaticField
                                    label="Product name"
                                    value="AAA"
                                />    
                                :       
                                <Controller
                                    name={`products.${index}.product`}
                                    control={control}
                                    render={({field}) => (
                                        <Select
                                            label="Product name"
                                            options={productOptions}
                                            placeholder={t('position.pleaseSelect')}
                                            data-cy={`products.${index}.product`}
                                            error={errors?.[`products[${index}].product`]?.message}
                                            required={true}
                                        />
                                    )}
                                />}
                            </div>

                            <div>
                                {status==='toEdit'||status==='toConfigure'?
                                <StaticField
                                    label={status==='toEdit' ? "Investment Strategy" : "Planned Investment"}
                                    value="AAA"
                                    Icon={status==='toEdit' && (<Button
                                        type='link'
                                        onClick={() => setShowChartModal(true)}
                                        className="edit-icon-button">
                                            <Icon
                                                type={ICON_TYPE_CHART_ARROW}
                                                size={20}
                                                color={undefined}
                                                ariaLabel={undefined}
                                                component={undefined}
                                                className={undefined}
                                            />Chart</Button>)}
                                    // Icon={product?.product==="ZIVV" &&(<Button
                                    //     type='link'
                                    //     onClick={() => setShowZivvEditModal(true)}
                                    //     className="edit-icon-button"
                                    // >
                                    //     <Icon
                                    //         type={ICON_EDIT_MINIMAL}
                                    //         size={20}
                                    //         color={undefined}
                                    //         ariaLabel={undefined}
                                    //         component={undefined}
                                    //         className={undefined}
                                    //     />
                                    // </Button>)}
                                />
                                :  
                                <Controller
                                    name={`products.${index}.amount`}
                                    control={control}
                                    render={({field}) => (
                                        <NumberInput
                                            id="investmentAmount"
                                            placeholder=""
                                            thousandSeparator="'"
                                            decimalSeparator="."
                                            // label={t('portfolio.createNewPortfolio.overview.productRow.PlannedInvestment.NumberInput.label')}
                                            label="Planned Investment"
                                            data-cy={`products.${index}.amount`}
                                            // error=''
                                            prefix="CHF "
                                            required
                                            value={product?.amount}
                                            error={errors?.[`products[${index}].amount`]?.message}
                                        />
                                    )}
                                />}

                            </div>

                            <div>
                                {status==='toEdit'&&
                                (<StaticField
                                    label="Planned Investment"
                                    value=  {'CHF ' + applyThousandSeparator('100000', `'`)}
                                />)  
                                }
                                
                            </div>
                            
                            </div>
                        )}

                    <div className="product-row-button-group">
                        {product?.new && (
                            <>
                                <Button type="link" onClick={handleDeleteRow}>
                                    <Icon
                                        type={ICON_TYPE_BIN}
                                        size={20}
                                        color={undefined}
                                        ariaLabel={undefined}
                                        component={undefined}
                                        className={undefined}
                                    />
                                    {/* {t('portfolio.createNewPortfolio.overview.productRow.buttons.delete')} */}
                                    Delete
                                </Button>
                                {status==='toSave' && (<Button
                                    onClick={handleSave}
                                    type="submit"
                                >
                                    Save
                                </Button>)}
                                {status==='toConfigure' && (<Button
                                    type={'primary'}
                                    onClick={handleConfig}
                                >
                                    Configure
                                </Button>)}
                                {status==='toEdit' && (<Button
                                    type='link'
                                    onClick={handleAction}
                                >
                                    <Icon
                                        type={ICON_EDIT_MINIMAL}
                                        size={20}
                                        color={undefined}
                                        ariaLabel={undefined}
                                        component={undefined}
                                        className={undefined}
                                    /> Edit
                                </Button>)}
                            </>
                        )}

                        {configured && !["ZIWP Immediate","PK"].includes(product?.product as string) && (
                            status==='toEdit' && (
                                product?.pendingReq ? 
                                <Infobox>This product is currently locked due to a pending change application.</Infobox> :

                                <Button
                                    type='link'
                                    onClick={handleEdit}
                                >
                                    <Icon
                                        type={ICON_EDIT_MINIMAL}
                                        size={20}
                                        color={undefined}
                                        ariaLabel={undefined}
                                        component={undefined}
                                        className={undefined}
                                    /> Edit
                                </Button>
                            )
                        )}
                        {configured && !["ZIWP Immediate","PK"].includes(product?.product as string) && (
                            status==='toSave' && (
                                product?.pendingReq ? 
                                <Infobox>This product is currently locked due to a pending change application.</Infobox> :

                                <Button
                                    type=''
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            )
                        )}                        
                    </div>
                </div>
            </div>

            {showChartModal && (
                <Modal 
                    width={610}
                    visible={showChartModal}
                    onCancel={() => setShowChartModal(false)}
                    okText="Save"
                    closable
                    borderRadius
                    closeIcon={(
                        <Icon
                            type={ICON_TYPE_CLOSE_SLIM}
                            size={20}
                            color={undefined}
                            ariaLabel={undefined}
                            component={undefined}
                            className={undefined}
                        />
                    )}>
                    <div className="azp-graph">
                            <div className="fi-verticals">
                                <Title type={2}>
                                    {t('3b.goalAndPortfolioSetup.estimatedPerformance')}
                                </Title>
                                <EmptyContent
                                    // data={chartData}
                                    text={t('advisoryDashboard.noData')}
                                >
                                    Test Chart
                                    {/* <LineChart yAxisFormat={azpYAxisFormat} customXAxisFormat={azpXAxisFormat} data={chartData} yTitle={currency} legendEnabled projection /> */}
                                </EmptyContent>
                            </div>
                        </div>
                </Modal>
            )}

            {showZIVVEditModal && (
                <Modal
                    width={610}
                    visible={showZIVVEditModal}
                    onCancel={() => setShowZivvEditModal(false)}
                    okText="Save"
                    closable
                    borderRadius
                    closeIcon={(
                        <Icon
                            type={ICON_TYPE_CLOSE_SLIM}
                            size={20}
                            color={undefined}
                            ariaLabel={undefined}
                            component={undefined}
                            className={undefined}
                        />
                    )}
                    className="change-strategy-modal"
                >
                    <Title type={2} className="mb-24">
                        Change ZIVV strategy
                    </Title>
                    <div className="edit-strategy-modal--current-stategy-info mb-24">
                        Current strategy: Default Strategy Growth (ZIAM 401)
                    </div>

                    <form className="edit-strategy-form">
                        <Select
                            // label={t('portfolio.createNewPortfolio.overview.productRow.changeStrategy.Select.label')}
                            label="Base strategy"
                            options={strategyOptions}
                            placeholder={t('position.pleaseSelect')}
                            data-cy="change-strategy"
                            // error={errors?.[`products[${index}].product`]?.message}
                            required
                        />
                        <Select
                            // label={t('portfolio.createNewPortfolio.overview.productRow.changeStrategy.Select.label')}
                            label="Preference stocks"
                            options={preferenceStocks}
                            placeholder={t('position.pleaseSelect')}
                            data-cy="preference-stocks"
                            // error={errors?.[`products[${index}].product`]?.message}
                            required
                        />
                        <Select
                            // label={t('portfolio.createNewPortfolio.overview.productRow.changeStrategy.Select.label')}
                            label="Preference exclusion"
                            options={preferenceExclusionOptions}
                            placeholder={t('position.pleaseSelect')}
                            data-cy="preference-execution"
                            // error={errors?.[`products[${index}].product`]?.message}
                            required
                        />
                        <Select
                            // label={t('portfolio.createNewPortfolio.overview.productRow.changeStrategy.Select.label')}
                            label="Substitute for Exclusion"
                            options={substituteForExecution}
                            placeholder={t('position.pleaseSelect')}
                            data-cy="substitute-for-execution"
                            // error={errors?.[`products[${index}].product`]?.message}
                        />

                        <div className="configured-col">
                            <Title type={4}>
                                <strong>
                                    {/* {t('portfolio.createNewPortfolio.overview.productRow.changeStrategy.target-strategy')} */}
                                    Target strategy
                                </strong>
                                <span>
                                    Growth 11 (ZIAM411)
                                </span>
                            </Title>
                        </div>

                        <div className="target-strategy-note">
                            Please note: This is only a pre-selection of the new strategy. The full strategy change has
                            to be finalised in a later step.
                        </div>
                    </form>
                </Modal>
            )}
        </>
    );
};

export default ProductRow;
