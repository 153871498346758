import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {StepperItemProps} from './types/StepperItemProps';

import './StepperItem.css';

const StepperItem = ({name, isSelected, isCompleted}: StepperItemProps) => {
    const {t} = useTranslation();

    const classNames = useMemo(() => {
        if (isSelected) {
            return 'selected';
        }
        if (isCompleted) {
            return 'completed';
        }

        return 'remaining';
    }, [isSelected, isCompleted]);

    return (
        <section className={`stepper-item ${classNames}`} title={name}>
            <span />
            <p>{t(name)}</p>
        </section>
    );
};

export default StepperItem;
