import React, { useMemo } from 'react';
import './SetupWithdrawalPlan.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Title } from 'ui-library';

const SetupWithdrawalPlan = ({
    value, onChange, disableWithdrawalOption,
}) => {
    const {t} = useTranslation();

    return (
        <div className="setupWithdrawalPlan" >
            <Title type={2}>{t('setupWithdrawalPlan.labels.title')}</Title>
            <div className="setupWithdrawalPlan__ButtonWrapper">
                <Button
                    onClick={() => onChange(true)}
                    type={value ? 'primary' : 'secondary'}
                    disabled={disableWithdrawalOption}
                >
                    {t('setupWithdrawalPlan.buttons.yes')}
                </Button>
                <Button
                    onClick={() => onChange(false)}
                    type={!value ? 'primary' : 'secondary'}
                >
                    {t('setupWithdrawalPlan.buttons.no')}
                </Button>
                {disableWithdrawalOption && <p>{t('setupWithdrawalPlan.labels.warnMessage')}</p>}
            </div>
        </div>
    );
};

SetupWithdrawalPlan.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,

};

export default SetupWithdrawalPlan;
