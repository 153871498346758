/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import HeaderCell from './HeaderCell';
import { filteringState, sortState } from '../utils/types';
import { createUniqueKey } from '../../../utils';

function Header(props) {
    const {
        columns,
        sorting,
        filtering,
        resetColumns,
        changeColumns,
        changeSorting,
        headerControls,
        changeFiltering,
    } = props;
    const visibleColumns = columns.filter(({ hidden }) => !hidden);

    return (
        <thead>
            <tr role="row">
                {visibleColumns.map((column, index) => (
                    <HeaderCell
                        column={column}
                        columns={columns}
                        sorting={sorting}
                        filtering={filtering}
                        resetColumns={resetColumns}
                        changeColumns={changeColumns}
                        changeSorting={changeSorting}
                        headerControls={headerControls}
                        changeFiltering={changeFiltering}
                        key={createUniqueKey(index, column.key)}
                        isFirst={index === 0}
                        isLast={index === visibleColumns.length - 1}
                    />
                ))}
            </tr>
        </thead>
    );
}

Header.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hidden: PropTypes.bool,
        sortable: PropTypes.bool,
        className: PropTypes.string,
    })),
    sorting: sortState,
    filtering: filteringState,
    resetColumns: PropTypes.func,
    changeColumns: PropTypes.func,
    changeSorting: PropTypes.func,
    changeFiltering: PropTypes.func,
    headerControls: PropTypes.shape({
        selectControlsLabel: PropTypes.shape({
            selectAll: PropTypes.string,
            reset: PropTypes.string,
        }),
        sortByLabel: PropTypes.string,
    }).isRequired,
};

Header.defaultProps = {
    columns: [],
    sorting: null,
    filtering: null,
    resetColumns: null,
    changeColumns: () => {},
    changeSorting: () => {},
    changeFiltering: () => {},
};

export default Header;
