import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ClientApplicationOverview from './ClientApplicationOverview';

const ApplicationOverviewRouter = () => (
    <Routes >
        <Route path="application/:applicationId/" element={<ClientApplicationOverview/>} />
        <Route path="container/:containerId/" element={<ClientApplicationOverview/>} />
        <Route
            path="*"
            element={<Navigate to="/404" replace />}
        />
    </Routes>
);

ApplicationOverviewRouter.displayName = 'Router';

export default ApplicationOverviewRouter;
