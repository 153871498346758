﻿import { Components } from 'formiojs';
import './autocomplete.min.css';

import autocomplete from './autocomplete.min';

class AutocompleteComponent extends Components.components.textfield {
    static schema(...extend) {
        return super.schema({
            label: 'AutoComplete',
            key: 'autocomplete',
            type: 'autocomplete',
            input: true,
        }, ...extend);
    }

    static get builderInfo() {
        return {
            title: 'AutoComplete',
            group: 'basic',
            icon: 'search',
            weight: 72,
            documentation: '#',
            schema: this.schema(),
        };
    }

    attach(element) {
        super.attach(element);

        if (this.refs && this.refs.input && this.refs.input.length > 0) {
            const input = this.refs.input[0];
            const $self = this;

            let suggestions;

            autocomplete({
                input,
                minLength: 0,
                emptyMsg: 'No items found',
                preventSubmit: true,
                showOnFocus: true,
                className: 'formio-autocomplete-custom',
                async fetch(text, update) {
                    let availableSuggestions;

                    if (!suggestions) {
                        try {
                            const response = await fetch(`${$self.component.suggestions_url}?componentKey=${$self.component.key}`);

                            if (response.ok) {
                                suggestions = await response.json();
                                // console.log("schema id", $self.root.form._id);

                                availableSuggestions = suggestions.filter((n) => n.label.toLowerCase().indexOf(text.toLowerCase()) > -1);
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    } else {
                        availableSuggestions = suggestions.filter((n) => n.label.toLowerCase().indexOf(text.toLowerCase()) > -1);
                    }

                    if (!availableSuggestions || availableSuggestions.length === 0) {
                        availableSuggestions = suggestions;
                    }
                    update(availableSuggestions);
                },
                onSelect(item) {
                    const flags = {
                        noValidate: true,
                        noCheck: true,
                        resetValue: true,
                    };

                    $self.setValueAt(0, item.label, flags);
                    $self.dataValue = item.label;
                    $self.triggerChange({
                        modified: true,
                    });
                },
            });
        }
    }

    static editForm(a, b, c) {
        const form = Components.components.textfield.editForm(a, b, c);
        const tabs = form.components.find((obj) => obj.type === 'tabs');
        const datatab = tabs.components.find((obj) => obj.key == 'data');

        datatab.components.splice(datatab.components.findIndex((obj) => obj.key = 'multiple'), 1);
        datatab.components.splice(1, 0,
            {
                input: true,
                key: 'suggestions_url',
                label: 'Suggestions URL',
                placeholder: 'Suggestions URL',
                tooltip: 'This is the url from were we retrieve the autocomplete suggestions',
                type: 'textfield',
                validate: {
                    required: true,
                },
            });


        return form;
    }
}

Components.addComponent('AutocompleteComponent', AutocompleteComponent);

export default AutocompleteComponent;
