import Service, {APIVersion} from 'additiv-services';
import {CUSTOM_SERVICE_LAYER_SERVICES, getCustomServiceLayerBaseUrl} from '../constants';
import {getQueryStringByObject} from '../../additiv-services/utils/queryUtils';

interface CalculateModelPortfolioAllocationsPayload {
    modelPortfolioId: number,
    data: [{
        securityId: number,
        allocation: number
    }]
}

interface PortfolioAllocationsResponseData {

}

interface ResponseData<T> {
    data: T
}

class CustomInvestmentService extends Service {
    static #baseServiceUrl = getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.INVESTMENT);

    static calculateModelPortfolioAllocations({modelPortfolioId, data}: CalculateModelPortfolioAllocationsPayload):Promise<ResponseData<PortfolioAllocationsResponseData>>|[Promise<ResponseData<PortfolioAllocationsResponseData>>, () => void] {
        return Service.postRequest(`${this.#baseServiceUrl}/${modelPortfolioId}/allocations`, data, {isSecure: true});
    }

    static checkIsFzAndFzpEnabled(contactId) {
        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/fz`, {isSecure: true});
    }

    static getInvestmentAppsByContainerId({containerId, language}) {
        const queryString = getQueryStringByObject({language});

        return Service.getRequest(`${this.#baseServiceUrl}/${containerId}/container-application-info?${queryString}`, {isSecure: true});
    }

    static getSubstituteExclusionOptions({preferenceKey, ...rest}) {
        const queryString = getQueryStringByObject({...rest});

        return Service.getRequest(`${this.#baseServiceUrl}/${preferenceKey}/getSubstituteExclusion?${queryString}`, {isSecure: true});
    }

    static getApplicationList({contactId, memberId, ...rest}) {
        const queryString = getQueryStringByObject({...rest});

        return Service.getRequest(`${this.#baseServiceUrl}/${contactId}/${memberId}/applications?${queryString}`, {isSecure: true});
    }

    static updateFees({ investmentApplicationId, payload }) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/update-fees`, payload, { isSecure: true });
    }

    static deleteFee({ investmentApplicationId, feeType }) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/fees?feeType=${feeType}`, { isSecure: true });
    }

    static getData(params) {
        return Service.getRequest(`${this.#baseServiceUrl}/projection?data=${params}`, { isSecure: true });
    }

    static postData(id, data) {
        return Service.postRequest(`${this.#baseServiceUrl}/projection?${id}`, data, { isSecure: true });
    }

    static cancelApplication({applicationId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${applicationId}/cancel`, null, { isSecure: true });
    }

    static cancelApplicationsByContainerId({containerId}) {
        return Service.deleteRequest(`${this.#baseServiceUrl}/${containerId}/cancel-application`, { isSecure: true });
    }

    static createNewInvestment({
        contactId, language, data,
    }) {
        const queryString = getQueryStringByObject({contactId, language});

        return Service.postRequest(`${this.#baseServiceUrl}/complete?${queryString}`, data, { isSecure: true });
    }

    static getInvestmentApplications({
        memberId, pageNumber, language, pageSize,
    }) {
        const queryString = getQueryStringByObject({
            pageNumber, language, pageSize,
        });

        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/applications?${queryString}`, { isSecure: true });
    }

    static reGenerateInvestmentApplicationDocuments({investmentApplicationId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/regenerate`, null, {isSecure: true});
    }

    static getGeneralAgencies() {
        return Service.getRequest(`${this.#baseServiceUrl}/GeneralAgencies`, {isSecure: true});
    }

    static getDawReport({from, to}) {
        return Service.getRequest(`${this.#baseServiceUrl}/eSign-report?dateFrom=${from}&dateTo=${to}`, {isSecure: true, axiosSetup: {responseType: 'blob'}});
    }

    static getLastApplicationByPortfolio({
        portfolioId, language,
    }) {
        const queryString = getQueryStringByObject({
            language,
        });

        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/last-application-by-portfolio?${queryString}`, { isSecure: true });
    }

    static getClientInvestmentApplications({
        pageNumber, language, pageSize, clientId, memberId,
    }) {
        const queryString = getQueryStringByObject({pageNumber, language, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/applications/${clientId}?${queryString}`, { isSecure: true });
    }

    static getClientInvestmentContainersByContactId({
        pageNumber, language, pageSize, clientId, memberId,
    }) {
        const queryString = getQueryStringByObject({pageNumber, language, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/${clientId}/${memberId}/getContainersByContactId?${queryString}`, { isSecure: true });
    }

    static getInvestmentContainersForMember({
        pageNumber, language, pageSize, memberId,
    }) {
        const queryString = getQueryStringByObject({pageNumber, language, pageSize});

        return Service.getRequest(`${this.#baseServiceUrl}/${memberId}/getContainersByMemberId?${queryString}`, { isSecure: true });
    }

    static getInvestmentApplicationOverview({
        language, containerOrApplicationId, isContainer,
    }) {
        const queryString = getQueryStringByObject({language});

        return Service.getRequest(`${this.#baseServiceUrl}/${containerOrApplicationId}/${isContainer ? 'container-application-info' : 'application-info'}?${queryString}`, { isSecure: true });
    }

    static cancelInvestmentApplications({investmentApplicationId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/cancel`, {}, { isSecure: true });
    }

    static saveProductDetails(payload) {
        return Service.postRequest(`${this.#baseServiceUrl}/save`, payload, { isSecure: true });
    }

    static savePersonalDetails({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/personal`, rest, { isSecure: true });
    }

    static saveStrategyData({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/strategy`, rest, { isSecure: true });
    }

    static saveKYCDone({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/kyc-done`, rest?.kycDone, { isSecure: true });
    }

    static saveIdUploadDone({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/set-id-provided`, rest?.idProvided, { isSecure: true });
    }

    static saveProductData({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/product`, rest, { isSecure: true });
    }

    static saveAgentData({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/agent`, rest, { isSecure: true });
    }

    static saveCustodyFee({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/fees`, rest, { isSecure: true });
    }

    static saveWithdrawalData({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/withdrawal`, rest, {isSecure: true});
    }

    static calculateWithdrawalPlanData({investmentApplicationId, ...rest}) {
        const queryString = getQueryStringByObject({...rest});

        return Service.getRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/withdrawalPlan?${queryString}`, {isSecure: true});
    }

    static finalizeNewPortfolio({investmentApplicationId, ...rest}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/finalize`, rest, { isSecure: true });
    }

    static generateDocuments({investmentApplicationId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/documents`, {}, { isSecure: true });
    }

    static getQuestionnaireAnswers({investmentApplicationId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/questionnaire`, { isSecure: true });
    }

    static postQuestionnaireAnswers({data, investmentApplicationId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/questionnaire`, data, { isSecure: true });
    }

    static signDocuments({investmentApplicationId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/multi-doc?investmentApplicationId=${investmentApplicationId}`, {}, { isSecure: true });
    }

    static changePlanApplication({portfolioId, ...rest}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${portfolioId}/changePlanApplication`, rest, { isSecure: true });
    }

    static changePlan({investmentApplicationId}) {
        return Service.postRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/changePlan`, {}, { isSecure: true });
    }

    static getInvestmentApplication({investmentApplicationId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${investmentApplicationId}`, { isSecure: true });
    }

    static getKudi({portfolioId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/kudi/${portfolioId}`, { isSecure: true });
    }

    static getCaseManagementDetails({portfolioId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/caseManagementDetails`, {isSecure: true});
    }

    static makeIbanReservation({investmentApplicationId}) {
        const queryString = getQueryStringByObject({investmentApplicationId});

        return Service.postRequest(`${this.#baseServiceUrl}/makeIbanReservation?${queryString}`, {}, {isSecure: true});
    }

    static getKudiSaldo({portfolioId}) {
        return Service.getRequest(`${this.#baseServiceUrl}/${portfolioId}/kudiSaldo`, { isSecure: true });
    }

    static saveAdvisoryDocumentCompletedFlag({investmentApplicationId, data}) {
        return Service.patchRequest(`${getCustomServiceLayerBaseUrl(CUSTOM_SERVICE_LAYER_SERVICES.INVESTMENT, APIVersion.V11)}/${investmentApplicationId}/advisory-completed`, data, {isSecure: true});
    }

    static generateContainerId({investmentApplicationId}) {
        return Service.patchRequest(`${this.#baseServiceUrl}/${investmentApplicationId}/add-container`, null, {isSecure: true});
    }

    static getInvestmentDataReport({from, to}) {
        return Service.getRequest(`${this.#baseServiceUrl}/get-investment-data-report?startDate=${from}&endDate=${to}`, {isSecure: true, axiosSetup: {responseType: 'blob'}});
    }
}

export default CustomInvestmentService;
