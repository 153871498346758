/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Icon, Title} from 'ui-library';
import {ICON_EDIT_OUTLINE} from '../../ui-library/components/Icon';
import {NewAddressModal} from '../../pages/ClientOverview/pages/ClientConnections/components/NewConnections';
import {useConnections} from '../../components/Connections/components';
import Preloader from '../../components/Preloader';
import {useOptionList} from '../../hooks/useOptionList';
import {OPTION_LIST} from '../../constants/constants';

type ConnectionAddressPropsWidgetProps = {
    contactGroupId: number;
}

export const ConnectionAddressWidget = (props: ConnectionAddressPropsWidgetProps) => {
    const [editAddressModalOpen, setEditAddressModalOpen] = useState<boolean>(false);
    const {
        contactGroupId,
    } = props;

    const {t} = useTranslation();
    const {
        getContactGroupDetails,
        contactGroupDetails,
        inProgress,
        updateContactGroupAddress,
    } = useConnections();

    const {
        lists: [optionCountry, optionLanguage], isLoading: isOptionsLoading,
    } = useOptionList([OPTION_LIST.country, OPTION_LIST.language]);

    const country = useMemo(
        () => (optionCountry?.find(
            (item) => (item.id === contactGroupDetails?.contactGroupAddress?.country),
        )?.label),
        [optionCountry, contactGroupDetails?.contactGroupAddress?.country],
    );

    const language = useMemo(
        () => (optionLanguage?.find(
            (item) => (item.id === contactGroupDetails?.contactGroupAddress?.language),
        )?.label),
        [optionLanguage, contactGroupDetails?.contactGroupAddress?.language],
    );

    useEffect(() => {
        (async () => getContactGroupDetails(contactGroupId))();
    }, []);

    return (
        <div className="personalDetails__singleForm connection-address-widget">
            <div className="LinkedHeader">
                <Title type={2} data-cy="full_name">
                    {t('portfolio.createNewPortfolio.personalPage.connection.title')}
                </Title>
                <div>
                    <button
                        className="button-link"
                        type="button"
                        data-cy="edit_personal_details"
                        onClick={() => setEditAddressModalOpen(true)}
                    >
                        {/* @ts-ignore */}
                        <Icon size={24} type={ICON_EDIT_OUTLINE}/>
                        <div>{t('interaction.edit')}</div>
                    </button>
                </div>
                <NewAddressModal
                    onSubmit={async (data) => {
                        setEditAddressModalOpen(false);

                        if (contactGroupId) {
                            await updateContactGroupAddress(`${contactGroupId}`, {
                                language: data.mailRecipientLanguage,
                                addLine1: data.mailRecipientAddressLine1,
                                addLine2: data.mailRecipientAddressLine2,
                                street: data.mailRecipientAddressLine3,
                                zipCity: data.mailRecipientAddressLine4,
                                city: data.mailRecipientAddressLine5,
                                country: data.mailRecipientAddressCountry,
                            }, getContactGroupDetails);
                        }
                    }}
                    outsideClickHappened={editAddressModalOpen}
                    setEditAddressModalOpen={setEditAddressModalOpen}
                    initData={{
                        mailRecipientLanguage: contactGroupDetails?.contactGroupAddress?.language,
                        mailRecipientAddressLine1: contactGroupDetails?.contactGroupAddress?.addLine1,
                        mailRecipientAddressLine2: contactGroupDetails?.contactGroupAddress?.addLine2,
                        mailRecipientAddressLine3: contactGroupDetails?.contactGroupAddress?.street,
                        mailRecipientAddressLine4: contactGroupDetails?.contactGroupAddress?.zipCity,
                        mailRecipientAddressLine5: contactGroupDetails?.contactGroupAddress?.city,
                        mailRecipientAddressCountry: contactGroupDetails?.contactGroupAddress?.country,
                    }}
                    customRenderComponent={(<></>)}
                />
            </div>
            <Preloader isLoading={inProgress || isOptionsLoading}>
                <div className="personalDetailsTable data-summary-wrapper">
                    <dl className="DataList">
                        <div>
                            <dt>
                                {t('portfolio.createNewPortfolio.personalPage.connection.language')}
                            </dt>
                            <dd data-cy="language">
                                {language}
                            </dd>
                        </div>
                        <div/>
                        <div>
                            <dt>
                                {t('portfolio.createNewPortfolio.personalPage.connection.street')}
                            </dt>
                            <dd data-cy="street">
                                {contactGroupDetails?.contactGroupAddress?.street}
                            </dd>
                        </div>
                        <div>
                            <dt>
                                {t('portfolio.createNewPortfolio.personalPage.connection.zipCity')}
                            </dt>
                            <dd data-cy="zipCity">
                                {contactGroupDetails?.contactGroupAddress?.zipCity}
                            </dd>
                        </div>
                        <div>
                            <dt>
                                {t('portfolio.createNewPortfolio.personalPage.connection.city')}
                            </dt>
                            <dd data-cy="city">
                                {contactGroupDetails?.contactGroupAddress?.city}
                            </dd>
                        </div>
                        <div>
                            <dt>
                                {t('portfolio.createNewPortfolio.personalPage.connection.country')}
                            </dt>
                            <dd data-cy="country">
                                {country}
                            </dd>
                        </div>
                    </dl>
                </div>
            </Preloader>
        </div>
    );
};
