import React, {useEffect} from 'react';
import PortfoliosTable from 'components/Portfolios';
import {useNavigate} from 'react-router-dom';
import {CreatePortfolioManager} from 'datasource/CreatePortfolio';
import {PortfolioProcessLayoutManager} from 'datasource/PortfolioProcessLayout';
import {useLayout} from './hooks/useLayout';
import {useNewPortfolioData} from './pages/CreateNewPortfolio/hooks/useNewPortfolioData';
import {useClientLayout} from '../../hooks/useClientLayout';
import {useClientProfile} from '../../../../prodivers/clientProfile';
import './Portfolios.css';
import {StepperManager} from "@/core/stepper/StepperManager";

function Portfolios({dfsClientId}) {
    const navigate = useNavigate();
    const { clearNewPortfolio, clearModifiedPositions } = useNewPortfolioData();
    const { clearLayoutCache } = useLayout();

    useClientLayout();

    const {
        sectionedPortfolios, isOverviewLoading, setReFetchPortfolios,
    } = useClientProfile();

    useEffect(() => {
        setReFetchPortfolios(true);
    }, []);

    const onNewClick = () => navigate(`/client/${dfsClientId}/portfolios/risk-profile`);

    const onOpenNewPortfolio = () => {
        clearNewPortfolio();
        clearModifiedPositions();
        clearLayoutCache();
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio`);
    };

    const onOpenNewPortfolio2 = () => {
        sessionStorage.removeItem(StepperManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(CreatePortfolioManager.LOCAL_STORAGE_KEY);
        sessionStorage.removeItem(PortfolioProcessLayoutManager.LOCAL_STORAGE_KEY);
        navigate(`/client/${dfsClientId}/portfolios/new-portfolio2`);
    };

    return (
        <PortfoliosTable
            isLoading={isOverviewLoading}
            data={sectionedPortfolios}
            onNewClick={onNewClick}
            onOpenNewPortfolio={onOpenNewPortfolio}
            onOpenNewPortfolio2={onOpenNewPortfolio2}
        />
    );
}

export default Portfolios;
