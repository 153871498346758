import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Title } from 'ui-library';
import QuestionnaireQuestion from '../QuestionnaireQuestion';

const QuestionnaireGroup = (props) => {
    const {
        data, value, errors, onChange, title, defaultValue, disableDefaultValue, radioRenderComponent,
    } = props;

    return (
        <div className="questionnaire-group">
            <Title type={2}>{title}</Title>
            <div className="questionnaire-group-content">
                {(data?.Steps || []).map((step) => (
                    <div className="step-content" key={step?.Id}>
                        {(step?.Questions || []).map((question) => (
                            <QuestionnaireQuestion
                                key={question.Id}
                                data={{ ...question, GroupDescription: data.Description }}
                                value={value[question.Id]}
                                error={errors[question.Id]}
                                onChange={onChange}
                                defaultValue={defaultValue}
                                disableDefaultValue={disableDefaultValue}
                                radioRenderComponent={radioRenderComponent}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

QuestionnaireGroup.propTypes = {
    data: PropTypes.shape({
        Name: PropTypes.string,
        Description: PropTypes.string,
        Steps: PropTypes.arrayOf(PropTypes.shape({
            Questions: PropTypes.arrayOf(PropTypes.shape({
                Text: PropTypes.string,
                Layout: PropTypes.string,
                Answers: PropTypes.arrayOf(PropTypes.shape({
                    Id: PropTypes.number,
                    Text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                })),
            })),
        })),
    }),
    value: PropTypes.shape({
        [PropTypes.number]: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.arrayOf(PropTypes.number),
        ]),
    }),
    errors: PropTypes.shape({
        [PropTypes.number]: PropTypes.string,
    }),
    onChange: PropTypes.func,
    title: PropTypes.string,
    defaultValue: PropTypes.oneOf(
        [PropTypes.arrayOf(PropTypes.number), PropTypes.number, PropTypes.string],
    ),
    disableDefaultValue: PropTypes.bool,
};

QuestionnaireGroup.defaultProps = {
    data: undefined,
    value: {},
    errors: {},
    onChange: () => {},
    title: '',
    defaultValue: null,
    disableDefaultValue: false,
};

export default QuestionnaireGroup;
