import React from 'react';
import {useParams} from 'react-router-dom';
import AgentInformationBase from 'pages/ClientOverview/pages/Portfolios/components/AgentInformation';
import {useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';
import {useAgentInformation} from './hooks/useAgentInformation';

import '../PersonalDetails/PersonalDetails.css';

function AgentInformation() {
    const {
        currentInvestmentDescriptionSelector,
    } = useCreatePortfolioSelectors();
    const zurichEmployee = currentInvestmentDescriptionSelector?.personalDetails?.zurichEmployee;
    const
        {dfsClientId} = useParams();
    const {
        t,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbSelectOptions,
        fullPbNumber,
        pbChange,
        isPBTextFieldAvailable,
        advisorData,
        newPBValue,
        validation,
        onChangeNewPBNumber,
        changeVSTNumber,
        vstNumber,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    } = useAgentInformation({dfsClientId});

    // Handlers/Callbacks

    return (
        <AgentInformationBase
            {...{
                t,
                validation,
                formatPhoneNumber,
                productDesc,
                issueCommissionSelected,
                issueCommissionChange,
                issueCommissionOptions,
                pbSelected,
                pbSelectOptions,
                fullPbNumber,
                pbChange,
                isPBTextFieldAvailable,
                advisorData,
                newPBValue,
                onChangeNewPBNumber,
                changeVSTNumber,
                vstNumber,
                generalAgencies,
                selectedGeneralAgency,
                setSelectedGeneralAgency,
                agentsList,
                selectedAgent,
                setSelectedAgent,
                handleSearchAgents,
                searchAgentsInProgress,
                zurichEmployee,
            }}
        />
    );
}

export default AgentInformation;
