import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import ServiceManager from 'services/ServiceManager';
import {useProductsList} from 'hooks/useProducts';
import {useTranslation} from 'react-i18next';
import {useContactGroupOwners} from 'hooks/helpers/useContactGroupOwners';
import {useOptionList} from 'hooks/useOptionList';
import {OPTION_LIST, PRODUCTS} from 'constants/constants';
import Preloader from 'components/Preloader';
import {isThreeBProduct} from 'hooks/isThreeBProduct';
import CreateNewPlanContext from './context';
import {useNewPortfolioData} from '../hooks/useNewPortfolioData';
import {useResume} from '../hooks/useResume';
import {usePlanWizardLayout} from '../../../components/PlanWizardLayout';
import useCommonAllocationDataProvider from '../../../context/useCommonAllocationDataProvider.';
import {useClientLayout} from '../../../../../hooks/useClientLayout';
import {SELECTED_INVESTMENT_APP} from '../../../../../constants';

const newPortfolioInitialData = {applicationData: {}};

const CreateNewPlanProvider = ({
    clientId,
    portfolioId,
    investmentApplicationId,
    children,
}) => {
    useClientLayout();
    const [selectedInvestmentApp, setSelectedInvestmentApp] = useState(global.sessionStorage.getItem(SELECTED_INVESTMENT_APP) || undefined);

    const {newPortfolio: cachedData, saveNewPortfolio: saveDataToLocalStorage, clearNewPortfolio: clearNewPortfolioLocalStorage} = useNewPortfolioData(selectedInvestmentApp);
    const [newPortfolio, setNewPortfolio] = useState(newPortfolioInitialData);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [error, setError] = useState(null);
    const [isFzAndFzpAlreadyApplied, setIsFzAndFzpAlreadyApplied] = useState(false);
    const {setSelectedProductType, selectedProductType} = useCommonAllocationDataProvider();
    const layout = usePlanWizardLayout();
    const {resume, isResumeInProgress: resumeInProgress} = useResume({
        clientId,
        setNewPortfolio,
        saveDataToLocalStorage,
        newPortfolio,
        investmentApplicationId,
        setLayoutData: layout.setData,
    });
    const productName = useMemo(() => (newPortfolio?.product?.name), [newPortfolio?.product?.name]);
    const is3bProduct = isThreeBProduct(newPortfolio);
    const selectedProductId = useMemo(() => newPortfolio?.applicationData?.investmentDescription?.productId, [newPortfolio?.applicationData?.investmentDescription?.productId]);
    const connectionGroupId = useMemo(() => newPortfolio?.connectionGroupId, [newPortfolio?.connectionGroupId]);

    const { i18n: { language } } = useTranslation();

    const {mainOwner, jaClient} = useContactGroupOwners({connectionGroupId});

    useEffect(() => {
        if (!!mainOwner || !!jaClient) {
            layout.setData({
                clientName: mainOwner?.name,
                clientAge: mainOwner?.age,
                jaClientName: jaClient?.name,
                jaClientAge: jaClient?.age,
            });

            setNewPortfolio((oldData) => ({
                ...oldData,
                mainOwner,
                jaClient,
            }));
        }
    }, [mainOwner, jaClient]);

    const {
        isLoadingProducts,
        groupedProducts,
        errorProducts,
        getProducts,
    } = useProductsList(clientId);

    const {lists: [fatfGreylist, countries]} = useOptionList([OPTION_LIST.fatfGreylist, OPTION_LIST.country]);

    const checkIfNationalityUnderFatfGrayList = useCallback((nationality) => {
        const countryOfNationality = countries?.find(f => f.id === nationality);

        if (fatfGreylist?.some(s => s.label === countryOfNationality?.label && selectedProductType === PRODUCTS.bbb)) {
            return 'portfolio.createNewPortfolio.nationalityOnFatfGrayList';
        }

        return null;
    }, [countries, fatfGreylist, selectedProductType]);

    const checkIfFzAndFzpAlreadyApplied = useCallback(async () => {
        try {
            const {data} = await ServiceManager.customInvestmentService('checkIsFzAndFzpEnabled', [clientId]);

            setIsFzAndFzpAlreadyApplied(data);
        } catch (err) {
            //
        }
    }, [clientId]);

    useEffect(() => {
        layout.setData({saveInProgress});
    }, [saveInProgress]);

    useEffect(() => {
        if (selectedProductType) {
            layout.setData({
                productOfferGroupName: groupedProducts.find((item) => (selectedProductType.toLowerCase() === item.settings.productKey.toLowerCase()))?.name,
            });
        }
    }, [selectedProductType, groupedProducts]);

    useEffect(() => {
        const fetchProduct = async () => {
            const products = await getProducts(clientId);

            const selectedProduct = products.find(product => product.id === selectedProductId);

            if (selectedProduct?.id) {
                setNewPortfolio((oldData) => ({...oldData, product: selectedProduct}));
            }
        };

        if (selectedProductId) {
            fetchProduct();
        }
    }, [language, clientId, selectedProductId]);

    useEffect(() => {
        const isApplicationCreated = !!newPortfolio?.applicationData?.investmentApplicationId;

        if (isApplicationCreated) {
            const isPersonalStep = is3bProduct ? layout?.data?.stepNavBarActive === 4 : layout?.data?.stepNavBarActive === 2;

            layout.setData({ backDisabled: isPersonalStep });
        } else {
            layout.setData({ backDisabled: false });
        }
    }, [newPortfolio?.applicationData?.investmentApplicationId, layout?.data?.stepNavBarActive, is3bProduct]);

    useEffect(() => {
        if (investmentApplicationId) {
            (async () => {
                try {
                    clearNewPortfolio();
                    await resume();
                } catch (err) {
                    console.log('resume error:', err);
                    setError(err.message);
                }
            })();
        }
    }, [investmentApplicationId]);

    useEffect(() => {
        setNewPortfolio({...cachedData, applicationData: cachedData.applicationData || {}});
        setSelectedProductType(cachedData?.product?.settings?.productKey?.toLowerCase());
    }, [cachedData]);

    useEffect(() => { (async () => checkIfFzAndFzpAlreadyApplied())(); }, []);

    const saveNewPortfolio = useCallback(async (payload) => {
        try {
            setSaveInProgress(true);

            let response = {};

            if (payload.method) {
                response = await ServiceManager.customInvestmentService(payload.method, [payload.data]);
                global.sessionStorage.setItem(SELECTED_INVESTMENT_APP, response?.data?.investmentApplicationId ?? payload?.data?.investmentApplicationId);
            }

            if (payload.callback && response?.data) {
                await payload.callback(response.data);
            }

            const newData = {
                ...newPortfolio,
                applicationData: {...newPortfolio.applicationData, ...response.data},
                ...payload.additionalData,
            };

            setNewPortfolio(newData);
            saveDataToLocalStorage(newData);
            if (response?.data) {
                setSelectedInvestmentApp(response?.data?.investmentApplicationId);
            }

            return true;
        } catch (err) {
            layout.setPageErrors(err);
            setError(err.message);

            return false;
        } finally {
            setSaveInProgress(false);
        }
    }, [newPortfolio, saveInProgress]);

    const clearNewPortfolio = (appId, callback) => {
        clearNewPortfolioLocalStorage(appId, callback);
        layout.clearLayoutCache();
    };

    const state = {
        clientId,
        portfolioId,
        productName,
        newPortfolio,
        saveNewPortfolio,
        layout,
        clearNewPortfolio,
        saveInProgress,
        error,
        isFzAndFzpAlreadyApplied,
        checkIfNationalityUnderFatfGrayList,
        isLoadingProducts,
        groupedProducts,
        errorProducts,
        getProducts,
        setSelectedInvestmentApp: (appId) => {
            global.sessionStorage.setItem(SELECTED_INVESTMENT_APP, appId);
            setSelectedInvestmentApp(appId);
        },

        saveNewPortfolioToSessionStorage: saveDataToLocalStorage,
    };

    return (
        <CreateNewPlanContext.Provider
            value={{ ...state }}
        >
            <Preloader isLoading={resumeInProgress}>
                {children}
            </Preloader>
        </CreateNewPlanContext.Provider>
    );
};

CreateNewPlanProvider.propTypes = {

};

CreateNewPlanProvider.defaultProps = {};

export default CreateNewPlanProvider;
