import React from 'react';
import { Templates } from 'formiojs';

const inputTemplate = Templates.current.input.form;
const selectTemplate = Templates.current.select.form;
const checkboxTemplate = Templates.current.checkbox.form;

export const useCustomTemplates = () => {
    Templates.current.input.HWM = (ctx) => (
        `<div class="TextInput-group-wrapper">
            ${inputTemplate(ctx)}
        </div>`
    );
    Templates.current.select.HWM = (ctx) => (
        `<div class="select">
            ${selectTemplate(ctx)}
        </div>`
    );
    Templates.current.checkbox.HWM = (ctx) => (
        `<div class="checkbox-wrapper">
            ${checkboxTemplate(ctx)}
        </div>`
    );
};
