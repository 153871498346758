import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import {
    Button, Icon, Modal, NumberInput,
} from 'ui-library';
import PieChartWithLegend from 'components/PieChartWIthLegend/PieChartWithLegend';
import {ICON_EDIT_OUTLINE, ICON_TYPE_WARNING} from '../../ui-library/components/Icon';
import {DEFAULT_APP_CURRENCY} from '../../constants/constants';
import Error from '../../components/AtomicStructure/atoms/Error';
import {usePortfolioProcessNavigation} from '../../hooks/helpers/usePortfolioProcessNavigation';
import './AssetsOverview.css';

type AssetsOverviewProps = {
    datasource: () => any;
}

const AssetsOverview:FC<AssetsOverviewProps> = ({datasource}) => {
    usePortfolioProcessNavigation({ preventNavigationLock: true});
    const {t} = useTranslation();

    const {
        data,
        formData,
        control,
        errors,
        showModal,
        closeModal,
        handleInvestmentAmount,
        totalLiquidityForInvestment,
        totalPensionSituation,
        totalAmountOfOtherAssets,
        submitAmountChange,
        isLoading,
        error,
        setShowModal,
        formatValue,
    } = datasource();

    const desiredAmountModal = useMemo(() => (
        <Modal
            visible={showModal}
            destroyOnClose
            onCancel={closeModal}
            title={t('assetsOverview.desiredAmountModalTitle')}
            width={520}
            okText={t('assetsOverview.saveAmount')}
            cancelText={t('clientDashboard.cancel')}
            confirmLoading={isLoading}
            onOk={handleInvestmentAmount}
            className="desired-amount-modal"
            isLoading={isLoading}
        >
            <section>
                <Controller
                    name="desiredInvestmentAmount"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <NumberInput
                            {...field}
                            error={errors.desiredInvestmentAmount?.message}
                            disabled={isLoading}
                            thousandSeparator="'"
                            decimalSeparator="."
                            value={data?.desiredInvestmentAmount ?? totalLiquidityForInvestment}
                            onChange={onChange}
                            label={t('assetsOverview.investmentAmount')}
                            prefix={`${DEFAULT_APP_CURRENCY} `}
                            textRight
                            required
                        />
                    )}
                />
                {+formData?.desiredInvestmentAmount > totalLiquidityForInvestment && (
                    <div className="modal-warning">
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Icon type={ICON_TYPE_WARNING} size={20}/>
                        <p>{t('assetsOverview.investmentAmountHigher')}</p>
                    </div>
                )}
            </section>
        </Modal>
    ), [showModal, t, isLoading, submitAmountChange, closeModal, errors, totalLiquidityForInvestment, formData?.desiredInvestmentAmount, data?.desiredInvestmentAmount]);

    if (error) {
        return <Error>{error.message}</Error>;
    }

    return (
        <>
            <section className="assets-overview">
                <h3 className="title">{t('assetsOverview.liquidityForInvestment')}</h3>
                <section className="liquidity">
                    <p>{t('assetsOverview.liquidity')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.totalLiquidity, '+')}</p>
                    <p>{t('assetsOverview.assetInflow')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.expectedAssetInflow, '+')}</p>
                    <p>{t('assetsOverview.expenses')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.plannedExpenses, '-')}</p>
                    <p>{t('assetsOverview.credits')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.loans, '-')}</p>
                    <p className="total">{formatValue(totalLiquidityForInvestment, totalLiquidityForInvestment > 0 ? '+' : '-')}</p>
                </section>
                <section className="desired-amount">
                    <h4>{t('assetsOverview.desiredAmount')}</h4>
                    <Button type="link" onClick={() => setShowModal(true)}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Icon type={ICON_EDIT_OUTLINE} size={24} color="white"/>
                    </Button>
                    <p className="total">{formatValue(data?.desiredInvestmentAmount ?? totalLiquidityForInvestment ?? 200000.00, (data?.desiredInvestmentAmount !== null ? data?.desiredInvestmentAmount < 0 : totalLiquidityForInvestment < 0) ? '-' : '')}</p>
                </section>
                <h3 className="title">{t('assetsOverview.pension')}</h3>
                <h3 className="title-total">{formatValue(totalPensionSituation)}</h3>
                <section className="left-section">
                    <h4>{t('assetsOverview.pilar')}</h4>
                    <p>{t('assetsOverview.zurich')}</p>
                    <p className="amount">{formatValue(data?.pensionSituation?.pillar3aAmount || 0)}</p>
                    <p>{t('assetsOverview.thirdParty')}</p>
                    <p className="amount">{formatValue(data?.pensionSituation?.otherPillar3aAmount || 0)}</p>
                </section>
                <section className="middle-section">
                    <h4>{t('assetsOverview.vestedBenefits')}</h4>
                    <p>{t('assetsOverview.zurich')}</p>
                    <p className="amount">{formatValue(data?.pensionSituation?.vbAmount || 0)}</p>
                    <p>{t('assetsOverview.thirdParty')}</p>
                    <p className="amount">{formatValue(data?.pensionSituation?.otherVbAmount || 0)}</p>
                </section>
                <section className="right-section">
                    <h4>{t('assetsOverview.lifeInsurance')}</h4>
                    <p>{t('assetsOverview.zurich')}</p>
                    <p className="amount">{formatValue(data?.pensionSituation?.pensionFund || 0)}</p>
                    <p>{t('assetsOverview.thirdParty')}</p>
                    <p className="amount">{formatValue(data?.pensionSituation?.lifeInsurance3aVb || 0)}</p>
                </section>
                <h3 className="title">{t('assetsOverview.other')}</h3>
                <h3 className="title-total">{formatValue(totalAmountOfOtherAssets || 0)}</h3>
                <section className="left-section">
                    <h4>{t('assetsOverview.depots')}</h4>
                    <p className="single-amount">{formatValue(data?.otherAssets?.investDepots || 0)}</p>
                </section>
                <section className="middle-section">
                    <h4>{t('assetsOverview.properties')}</h4>
                    <p className="single-amount">{formatValue(data?.otherAssets?.properties || 0)}</p>
                </section>
                <section className="right-section">
                    <h4>{t('assetsOverview.thirdPartyAssets')}</h4>
                    <p className="single-amount">{formatValue(data?.otherAssets?.thirdPartyAssets || 0)}</p>
                </section>
            </section>
            <section className="assets-overview assets-overview--chart-wrapper">
                <section>
                    <div className="title">
                        <h3>{t('assetsOverview.allocations')}</h3>
                        <span>{t('assetsOverview.allocationsDesc')}</span>
                    </div>
                    <PieChartWithLegend chartData={data?.securityAllocations || []} className="assets-overview-chart" />
                </section>
            </section>
            {showModal && desiredAmountModal}
        </>
    );
};

export default AssetsOverview;
