import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'ui-library';

const CellWithIconAction = ({ icon, size, onChange }) => (
    <div className="table-cell_icon">
        {icon && <Icon type={icon} size={size} onClick={onChange} />}
    </div>
);

CellWithIconAction.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.number,
    onChange: PropTypes.func,
};

CellWithIconAction.defaultProps = {
    icon: undefined,
    size: undefined,
    onChange: () => {},
};

export default CellWithIconAction;
